import React, { Component } from 'react'
import Navbar from '../navbar'
import strings from '../languageStrings'
import { Link } from 'react-router-dom'

class Terms extends Component {
	state = {}
	render() {
		let language = strings.getLanguage()
		return (
			<React.Fragment>
				<Navbar />
				<div className="container">
					<div className="row">
						<div className="col">
							{language !== 'de' && (
								<>
									<h1>Terms and Conditions</h1>
									<p>
										<br />
										01.06.2019
									</p>
									<p></p>
									<ol>
										<li>
											<h2>Introduction</h2>
											<ol>
												<li>
													These terms and conditions relate to our website, products or brands infludata or
													infludata.com and paymentpunks or paymentpunks.com and onlinepunks or onlinepunks.com owned by
													Primed Marketing Agency GmbH, Wipplingerstraße 12/2/4, 1010 Wien, Österreich (referred as
													“Onlinepunks”, “we”, “us”) and to any other website and product that we own or containing our
													services or products (our “Website” or “services”). These Terms of Use set out the terms and
													conditions agreed between us and you (being the person using our Website or - if applicable -
													customer or user of our services ("you", "your", “customer” or “client” as applicable).
												</li>
												<li>
													Please read these Terms and Conditions carefully before you start using any section of our
													Website or purchase any products or services. By using any section of our Website or by
													registering with us through the Website, you agree to be bound by:
													<ol>
														<li>these Terms and Conditions;</li>
														<li>
															the&nbsp;<a href="https://chess24.com/en/static/privacy-policy">Privacy Policy</a>; and
														</li>
														<li>
															any terms and conditions and or rules with regards to promotions or special offers which
															may apply from time to time;
															<br />
															(together, the “Terms of Use”).
														</li>
													</ol>
												</li>
												<li>
													If the Terms of Use are translated into any other language it will be for information purposes
													only and in the event of any conflict between the translation and the German language version,
													the German language version shall prevail.
												</li>
												<li>
													Your continued use of the Website or any purchase you conclude will constitute acceptance of
													the Terms of Use.
												</li>
												<li>The Terms of Use constitute your agreement with us.</li>
												<li>
													In the event that there is any conflict or inconsistency between the terms and conditions of
													the Terms of Use, the order of precedence shall be as set out in clause 1.2.
												</li>
												<li>
													We use cookies to give you the best experience on the Website. If you continue to use any
													section of our Website without changing your cookie settings, we assume that you consent to
													our use of cookies on the Website. You can change your cookie settings at any time but if you
													do, you may lose some functionality on our Website. More information can be found in our&nbsp;
													<a href="https://chess24.com/en/static/privacy-policy">Privacy Policy</a>&nbsp;and&nbsp;
													<a href="https://chess24.com/en/static/cookie-policy">Cookie Policy</a>.
												</li>
											</ol>
										</li>
									</ol>
									<p></p>
									<ol>
										<li>
											Changes to the Terms of Use
											<ol>
												<li>
													We reserve the right to make changes to the Terms of Use at any time. We will make reasonable
													efforts to ensure that any significant changes to the Terms of Use will be notified to you by
													an appropriate method (for example, by email or via a notice on the Website). However, it
													remains your responsibility to check the Terms of Use from time to time to ensure that you
													agree with them, and your continued use of the Website will be deemed to be your acceptance of
													any changes we may make.
													<br />
													<br />
												</li>
											</ol>
										</li>
										<li>
											<h2>Use of the Website</h2>
											<ol>
												<li>You shall use the Website for lawful proposes only.</li>
												<li>
													You shall use the Website for your own personal and non-commercial use only and shall not be
													allowed to reproduce the Website or any part of it in any form whatsoever without our express
													consent.
												</li>
												<li>
													You must not:
													<ol>
														<li>
															attempt to gain unauthorised access to the Website, the server on which it is stored or
															any server, computer or database connected to our Website;
														</li>
														<li>
															you must not attack our Website via a denial-of-service attack or a distributed denial-of
															service attack; and
														</li>
														<li>
															misuse the Website by knowingly introducing viruses, trojans, worms, logic bombs,
															time-bombs, keystroke loggers, spyware, adware or any other harmful material designed to
															adversely affect the operation of any computer software or hardware.
														</li>
													</ol>
												</li>
												<li>
													By breaching any provision of clause 5.3, you may commit a criminal offence. We may report any
													such breach to the relevant law enforcement authorities and we will co-operate with those
													authorities by disclosing information about you to them. In the event of such a breach, your
													right to use our Website will cease immediately.
												</li>
												<li>
													You shall not use the Website:
													<ol>
														<li>
															in any way that breaches any applicable local, national or international law or
															regulation;
														</li>
														<li>
															in any way that is unlawful or fraudulent, or has any unlawful or fraudulent purpose or
															effect.
														</li>
														<li>
															for any purpose which is or is likely to be considered to be harmful, threatening,
															defamatory, libellous, abusive, vulgar, obscene, hateful, racist, sexist, of a
															discriminatory nature or which could cause offence or is otherwise objectionable;
														</li>
														<li>
															for the purpose of harming, threatening or attempting to harm or threaten minors in any
															way; nor
														</li>
														<li>
															to transmit, or procure the sending of, any unsolicited or unauthorised advertising or
															promotional material or any other form of similar solicitation (spam);
														</li>
														<li>to "stalk" or otherwise harass another;</li>
														<li>
															to impersonate any person or entity, including, but not limited to, Onlinepunks
															representative, or falsely state or otherwise misrepresent your affiliation with a person
															or entity;
														</li>
														<li>
															to forge headers or otherwise manipulate identifiers in order to disguise the origin of
															any content transmitted through the Website;
														</li>
														<li>
															disrupt the normal flow of dialogue or otherwise act in a manner that negatively affects
															other users' ability to engage in real time exchanges;
														</li>
														<li>
															collect or store personal data about other users of our Website in connection with conduct
															and activities prohibited by these Terms of Use.
														</li>
													</ol>
												</li>
												<li>
													You shall not interfere with, damage or disrupt:
													<ol>
														<li>any section of our Website;</li>
														<li>any network on which our Website is stored; or</li>
														<li>any software used in the provision of our Website.</li>
													</ol>
												</li>
												<li>
													We will not be liable for any loss or damage caused by a distributed denial-of-service attack,
													viruses or other technologically harmful material that may infect your computer equipment,
													computer programs, data or other proprietary material due to your use of our Website or to
													your downloading of any material posted on it, or on any website linked to it.
													<br />
													<br />
												</li>
											</ol>
										</li>
										<li>
											<h2>Registration, Opening & Maintaining Account</h2>
											<ol>
												<li>By registering with us, you will open an account (“Account”) with us.</li>
												<li>
													When registering and opening an Account with us, you are requested to choose your own username
													and password. You should ensure that these details are kept private as you are responsible for
													all activity undertaken with your username (where the correct security information has been
													provided). If you lose, or forget, your username and/or password, or if you believe that a
													third party is aware of them, you should contact us immediately so that new security details
													can be allocated to you. These new details will be emailed to you.
												</li>
												<li>
													If another person accesses your Account, you are solely responsible for all their actions
													whether or not their access was authorised by you and you hereby indemnify us and hold us
													harmless against all costs, claims, expenses and damages arising in connection with the use of
													or access to your Account by any third party.
													<br />
													<br />
												</li>
											</ol>
										</li>
										<li>
											Subject of Agreement - Infludata und Paymentpunks
											<br />
											<br />
											<ol>
												<li>
													Onlinepunks and its brands provide technical services, especially exports of data and analyses
													of social media profiles, to be used for advertising and marketing purposes. The provided data
													is publicly accessible and contains mostly business profiles and information extracted from
													the platform “instagram.com”. The provided data and information is processed automatically and
													based on estimations and algorithms by Onlinepunks or based on data that Instagram or its
													users share publicly.&nbsp;
													<br />
													<br />
												</li>
												<li>
													All business connections and agreements between Onlinepunks and the customer are based on
													these terms and conditions. Deviating terms or agreements - especially the customer’s terms -
													are not applied unless they are approved by Onlinepunks and reduced by writing.
													<br />
													<br />
												</li>
												<li>
													Onlinepunks is not part of Facebook, Inc. or any other social media platform neither are there
													any business relations existent.&nbsp;
													<br />
													<br />
												</li>
											</ol>
										</li>
										<li>
											Offers and Contract Closing
											<br />
											<br />
											<ol>
												<li>
													Offers - either private or publicly available - are non-binding. Orders or acceptions of
													offers from the customer side need to be reducted by writing or via our payment platform. They
													are coming into effect only after a written confirmation by Onlinepunks and - in case of a
													subscription - are automatically prolonged if no written termination of the agreement is
													provided or if the written termination of the agreement is provided only after the
													cancellation period. Termination notices are only valid reduced to writing or via our platform
													cancellation services.
													<br />
													<br />
												</li>
												<li>
													These terms and conditions apply to any offer and service and are always meant to be the legal
													basis. Divergent or additional agreements need to be reduced to writing especially conditions
													provided by the customer. Such condition will not apply even if they are not explicitly
													rejected by Onlinepunks.
													<br />
													<br />
												</li>
											</ol>
										</li>
										<li>
											Specification of Services
											<br />
											<br />
											<ol>
												<li>
													The scope of the services equals the offer provided by “infludata.com” and is confirmed by the
													written order confirmation of Onlinepunks. Subsequent changes of provided services require a
													written confirmation of Onlinepunks. In case the delivered or provided product is not equal to
													the offered or ordered product, the customer must inform Onlinepunks immediately after
													knowledge.&nbsp;
													<br />
													<br />
												</li>
												<li>
													All provided data is non committal and provided without liability, originated from publicly
													available sources and there is a high probability that information or data is flawed. Certain
													parts of the provided data is based on estimations and may not be up to date. The services of
													Onlinepunks - especially parts of the search engine - should only be used as an assistance and
													not be trusted completely. Claims of any kind in regards to the accuracy of the provided data
													are prohibited.
													<br />
													<br />
												</li>
												<li>
													The customer must use the Onlinepunks services only for the designated purposes. Unless there
													are divergent written agreements, these purposes only cover the advertisement and marketing of
													the customer’s products.&nbsp; It is particularly forbidden to use any service provided by
													Onlinepunks for the following purposes:
													<br />
													<br />
													<ol>
														<li>Mass-mailing or mass-messaging any contact provided by Onlinepunks (e.g. Spam).</li>
														<li>
															Usage of the services or data provided by Onlinepunks in any way that it could violate
															national or international laws. In particular this includes laws by the customer’s nation
															of residence, laws by the Onlinepunks state of residence, or laws by the nation of
															residence of any third party that is involved.
														</li>
														<li>Automated or partly automated usage of any part of the service.</li>
														<li>Storing or fetching of unusual large amounts of data.</li>
														<li>
															Transferring any data or information provided by Onlinepunks to any third party outside
															your organization (unless there is written approval by Onlinepunks)
															<br />
															<br />
														</li>
													</ol>
												</li>
												<li>
													The customer is obligated to provide access to any information that is required for
													Onlinepunks to fulfill the services offered and purchased by the customer.&nbsp;
													<br />
													<br />
												</li>
												<li>
													The customer is obligated to verify that at any time no copyrights or other rights of third
													parties are violated.
													<br />
													<br />
												</li>
											</ol>
										</li>
										<li>
											Liability & warranties
											<br />
											<br />
											<ol>
												<li>
													Onlinepunks guarantees that the services provided comply with the agreed service description.
													Onlinepunks does not assume any warranty for third-party services, such as error-free and
													secure network operation. Any claims for damages on the part of the customer shall be made in
													writing by the customer - without prejudice to statutory or contractually agreed deadlines -
													within 3 months of knowledge of the damage and the injuring party, otherwise they shall
													expire. The customer's obligation to examine and give notice of defects remains entirely
													unaffected.
													<br />
													<br />
												</li>
												<li>
													As an error in the performance of Onlinepunks only reproducible errors are considered, which
													originate from a qualitatively deficient performance on the part of Onlinepunks. Other causes,
													such as faulty operation, external optimization measures and changes in content, defective
													hardware, adverse environmental conditions or poor data quality shall not be regarded as
													errors within the meaning of the warranty.
													<br />
													<br />
												</li>
												<li>
													The risk of the legal permissibility of the measures developed and implemented by Onlinepunks
													is carried by the customer. This applies in particular in the event that the actions and
													measures contravene provisions of competition law, copyright law and specific advertising
													laws. The customer exempts Onlinepunks from such claims of third parties. This also includes
													legal prosecution costs in the event that Onlinepunks may use third parties. Onlinepunks shall
													notify the customer of such concerns in writing immediately after becoming aware of them. If
													Onlinepunks considers a competition law examination by a particularly competent person or
													institution to be necessary for a measure to be carried out, the costs for this shall be
													carried by the customer after consultation with Onlinepunks.
													<br />
													<br />
												</li>
												<li>
													Onlinepunks is only liable for damages that it or its vicarious agents have caused
													intentionally or through gross negligence. The liability of Onlinepunks is limited to the
													typical damage to be expected by Onlinepunks for the service in question. This limitation of
													liability shall also apply to consequential and indirect damages in the event of simple
													negligence, except for death, personal injury or damage to health.&nbsp;
													<br />
													<br />
												</li>
												<li>
													Onlinepunks does not guarantee the full avoidance of downtime or loss of revenue.
													<br />
													<br />
												</li>
												<li>
													Excluded from the warranty are those errors that are caused by external influences, operating
													errors or changes, additions, installations or removals not carried out by Onlinepunks, repair
													attempts by the customer or other handling.
													<br />
													<br />
												</li>
												<li>
													The services - in particular the data provided by Onlinepunks - can contain errors and no
													guarantee can be given on correctness. The client himself is obliged to check the data for
													correctness. Furthermore, Onlinepunks assumes no liability for damages resulting from
													incorrect data or information provided. Please note that the data provided is either based on
													automated estimates and algorithms or provided by Instagram or its users. This information is
													not checked manually.
													<br />
													<br />
												</li>
												<li>
													If Onlinepunks is proven to have acted with gross negligence, liability for damages shall be
													limited to the amount of the net order value of the previous month.
													<br />
													<br />
												</li>
												<li>
													Onlinepunks will not pay compensation if the cause of a breach of duty lies outside the
													responsibility of Onlinepunks, for example because third parties do not provide the required
													supplier services properly or technical problems occur for which third parties are
													responsible.
													<br />
													<br />
												</li>
												<li>
													The customer is liable for damages to Onlinepunks caused intentionally or through gross
													negligence and based on an improper use of the services. In particular, the customer shall be
													liable for damages or lost profits caused by the transfer of the gained data or access data to
													third parties. The customer shall also be liable for any damage caused by system failures if
													these are due to improper use.
													<br />
													<br />
												</li>
											</ol>
										</li>
										<li>
											Pricing, payments & due dates
											<br />
											<br />
											<ol>
												<li>
													All prices are net prices plus statutory sales tax, unless otherwise stated.
													<br />
													<br />
												</li>
												<li>
													The invoice is issued at the beginning of the respective payment period and is settled by the
													deposited payment method.
													<br />
													<br />
												</li>
												<li>
													The customer is obliged to keep the payment data up to date and to inform Onlinepunks
													immediately in case of modifications.
													<br />
													<br />
												</li>
												<li>
													The interest charged by Onlinepunks in the event of default in payment on the part of the
													customer shall be based on the base interest rate of the European Central Bank plus 5 percent.
													Compensation for further damage caused by the delay can also be claimed.
													<br />
													<br />
												</li>
											</ol>
										</li>
										<li>
											Contract duration, termination
											<br />
											<br />
											<ol>
												<li>
													Contract periods, periods of notice and extensions are agreed individually and stipulated
													precisely within the scope of the offer. Unless otherwise specified in the offer, the billing
													periods are monthly and automatically extended at the end of each billing period. Notices of
													termination can be given at the latest before the start of the billing period. If notice of
													termination is given during a billing period, termination shall not take place until the end
													of the respective billing period.
													<br />
													<br />
												</li>
												<li>
													If there are important reasons, immediate termination of the contract is possible regardless
													of the minimum periods. Such reasons shall be deemed to be 1. non-payment by the client, 2.
													breaches of contractually agreed obligations, such as confidentiality or appropriate use. In
													addition, a reason justifying the termination shall be assumed as soon as third parties raise
													objections against the use of the terms and contents specified and posted by the customer.
													<br />
													<br />
												</li>
											</ol>
										</li>
										<li>
											Contribution
											<br />
											<br />
											<ol>
												<li>
													All information and other cooperation services required for the performance of the
													contractually agreed services must be made available or granted to Onlinepunks by the
													customer.
													<br />
													<br />
												</li>
												<li>
													The quality of the services provided by Onlinepunks must be immediately evaluated by the
													customer, any deficiencies found must be immediately presented to Onlinepunks in writing and
													in detail. A period of 14 days from the provision of the service shall apply for notification
													of faults.
													<br />
													<br />
												</li>
												<li>
													If the customer does not fulfill his obligations to cooperate, Onlinepunks is also exempt from
													the obligation to provide services.
													<br />
													<br />
												</li>
												<li>
													The customer shall ensure that Onlinepunks, if necessary, gain access (or comparable) to the
													websites to be optimized.
													<br />
													<br />
												</li>
												<li>
													Unless otherwise agreed in writing, Onlinepunks has the right, for the purpose of its own
													marketing, to publish an existing contractual relationship by naming the client and in
													presentation documents. In addition, both parties undertake to maintain silence regarding
													details of the contractual relationship.
													<br />
													<br />
												</li>
											</ol>
										</li>
										<li>
											Confidentiality and data protection
											<br />
											<br />
											<ol>
												<li>
													The use of the service is permitted only to the registered user. A passing on of the
													registration data to third parties is prohibited.
													<br />
													<br />
												</li>
												<li>
													Onlinepunks is obliged to treat all knowledge which it receives from the customer on the basis
													of an order as strictly confidential for an unlimited period of time and to obligate both its
													employees and third parties engaged by it to absolute confidentiality in the same manner.
													<br />
													<br />
												</li>
												<li>
													The customer undertakes to keep secret all business and company secrets or information
													designated as confidential that he receives or becomes aware of during the execution of the
													contract by Onlinepunks or persons acting on behalf of Onlinepunks. This applies in particular
													to information about algorithms and technologies of the social media platforms, as far as
													these are not generally known or also data and information which have become known to the
													customer through the service. This obligation also applies for two years after the termination
													of the contract.
													<br />
													<br />
												</li>
												<li>
													Onlinepunks stores all data of the customer electronically during the duration of the
													contractual relationship, as far as this is necessary for the fulfillment of the contract
													purpose, in particular for accounting purposes. In addition, usage data (time of entry, input
													values, number of entries, user's IP address, user's data stored on the platform, user's data
													exports, etc.) is stored and processed in order to improve products, find and eliminate errors
													in the software, or to detect misuse. Onlinepunks also processes and uses the collected data
													anonymously for advising its customers, for advertising and market research for its own
													purposes and for the demand-oriented design of its telecommunications services. Onlinepunks
													will provide the customer at any time on request with complete and free information about the
													stored data stock, as far as he is concerned, and delete all stored personal data at the
													customer's request.
													<br />
													<br />
												</li>
												<li>
													The stored inventory data includes the personal data, namely name/company, profession, date of
													birth, company register number, powers of representation, contact person, business address and
													other addresses of the customer, telephone number, fax number, e-mail address, bank details
													and VAT number, as well as the access data ('login') of the company.
													<br />
													<br />
												</li>
												<li>
													Onlinepunks will neither forward this data nor the content of private messages from the
													customer to third parties without the customer's consent. This does not apply to the extent
													that Onlinepunks is obliged to disclose such data to third parties, in particular government
													agencies, or to the extent that internationally recognized technical standards provide for
													this and the customer does not disagree.
													<br />
													<br />
												</li>
												<li>
													Onlinepunks expressly indicates to the customer that data protection for data transmissions in
													open networks such as the Internet cannot be comprehensively guaranteed according to the
													current state of technology.
													<br />
													<br />
												</li>
												<li>
													The customer knows that the provider can view the website stored on the web server and, under
													certain circumstances, other customer data stored there at any time from a technical point of
													view. Other Internet users may also be technically able to interfere with network security
													without authorization and control message traffic. The customer is fully responsible for the
													security of the data transmitted by him to the Internet and stored on web servers.
													<br />
													<br />
												</li>
											</ol>
										</li>
										<li>
											Fraud
											<br />
											<br />
											<ol>
												<li>
													We reserve the right to seek criminal and contractual sanctions against you if you are
													involved in fraud, dishonest or criminal acts and will make such reports as necessary to the
													authorities.
													<br />
													<br />
												</li>
												<li>
													You shall indemnify and shall be liable to pay us, on demand, all costs, charges or losses
													sustained or incurred by us (including any direct, indirect or consequential losses, loss of
													profit and loss of reputation) arising directly or indirectly from your fraud, dishonesty or
													criminal actions.
													<br />
													<br />
												</li>
											</ol>
										</li>
										<li>
											Disputes
											<br />
											<br />
											<ol>
												<li>
													If you wish to make a query or complaint regarding the Website, our products or services, as a
													first step you should as soon as reasonably practicable contact our Customer Support Team.
													<br />
													<br />
												</li>
												<li>
													If, after having dealt with a member of the Customer Support Team, your query or complaint is
													not resolved, you can ask for the query/complaint to be escalated to a manager or supervisor.
													Our manager/supervisor will look into your query/complaint in more detail and will contact you
													as soon as practically possible. You will be given the name and status of the person who your
													query/complaint has been referred to.
													<br />
													<br />
												</li>
											</ol>
										</li>
										<li>
											General
											<br />
											<br />
											<ol>
												<li>
													Entire Agreement. These Terms of Use and any document expressly referred to in them and any
													guidelines or rules posted on our Website constitute the entire agreement and understanding
													between you and us in relation to the subject matter of the Terms of Use and supersede any
													prior agreement, understanding or arrangement between us, whether oral or in writing. You
													acknowledges and agree that in accepting these Terms of Use you do not rely on, and shall have
													no remedy in respect of, any statement, representation, warranty, understanding, promise or
													assurance (whether negligently or innocently made) of any person other than as expressly set
													out in these Terms of Use. Nothing in this clause shall operate to limit or exclude any
													liability for fraud, fraudulent or negligent misrepresentation.
													<br />
													<br />
												</li>
												<li>
													Waiver. No delay, failure or omission (in whole or in part) in enforcing, exercising or
													pursuing any right, power, privilege, claim or remedy conferred by or arising under these
													Terms of Use or by law, be deemed to be or construed as a waiver of the same.Similarly, any
													single or partial exercise or pursuance of such right, power, privilege, claim or remedy,
													shall not preclude any further exercise of any of the same or the exercise of any other right,
													other right, power, privilege, claim or remedy.
													<br />
													<br />
												</li>
												<li>
													Assignment, Transfer, etc. You may not assign, transfer, charge, licence, sub-licence or
													otherwise deal in your rights and/or obligations under the Terms of Use without our prior
													written consent. We are entitled to assign, transfer, charge or otherwise deal in our rights
													under these Terms of Use as we see fit.
													<br />
													<br />
												</li>
												<li>
													Severability. If any of the Terms of Use are determined by any court, administrative body or
													other competent authority to be invalid, unlawful or unenforceable to any extent, such term,
													condition or provision will to that extent be severed from the remaining Terms and Conditions
													which will continue to be valid to the fullest extent permitted by law.
													<br />
													<br />
												</li>
												<li>
													Notices. Any notices given or made under these Terms of Use shall be in writing and shall be
													delivered as follows:
													<ol>
														<li>
															Notices to you shall be sent by email to the relevant email address provided by you when
															opening an Account with us or by any of the methods set out in clause 28.5(b)(i) to (iii)
															inclusive.
														</li>
														<li>
															If you have not opened an Account with us, notices to you shall be delivered: (i) by email
															to any email address you may have used to communicate with us, (ii) by post, courier or
															other form of physical delivery to your home or place of work or (iii) by personal
															delivery to you.
														</li>
														<li>
															Notices to us shall be sent by email to Customer Support Team or to the registered address
															of Primed Marketing Agency GmbH, Wipplingerstraße 12/2/4, 1010 Wien, Austria.
															<br />
															<br />
														</li>
													</ol>
												</li>
												<li>
													Third Party Rights. Unless expressly stated, nothing in the Terms of Use shall create or
													confer any rights or any other benefits whether pursuant to the English Contracts (Rights of
													Third Parties) Act 1999 or otherwise in favour of any person other than you and us.
													<br />
													<br />
												</li>
												<li>
													No Partnership, Agency, etc. Nothing in the Terms of Use shall be construed as creating any
													agency, partnership or any other form of joint enterprise between you and us nor to authorise
													either to act as agent for the other and neither you or us shall have authority to act in the
													name of or on behalf of or otherwise to bind the other in any way.
													<br />
													<br />
												</li>
												<li>
													Force Majeure. Neither you nor us shall be liable to the other in respect of anything which,
													apart from this provision, may constitute breach of these Terms of Use arising by reason of
													force majeure, namely circumstances beyond the control of either you or us which shall include
													(but not be limited to) acts of God, terrorism, perils of the sea or air, fire, flood,
													drought, explosion, embargo, riots, labour strikes, civil commotion or civil authority
													including acts of local government and parliamentary authority, terrorist attacks, global
													computer viruses which affect the internet backbone.
													<br />
													<br />
												</li>
												<li>
													Governing Law & Jurisdiction. The validity, construction and performance of these Terms of Use
													(and any claim, dispute or matter arising under or in connection with it or its
													enforceability) shall be governed by and construed in accordance with the laws of Austria. We
													and you irrevocably submit to the exclusive jurisdiction of the Austria courts over any claim,
													dispute or matter arising under or in connection with this Agreement or its enforceability.
												</li>
											</ol>
										</li>
									</ol>
									<p>
										<br />
										<br />
										01.06.2019
									</p>
									<p></p>
								</>
							)}
							{language == 'de' && (
								<>
									<h1>
										Allgemeine Geschäftsbedingungen vom 01.06.2019
										<br />
										<br />
									</h1>
									<p></p>
									<ol>
										<li>Einleitung</li>
									</ol>
									<p></p>
									<ol>
										<ol>
											<li>
												Diese Allgemeinen Geschäftsbedingungen beziehen sich auf die Websiten, Produkte oder Marken
												infludata bzw. infludata.com und paymentpunks bzw. Paymentpunks.com und onlinepunks oder
												onlinepunks.com der Primed Marketing Agency GmbH, Wipplingerstraße 12/2/4, 1010 Wien, Österreich
												(im Folgenden “Onlinepunks”, “Wir”, “Uns”) und alle anderen Websites oder Produkte, die uns
												gehören und unsere Produkte und Dienstleistungen enthalten (unsere „Website“ oder
												“Dienstleistungen”). Diese allgemeinen Geschäftsbedingungen werden zwischen uns und dem Besucher
												unserer Websiten oder - falls zutreffend - mit dem Nutzer oder Käufer unserer Dienstleistungen
												vereinbart (im Folgenden als “Sie”, “Nutzer”, “Auftraggeber” oder “Kunde”).&nbsp;
											</li>
											<li>
												Bitte lesen Sie diese Allgemeinen Geschäftsbedingungen aufmerksam durch, bevor Sie einen Bereich
												unserer Website nutzen oder bevor Sie Produkte oder Dienstleistungen erwerben. Durch die Nutzung
												jeglichen Bereichs unserer Website oder die Registrierung bei uns über die Website erklären Sie
												Ihr Einverständnis mit:
												<ol>
													<li>(a) diesen Allgemeinen Geschäftsbedingungen;</li>
													<li>(b) den Datenschutzrichtlinien; und</li>
													<li>
														(c) jeglichen Geschäftsbedingungen und Regelungen in Bezug auf Werbeaktionen oder
														Sonderangebote, die von Zeit zu Zeit Anwendung finden;
														<br />
														(zusammen: die „Nutzungsbedingungen“).
													</li>
												</ol>
											</li>
											<li>
												Falls die Nutzungsbedingungen in irgendeine andere Sprache übersetzt werden, dient die
												Übersetzung ausschließlich zur Information, und im Falle irgendeiner Abweichung zwischen der
												Übersetzung und der deutschsprachigen Version, ist die deutschsprachige Version verbindlich.
											</li>
											<li>
												Durch Ihre fortgesetzte Nutzung der Website oder einer Bestellung erklären Sie sich mit den
												Nutzungsbedingungen einverstanden.
											</li>
											<li>Die Nutzungsbedingungen regeln Ihre Vertragsvereinbarung mit uns.</li>
											<li>
												Sollte es irgendeinen Widerspruch oder irgendeine Unstimmigkeit in den Vertragsbedingungen
												geben, gilt die in Absatz 1.2 festgesetzte Rangordnung.
											</li>
											<li>
												Wir verwenden Cookies, um Ihren Besuch der Website bestmöglich zu gestalten. Wenn Sie
												irgendeinen Teil unserer Website weiterhin nutzen, ohne Ihre Cookie-Einstellungen zu verändern,
												setzen wir voraus, dass Sie unserer Cookie-Verwendung auf der Website zustimmen. Sie können Ihre
												Cookie-Einstellungen jederzeit ändern, allerdings könnten in diesem Fall einige Funktionen auf
												unserer Website nicht zur Verfügung stehen. Weitere Informationen finden Sie in unserer
												Datenschutzerklärung.
												<br />
												<br />
											</li>
										</ol>
										<li>
											Veränderungen der Nutzungsbedingungen
											<br />
											<br />
											<ol>
												<li>
													Wir behalten uns das Recht vor, jederzeit Änderungen an den Nutzungsbedingungen vorzunehmen.
													Wir werden angemessene Maßnahmen ergreifen um sicherzustellen, dass Sie über jegliche wichtige
													Änderung über einen angemessenen Weg (zum Beispiel über Email oder mittels einer Nachricht auf
													der Website) informiert werden. Es unterliegt allerdings Ihrer Verantwortung, die
													Nutzungsbedingungen von Zeit zu Zeit zu prüfen, um sicherzustellen, dass Sie ihnen zustimmen,
													und Ihre fortgesetzte Nutzung der Website wird als Zustimmung zu allen Änderungen, die wir
													vornehmen, befunden.
												</li>
											</ol>
										</li>
									</ol>
									<p></p>
									<ol>
										<li>
											Nutzung der Website
											<br />
											<br />
											<ol>
												<li>Sie sollen die Website ausschließlich zu rechtmäßigen Zwecken nutzen.</li>
												<li>
													Sie sind nicht dazu befugt, die Website oder irgendwelche Teile von ihr in irgendeiner Form
													ohne unsere ausdrückliche Einverständnis zu vervielfältigen.
												</li>
												<li>
													Sie dürfen nicht:
													<ol>
														<li>
															versuchen, sich unautorisierten Zugang zu der Website, zu ihrem Server oder irgendeinem
															anderen Server, Computer oder einer Datenbank zu verschaffen, die mit unserer Website
															verbunden ist;
														</li>
														<li>
															unsere Website über eine Denial-of-Service-Attacke oder eine
															Distributed-Denial-of-Service-Attacke angreifen; und
														</li>
														<li>
															die Website wissentlich durch Viren, Trojaner, Würmer, Logikbomben, Zeitbomben, Keylogger,
															Spyware, Adware oder jegliches andere schädliche Material, das zur negativen Beeinflussung
															der Funktionsweise von jeglicher Computer-Software oder -Hardware entwickelt wurde,
															infizieren.
														</li>
														<li>
															Sie machen sich eventuell einer Straftat schuldig, wenn Sie irgendeine der in Abschnitt
															5.3. aufgeführten Bedingungen verletzen. Wir werden eine solche Verletzung den zuständigen
															Vollzugsbehörden melden und mit diesen Behörden kooperieren, indem wir Informationen über
															Sie bereitstellen. Im Falle einer solchen Verletzung wird Ihr Recht zur Nutzung unserer
															Website unmittelbar erlöschen.
														</li>
													</ol>
												</li>
												<li>
													&nbsp;Sie sollen die Website nicht nutzen:
													<ol>
														<li>
															indem Sie gegen geltende örtliche, nationale oder internationale Gesetze oder Verordnungen
															verstoßen;
														</li>
														<li>
															in irgendeiner rechtswidrigen oder betrügerischen Weise oder in einer Weise, die
															rechtswidrige oder betrügerische Absichten oder Folgen hat;
														</li>
														<li>
															zu irgendeinem Zweck, der schädlich, bedrohlich, diffamierend, verleumderisch,
															beleidigend, hasserfüllt, sexistisch oder diskriminierend ist oder so verstanden wird,
															Anstoß erregen könnte oder anderweitig beleidigend ist;
														</li>
														<li>
															um Minderjährige in irgendeiner Weise zu schaden, sie zu bedrohen oder den Versuch zu
															unternehmen, ihnen Schaden zuzufügen;
														</li>
														<li>
															um irgendein unerwünschtes oder unautorisiertes Werbe- oder Promotion-Material oder
															irgendeine andere Form von ähnlicher Werbung (Spam) zu versenden oder zur
															Versandbereitstellung zu nutzen;
														</li>
														<li>um andere Personen zu „stalken“ oder anderweitig zu belästigen;</li>
														<li>
															sich als eine andere natürliche oder juristische Person, hierunter – aber nicht
															ausschließlich – Vertreter von Onlinepunks, auszugeben oder Ihre Verbindung mit einer
															natürlichen oder juristischen Person fälschlich oder anderweitig unangemessen
															darzustellen;
														</li>
														<li>
															um Kopfzeilen zu fälschen oder Identifikationsmerkmale anderweitig zu manipulieren, um die
															Herkunft der über die Website übertragenen Inhalte zu verschleiern;
														</li>
														<li>
															um den normalen Dialogfluss zu unterbrechen oder in einer anderen Weise zu agieren, die
															andere Nutzer in ihrer Fähigkeit zum Echtzeitaustausch einschränkt;
														</li>
														<li>
															um persönliche Daten anderer Nutzer unserer Website in Verbindung mit den in den
															Nutzungsbedingungen untersagten Verhaltensweisen und Aktivitäten zu sammeln oder zu
															speichern.
														</li>
													</ol>
												</li>
												<li>
													Sie dürfen nicht einschränken, beschädigen oder zerstören:
													<ol>
														<li>irgendeinen Teil unserer Website;</li>
														<li>irgendein Netzwerk, in dem unsere Website gespeichert ist;</li>
														<li>irgendeine zur Bereitstellung unserer Website verwendete Software.</li>
													</ol>
												</li>
												<li>
													Wir übernehmen keine Haftung für irgendwelche Verluste oder Schäden, die durch eine
													Distributed-Denial-of-Service-Attacke, Viren oder durch anderes technologisch schädliches
													Material verursacht wird, die Ihre Computerausrüstung, Computerprogramme, Daten oder anderes
													eigenes Material aufgrund Ihrer Nutzung unserer Website oder Ihres Downloads von irgendeinem
													auf dieser Website oder auf irgendeiner verlinkten Website bereitgestellten Materials
													infizieren können.
													<br />
													<br />
												</li>
											</ol>
										</li>
										<li>
											Registrierung, Konto-Eröffnung & Konto-Verwaltung
											<br />
											<br />
											<ol>
												<li>Durch Ihre Registrierung bei uns eröffnen Sie ein Konto („Konto”) bei uns.</li>
												<li>
													Wenn Sie sich registrieren und bei uns ein Konto eröffnen, werden wir Sie dazu auffordern,
													einen Nutzernamen und ein Password zu wählen. Sie sollten sicherstellen, dass diese Details
													privat bleiben. Sie sind für alle mit Ihrem Nutzernamen (wo die korrekten
													Sicherheitsinformationen zur Verfügung gestellt werden) verbundenen Aktivitäten
													verantwortlich. Wenn Sie Ihren Nutzernamen und/oder Ihr Password verlieren oder vergessen,
													oder wenn Sie glauben, dass eine dritte Person Kenntnis von ihnen hat, sollten Sie uns
													unverzüglich kontaktieren, damit Ihnen neue Sicherheitsdetails zur Verfügung gestellt werden
													können. Diese neuen Details werden Ihnen per Email zugeschickt.
												</li>
												<li>
													Wenn eine andere Person auf Ihr Konto zugreift, sind Sie allein für alle ihre Aktionen
													verantwortlich, unabhängig davon, ob der Zugriff von Ihnen genehmigt wurde. Hiermit halten Sie
													uns schadlos und frei von jeglicher Haftung für Kosten, Ansprüche, Ausgaben und Schäden jeder
													Art, die in Verbindung mit der Nutzung oder dem Zugriff auf Ihr Konto durch irgendeine dritte
													Partei entstehen.
												</li>
											</ol>
										</li>
									</ol>
									<p></p>
									<ol>
										<li>
											Leistungsgegenstand Infludata und Paymentpunks
											<br />
											<br />
											<ol>
												<li>
													Geschäftsinhalt von Onlinepunks ist die Erbringung von technischen Dienstleistungen, vor allem
													der Bereitstellung von Daten und Auswertungen von Social-Media Profilen, die für Werbe- und
													Marketingzwecke genutzt werden. Es handelt sich insbesondere um Nutzer öffentlicher Profile
													auf der Plattform Instagram. Die bereitgestellten Daten werden automatisiert verarbeitet und
													basieren auf Schätzungen und Algorithmen von Onlinepunks oder auf Daten, die von Instagram
													oder deren Nutzer öffentlich bereitgestellt werden.
													<br />
													<br />
												</li>
												<li>
													Grundlage aller zwischen Onlinepunks und dem Auftraggeber bestehenden Verbindungen und
													Verträge sind diese Allgemeinen Geschäftsbedingungen (AGB). Davon abweichende Regelungen und
													AGB – etwa des Auftraggebers – besitzen keine Gültigkeit, es sei denn, Onlinepunks stimmt
													ihnen ausdrücklich und schriftlich zu.
													<br />
													<br />
												</li>
												<li>
													Onlinepunks steht in keinem Verhältnis zu Facebook Inc. oder irgendeiner anderen Plattform.
													<br />
													<br />
													<br />
												</li>
											</ol>
										</li>
										<li>
											Angebot und Vertragsabschluss
											<br />
											<br />
											<ol>
												<li>
													Angebote - sowohl persönlich, als auch im Internet öffentlich zur Verfügung gestellt - sind
													freibleibend. Bestellungen bzw. Erklärungen zur Angebotsannahme seitens des Auftraggebers
													müssen schriftlich oder elektronisch erfolgen und bedürfen zu ihrem Wirksamwerden der
													elektronischen oder schriftlichen Zustimmung von Onlinepunks.
													<br />
													<br />
												</li>
												<li>
													Für sämtliche Angebote, Leistungen und Lieferungen gelten diese AGB als Rechtsgrundlage.
													Abweichende oder zusätzliche Vereinbarungen müssen schriftlich fixiert werden, insbesondere
													Konditionen seitens des Auftraggebers, die auch dann keine automatische Gültigkeit erlangen,
													wenn Onlinepunks sie im Rahmen eines Dienstleistungsvertrags nicht explizit zurückweist.
													<br />
													<br />
												</li>
												<li>
													Die Tätigkeit von Onlinepunks tritt nach der schriftlichen beiderseitigen Zustimmung in Kraft
													und wird mit Versand einer Bestellbestätigung bestätigt. Im Falle eines Abonnements werden die
													Leistungen - wenn nicht anders schriftlich vereinbart -&nbsp; nach Ablauf des
													Leistungszeitraum automatisch verlängert, wenn keine Kündigung bzw. keine fristgerechte
													Kündigung erfolgt ist (siehe Punkt 6).&nbsp;&nbsp;
												</li>
											</ol>
										</li>
									</ol>
									<p></p>
									<ol>
										<li>
											Leistungsbeschreibung
											<br />
											<br />
											<ol>
												<li>
													Der Umfang der zu erbringenden Leistungen ergibt sich aus der Leistungsbeschreibung des auf
													der Webseite “infludata.com” ausgewählten Produkts und der schriftlichen Auftragsbestätigung
													durch Onlinepunks (Bestellbestätigung). Nachträgliche Änderungen des Leistungsinhaltes
													bedürfen der schriftlichen Bestätigung durch Onlinepunks. Kommt es im Rahmen der Bestellung zu
													technischen Problemen oder wird ein falsches Produkt bereitgestellt, ist Onlinepunks sofort
													nach Kenntnisnahme davon zu unterrichten.
													<br />
													<br />
												</li>
												<li>
													Alle bereitgestellten Daten von Instagramnutzern sind unverbindlich, stammen von öffentlich
													zugänglichen Quellen und können mit großer Wahrscheinlichkeit Fehler enthalten. Alle Werte
													sind lediglich Schätzungen von Onlinepunks und entsprechen oft nicht dem aktuellen Stand. Die
													Dienstleistungen - insbesondere die Suchfunktion - stellen lediglich eine Hilfestellung bei
													der Recherche dar. <br />
													<br />
												</li>
												<li>
													Die Dienstleistungen dürfen in jedem Fall nur für den vorhergesehenen Zweck verwendet werden.
													Wenn es nicht anders angegeben ist, entspricht dies Werbe- und Marketingzwecke des
													Auftraggebers. Insbesondere ist die Verwendung der Dienstleistungen zu folgenden Zwecken
													untersagt:
													<ol>
														<li>
															Versand von Massen-Emails oder Nachrichten an bereitgestellte Kontaktadressen (“Spam”)
														</li>
														<li>
															Nutzung der Daten in einer Weise, die gegen bestehendes Recht, entweder im ansässigen
															Staat von Onlinepunks, oder auch im ansässigen Staat des Auftraggebers verstoßen
														</li>
														<li>(Teil-) automatisierte Nutzung der Plattform “infludata.com”</li>
														<li>
															Speicherung oder Abrufen unüblich großer Mengen von Daten, die auf eine missbräuchliche
															Nutzung schließen lassen
														</li>
														<li>
															Weitergabe gewonnener Informationen oder Daten an Dritte außerhalb der Organisation des
															Auftraggebers (wenn nicht schriftlich anders vereinbart)
															<br />
															<br />
														</li>
													</ol>
												</li>
											</ol>
										</li>
									</ol>
									<p></p>
									<ol>
										<ol>
											<li>
												Der Kunde wird Onlinepunks zeitgerecht und vollständig alle Informationen und Unterlagen
												zugänglich machen, die für die Erbringung der Leistung erforderlich sind..
												<br />
												<br />
											</li>
											<li>
												Der Kunde ist verpflichtet, die für die Durchführung des Auftrages zur Verfügung gestellten
												Unterlagen auf Urheber-, Kennzeichenrechte oder sonstige Rechte Dritter zu überprüfen.
												<br />
												<br />
											</li>
										</ol>
									</ol>
									<p>
										<br />
										<br />
									</p>
									<ol>
										<li>
											Haftung, Gewährleistung und Garantie
											<br />
											<br />
											<ol>
												<li>
													Onlinepunks gewährleistet, dass die erbrachten Leistungen der vereinbarten
													Leistungsbeschreibung entsprechen. Für Leistungen Dritter, etwa den fehlerfreien und sicheren
													Netzwerkbetrieb, übernimmt Onlinepunks keine Gewährleistung. Allfällige
													Schadenersatzforderungen des Auftraggebers sind von diesem – unbeschadet gesetzlicher oder
													vertraglich vereinbarter Fristen – binnen 3 Monaten ab Kenntnis des Schadens und des
													Schädigers bei sonstigem Verfall schriftlich anzumelden. Die Untersuchung- und
													Rügeobliegenheit des Auftraggebers bleibt davon gänzlich unberührt.
													<br />
													<br />
												</li>
												<li>
													Als Fehler in der Leistungserbringung von Onlinepunks gelten lediglich reproduzierbare Fehler,
													die auf eine qualitativ mangelhafte Leistung seitens Onlinepunks zurückgehen. Andere Ursachen,
													etwa fehlerhafte Bedienung, fremde Optimierungsmaßnahmen und inhaltliche Veränderungen,
													schadhafte Hardware, widrige Umgebungsbedingungen oder schlechte Datenqualität gelten nicht
													als Fehler im Sinne der Gewährleistung.
													<br />
													<br />
												</li>
												<li>
													Das Risiko der rechtlichen Zulässigkeit der durch Onlinepunks erarbeiteten und durchgeführten
													Maßnahmen wird vom Kunden getragen. Das gilt insbesondere für den Fall, dass die Aktionen und
													Maßnahmen gegen Vorschriften des Wettbewerbsrechts, des Urheberrechts und der speziellen
													Werberechtsgesetze verstoßen. Der Kunde stellt Onlinepunks von derartigen Ansprüchen Dritter
													frei. Dieses umfasst auch die Rechtsverfolgungskosten im Falle einer Inanspruchnahme von
													Onlinepunks durch Dritte. Die Anmeldung solcher Bedenken durch Onlinepunks beim Kunden erfolgt
													unverzüglich nach Bekanntwerden in schriftlicher Form. Erachtet Onlinepunks für eine
													durchzuführende Maßnahme eine wettbewerbsrechtliche Prüfung durch eine besonders sachkundige
													Person oder Institution für erforderlich, so trägt nach Absprache mit Onlinepunks die Kosten
													hierfür der Kunde.
													<br />
													<br />
												</li>
												<li>
													Onlinepunks haftet nur für Schäden, die sie oder ihre Erfüllungsgehilfen vorsätzlich oder grob
													fahrlässig herbeigeführt haben. Die Haftung von Onlinepunks wird beschränkt auf die
													typischerweise bei der betreffenden Dienstleistung der von Onlinepunks zu erwartenden Schäden.
													Diese Haftungsbeschränkung gilt auch für Folgeschäden und mittelbare Schäden im Fall einfacher
													Fahrlässigkeit, mit Ausnahme von Lebens-, Körper- oder Gesundheitsverletzungen.
													<br />
													<br />
												</li>
												<li>
													Onlinepunks übernimmt keine Gewähr für die vollständige Vermeidung von Ausfällen oder
													Umsatzeinbußen.
													<br />
													<br />
												</li>
												<li>
													Von der Gewährleistung ausgeschlossen sind grundsätzlich solche Fehler, die durch äußere
													Einflüsse, Bedienungsfehler oder nicht von Onlinepunks durchgeführte Änderungen, Ergänzungen,
													Ein- oder Ausbauten, Reparaturversuche des Kunden oder sonstige Manipulationen entstehen.
													<br />
													<br />
												</li>
												<li>
													Die Leistungen - insbesondere die durch Onlinepunks bereitgestellten Daten - können Fehler
													enthalten und es kann kann keine Garantie auf Richtigkeit gegeben werden. Der Auftraggeber
													selbst ist verpflichtet, die Daten auf Richtigkeit zu überprüfen. Weiter übernimmt Onlinepunks
													keine Haftung für Schäden, die aus fehlerhaften bereitgestellten Daten oder Informationen
													hervorgegangen sind. Es wird darauf hingewiesen, dass die bereitgestellten Daten entweder auf
													automatisierten Schätzungen und Algorithmen beruhen oder von Instagram oder deren Nutzer
													bereitgestellt wurden. Diese Informationen werden nicht manuell überprüft.
													<br />
													<br />
												</li>
												<li>
													Wird Onlinepunks grobe Fahrlässigkeit nachgewiesen, ist die Haftung für Schäden auf die Höhe
													der durch die Leistungen des Netto-Auftragswerts des vergangenen Monats begrenzt.
													<br />
													<br />
												</li>
												<li>
													Onlinepunks leistet keinen Schadensersatz, wenn die Ursache einer Pflichtverletzung außerhalb
													des Onlinepunks-Verantwortungsbereichs liegt, etwa weil Dritte die erforderlichen
													Zuliefererdienste nicht ordnungsgemäß erbringen oder technische Probleme auftreten, für die
													Dritte verantwortlich sind.
													<br />
													<br />
												</li>
												<li>
													Der Auftraggeber haftet für Schäden, die Onlinepunks vorsätzlich oder durch grobe
													Fahrlässigkeit entstehen und auf eine missbräuchliche Verwendung der Dienstleistungen beruhen.
													Insbesondere haftet der Auftraggeber für Schäden oder entgangene Gewinne, die durch die
													Weitergabe der gewonnen Daten bzw. der Zugangsdaten an Dritte entstanden sind. Der
													Auftraggeber haftet ebenfalls für entstandene Schäden durch Ausfälle des Systems, sofern diese
													auf eine missbräuchliche Verwendung zurückzuführen sind.
													<br />
													<br />
													<br />
												</li>
											</ol>
										</li>
										<li>
											Preise, Zahlungen, Fälligkeit
											<br />
											<br />
											<ol>
												<li>
													Alle Preise verstehen sich netto zuzüglich gesetzlicher Umsatzsteuer, wenn nicht anders
													angegeben.
													<br />
													<br />
												</li>
												<li>
													Die Rechnungsstellung erfolgt jeweils zu Beginn der jeweiligen Zahlungsperiode und wird durch
													die hinterlegte Zahlungsmethode beglichen. <br />
													<br />
												</li>
												<li>
													Der Auftraggeber ist verpflichtet die Zahlungsdaten aktuell zu halten und Onlinepunks im Falle
													von Änderungen unmittelbar zu unterrichten.
													<br />
													<br />
												</li>
												<li>
													Die von Onlinepunks bei Zahlungsverzug seitens des Auftraggebers erhobenen Zinsen belaufen
													sich auf den Basiszinssatz der Europäischen Zentralbank zuzüglich 5 Prozent. Kompensationen
													für weitere Schäden, die durch die Verzögerung entstehen, können darüber hinaus ebenfalls
													eingefordert werden.
													<br />
													<br />
													<br />
												</li>
											</ol>
										</li>
										<li>
											Vertragslaufzeit, Kündigung
											<br />
											<br />
											<ol>
												<li>
													Vertragslaufzeiten, Kündigungsfristen und Verlängerungen werden individuell vereinbart und im
													Rahmen des Angebotes genau festgelegt. Wenn im Angebot nicht näher beschrieben, gelten die
													Abrechnungszeiträume monatlich und verlängern sich automatisch am Ende der jeweiligen
													Abrechnungsperiode. Kündigungen können spätestens vor Beginn der Abrechnungsperiode erfolgen.
													Wird während einer Abrechnungsperiode gekündigt, so erfolgt die Kündigung erst mit Ende der
													jeweiligen Abrechnungsperiode.
													<br />
													<br />
												</li>
												<li>
													Sollten wichtige Gründe vorliegen, ist unabhängig von den Mindestfristen eine sofortige
													Vertragskündigung möglich. Als solche Gründe gelten 1. ausbleibende Zahlungen seitens des
													Auftraggebers, 2. Verstöße gegen vertraglich festgelegte Pflichten, etwa die zur Geheimhaltung
													oder die zweckgemäße Nutzung. Außerdem wird ein die Kündigung rechtfertigender Grund
													angenommen, sobald von dritter Seite Einsprüche gegen die Verwendung der vom Auftraggeber
													genannten und eingestellten Begriffe und Inhalte erhoben werden.
													<br />
													<br />
													<br />
												</li>
											</ol>
										</li>
										<li>
											Mitwirkung
											<br />
											<br />
											<ol>
												<li>
													Alle für die Erbringung der vertraglich vereinbarten Leistungen erforderlichen Informationen
													und sonstigen Mitwirkungsleistungen müssen Onlinepunks vom Auftraggeber zugänglich gemacht
													bzw. gewährt werden.
													<br />
													<br />
												</li>
												<li>
													Die Qualität der von Onlinepunks erbrachten Leistungen muss vom Auftraggeber unverzüglich
													evaluiert, dabei festgestellte Mängel müssen sofort schriftlich und detailliert gegenüber
													Onlinepunks dargestellt werden. Es gilt eine Frist zur Mängelrüge von 14 Tagen ab
													Leistungserbringung.
													<br />
													<br />
												</li>
												<li>
													Sollte der Auftraggeber seine Pflichten zur Mitwirkung nicht erfüllen, so ist auch Onlinepunks
													von der Pflicht zur Leistungserbringung befreit. <br />
													<br />
												</li>
												<li>
													Der Auftraggeber trägt dafür Sorge, dass Onlinepunks, wenn notwendig, Zugriff (oder
													vergleichbares) auf die zu optimierenden Webseiten erhält.
													<br />
													<br />
												</li>
												<li>
													Wenn schriftlich nicht anders vereinbart, erhält Onlinepunks zum Zwecke des Eigenmarketings
													das Recht, ein bestehendes Vertragsverhältnis unter Nennung des Auftraggebers, sowie in
													Präsentationsunterlagen, öffentlich zu machen. Darüber hinaus verpflichten sich beide Seiten,
													zu Details des Vertragsverhältnisses Stillschweigen zu bewahren.
													<br />
													<br />
													<br />
													<br />
												</li>
											</ol>
										</li>
										<li>
											Geheimhaltung und Datenschutz
											<br />
											<br />
											<ol>
												<li>
													Die Nutzung der Dienstleistung ist nur dem registrierten Nutzer gestattet. Eine Weitergabe der
													Anmeldedaten an Dritte ist untersagt.
												</li>
											</ol>
										</li>
									</ol>
									<p></p>
									<ol>
										<ol>
											<li>
												Onlinepunks ist verpflichtet, alle Kenntnisse die sie aufgrund eines Auftrags vom Kunden erhält,
												zeitlich unbeschränkt streng vertraulich zu behandeln und sowohl ihre Mitarbeiter, als auch von
												ihr herangezogene Dritte ebenfalls in gleicher Weise zu absolutem Stillschweigen zu
												verpflichten.
												<br />
												<br />
											</li>
											<li>
												Der Kunde verpflichtet sich, alle ihm bei der Vertragsdurchführung von Onlinepunks oder im
												Auftrag von Onlinepunks handelnden Personen zugehenden oder bekanntwerdenden Geschäfts- und
												Betriebsgeheimnisse oder als vertraulich bezeichnete Informationen geheim zu halten. Dies gilt
												insbesondere für Informationen über Algorithmen und Technologien der Social-Media Plattformen,
												soweit diese nicht allgemein bekannt sind oder auch Daten und Informationen, die durch die
												Dienstleistung dem Auftraggeber bekannt geworden sind. Diese Verpflichtung gilt zudem auch zwei
												Jahre über das Vertragsende hinaus.
												<br />
												<br />
											</li>
											<li>
												Onlinepunks speichert alle Daten des Kunden während der Dauer des Vertragsverhältnisses
												elektronisch, soweit dies zur Erfüllung des Vertragszwecks, insbesondere für Abrechnungszwecke,
												erforderlich ist. Außerdem werden Daten über die Nutzung (Zeitpunkt der Eingabe, Eingabewerte,
												Anzahl der Eingaben, IP des Nutzer, auf der Plattform gespeicherte Daten des Nutzers, Daten über
												Datenexporte des Nutzers usw.) gespeichert und verarbeitet, um Produkte zu verbessern, Fehler in
												der Software zu finden und zu eliminieren, oder auch um missbräuchliche Nutzungen festzustellen.
												Die erhobenen Daten verarbeitet und nutzt Onlinepunks auch anonymisiert zur Beratung seiner
												Kunden, zur Werbung und zur Marktforschung für eigene Zwecke und zur bedarfsgerechten Gestaltung
												seiner Telekommunikationsleistungen. Onlinepunks wird dem Kunden auf Verlangen jederzeit über
												den gespeicherten Datenbestand, soweit er ihn betrifft, vollständig und unentgeltlich Auskunft
												erteilen und sämtliche gespeicherte personenbezogene Daten auf Kundenwunsch löschen.
												<br />
												<br />
											</li>
											<li>
												Die gespeicherten Bestandsdaten umfassen die persönlichen Daten, nämlich Name/Firma, Beruf,
												Geburtsdatum, Firmenbuchnummer, Vertretungsbefugnisse, Ansprechperson, Geschäftsanschrift und
												sonstige Adressen des Kunden, Telefonnummer, Telefaxnummer, E-Mail-Adresse, Bankverbindungen und
												UID-Nummer, sowie die Zugangsdaten (‘Login’) des Unternehmens.
												<br />
												<br />
											</li>
											<li>
												Onlinepunks wird weder diese Daten noch den Inhalt privater Nachrichten des Kunden ohne dessen
												Einverständnis an Dritte weiterleiten. Dies gilt insoweit nicht, als Onlinepunks verpflichtet
												ist, Dritten, insbesondere staatlichen Stellen, solche Daten zu offenbaren oder soweit
												international anerkannte technische Normen dies vorsehen und der Kunde nicht widerspricht.
												<br />
												<br />
											</li>
											<li>
												Onlinepunks weist den Kunden ausdrücklich darauf hin, dass der Datenschutz für
												Datenübertragungen in offenen Netzen wie dem Internet nach dem derzeitigen Stand der Technik
												nicht umfassend gewährleistet werden kann.
												<br />
												<br />
											</li>
											<li>
												Der Kunde weiß, dass der Provider das auf dem Webserver gespeicherte Seitenangebot und unter
												Umständen auch weitere dort abgelegte Daten des Kunden aus technischer Sicht jederzeit einsehen
												kann. Auch andere Teilnehmer am Internet sind unter Umständen technisch in der Lage, unbefugt in
												die Netzsicherheit einzugreifen und den Nachrichtenverkehr zu kontrollieren. Für die Sicherheit
												der von ihm ins Internet übermittelten und auf Web-Servern gespeicherten Daten trägt der Kunde
												vollumfänglich selbst Sorge.
												<br />
												<br />
												<br />
											</li>
										</ol>
										<li>
											<h2>Betrug</h2>
											<ol>
												<li>
													Wir behalten uns das Recht vor, Maßnahmen zur Umsetzung von Strafverfolgungen und Sanktionen
													gegen Sie zu ergreifen, wenn Sie in betrügerische, unehrliche oder verbrecherische Handlungen
													involviert sind, und wir werden diese nach Bedarf an die Behörden melden.
												</li>
												<li>
													Auf Verlangen entschädigen Sie uns für und halten uns schadlos gegen alle Kosten, Forderungen
													und Verluste (einschließlich aller direkten, indirekten und Folge-Verluste, entgangener
													Gewinne und Ansehensverlust), die uns direkt oder indirekt durch Ihren Betrug, Ihre
													Unehrlichkeit oder Ihr verbrecherisches Handeln entstehen.
												</li>
											</ol>
										</li>
										<li>
											<h2>&nbsp;Konflikte</h2>
											<ol>
												<li>
													Im Falle von Fragen oder Beschwerden in Verbindung mit unserer Website, unseren Produkten oder
													Dienstleistungen, sollten Sie als ersten Schritt und so bald es Ihnen möglich ist, unseren
													Kundendienst kontaktieren.
												</li>
												<li>
													Falls Ihre Anfrage oder Ihre Beschwerde nach der Kontaktierung des Kundendienstes nicht gelöst
													werden kann, können Sie zur Behebung oder Lösung der Anfrage/Beschwerde einen leitenden
													Angestellten oder Vorgesetzten verlangen. Unser leitender Angestellte/Vorgesetzter wird Ihre
													Anfrage/Beschwerde eingehend prüfen und Sie so schnell wie praktisch möglich kontaktieren. Sie
													werden über den Namen und Status der Person informiert, zu der Ihre Anfrage/Beschwerde
													weitergeleitet wurde.
												</li>
											</ol>
										</li>
										<li>
											<h2>Allgemeines</h2>
											<ol>
												<li>
													Gesamte Vereinbarung. Diese Nutzungsbedingungen und jegliches Dokument, das hierin
													ausdrücklich mit diesen in Verbindung gebracht wird, und alle Richtlinien oder Regelungen, die
													auf unserer Website veröffentlicht sind, stellen die gesamte Vereinbarung dar sowie das
													Einverständnis zwischen Ihnen und uns in Bezug auf den Vertragsgegenstand der
													Nutzungsbedingungen und ersetzen alle vorherigen mündlichen oder schriftlichen Vereinbarungen
													oder Absprachen zwischen Ihnen und uns. Mit der Anerkennung der Nutzungsbedingungen erkennen
													Sie an und erklären Sie sich einverstanden, dass Sie sich nicht verlassen oder einen Anspruch
													haben auf Aussagen, Darstellungen, Zusicherungen von Übereinkünften mit (gleich, ob
													fahrlässiger Natur oder in gutem Glauben gemacht) jedweder Person als in den
													Nutzungsbedingungen dargelegt. Mit keiner Angabe in diesem Abschnitt ist beabsichtigt, die
													Haftung für Betrug, betrügerische Absicht oder fahrlässige Falschdarstellung teilweise oder
													ganz auszuschließen.
												</li>
												<li>
													Verzicht. Keine Verzögerung, kein Fehler und keine Auslassung (im Ganzen oder in Teilen) bei
													dem Bestreben der Verfolgung oder Durchsetzung von Recht, Macht, Privileg, Ansprüchen oder
													Rechtsmitteln durch oder entstehend durch diese Nutzungsbedingungen oder via Gesetz, darf als
													Verzichtserklärung verstanden werden. Ähnlich schließt eine einzelne oder teilweise Ausübung
													oder Verfolgung von Recht, Macht, Privileg, Ansprüchen oder Rechtsmitteln eine weitere
													Ausübung derselben in Bezug auf anderes Recht, Macht, Privileg, Ansprüche oder Rechtsmittel
													nicht aus.
												</li>
												<li>
													Abtretung, Transfer etc. Sie dürfen Ihre Rechte und/oder Pflichten aus den Nutzungsbedingungen
													ohne unsere vorherige schriftliche Einverständniserklärung nicht abtreten, übertragen,
													lizenzieren, sublizenzieren oder hiermit anderweitig handeln. Wir sind dazu berechtigt, unsere
													Rechte aus den Nutzungsbedingungen nach Ermessen abzutreten, zu übertragen oder hiermit
													anderweitig zu handeln.
												</li>
												<li>
													Unschädlichkeit. Falls irgendeine der Nutzungsbedingungen durch ein Gericht, eine
													Verwaltungsbehörde oder andere zuständige Stellen in irgendeinem Ausmaß als ungültig,
													ungesetzlich oder uneinklagbar erklärt wird, wird solch eine Bedingung oder Regelung in diesem
													Ausmaß in den verbleibenden Nutzungsbedingungen, die weiterhin in vollem gesetzlich erlaubtem
													Maße ihre Gültigkeit behalten, als nichtig erklärt.
												</li>
												<li>
													Benachrichtungen. Jegliche Benachrichtigung in Zusammenhang mit den Nutzungsbedingungen muss
													schriftlich und auf folgende Weise erfolgen:
													<ol>
														<li>
															Benachrichtigungen an Sie müssen per Email an die gültige Email-Adresse gesendet werden,
															die Sie uns bei Eröffnung Ihres Kontos bei uns zur Verfügung gestellt haben oder durch
															über andere Wege, wie sie in Abschnitt 28.5(b)(i) bis einschließlich (iii) dargelegt sind.
														</li>
														<li>
															Wenn Sie kein Konto bei uns eröffnet haben, werden Benachrichtigungen wie folgt geliefert:
															(i) per Email an jedwede Emailadresse, die Sie zur Kommunikation mit uns verwendet haben,
															(ii) via Post, Kurier oder andere Formen der physischen Zulieferung an Ihre Wohnadresse
															oder Ihren Arbeitsplatz oder (iii) durch persönliche Zustellung an Sie.
														</li>
														<li>
															Benachrichtigungen an uns können Sie per Email an den Kundendienst oder an die
															registrierte Adresse von Primed Marketing Agency GmbH, Wipplingerstraße 12/2/4, 1010 Wien,
															Österreich, senden.
														</li>
													</ol>
												</li>
												<li>
													Rechte Dritter. Sofern nicht ausdrücklich angegeben, werden in den Nutzungsbedingungen keine
													Rechte oder irgendwelche Vorteile, entweder gemäß englischer Verträge Act 1999 (Rechte
													Dritter) oder anderweitiger, an andere Personen außer Ihnen und uns übertragen.
												</li>
												<li>
													Keine Kooperation, Agentur etc. Nichts in diesen Nutzungsbedingungen darf ausgelegt werden als
													Gründung einer Agentur, Kooperation oder als irgendeine andere Form der
													Gemeinschaftsunternehmung zwischen Ihnen und uns noch als Genehmigung, als Agent für die
													andere Partei zu agieren oder als Berechtigung für uns oder Sie, im Namen oder Auftrag die
													andere Partei in irgendeiner Weise zu binden.
												</li>
												<li>
													Höhere Gewalt. Weder Sie noch wir sind in irgendeiner Weise haftbar für irgendetwas,
													ausgenommen von dieser Regelung, was diese Nutzungsbedingungen infolge von höherer Gewalt, und
													zwar infolge von Ereignissen, die außerhalb Ihrer oder unserer Kontrolle liegen, verletzt,
													hierunter (aber nicht ausschließlich) Naturereignisse, Terrorismus, See- und
													Luftfahrtgefahren, Feuer, Überschwemmungen, Dürre, Explosionen, Embargo, Unruhen,
													Arbeiterstreiks, bürgerliche Unruhen oder Zivilhoheiten, hierunter Handlungen lokaler
													Regierungen und parlamentarischer Autoritäten, Terroranschlägen, weltweite Computerviren, die
													grundlegende Internetverbindungen beeinträchtigen.
												</li>
												<li>
													Anwendbares Recht und Gerichtsstand. Die Gültigkeit, Auslegung und Erfüllung dieser
													Nutzungsbedingungen (und jeglicher Anspruch, Streit und jegliche Angelegenheit, die in
													Verbindung mit ihr oder ihrer Vollstreckbarkeit steht) wird in Übereinstimmung mit den
													Gesetzen von Österreich geregelt und ausgelegt. Wir und Sie unterwerfen uns in Bezug auf jeden
													Anspruch, jeden Streitigkeit und jeder Angelegenheit, die in Verbindung mit dieser
													Vereinbarung steht, unwiderruflich der ausschließlichen juristischen Zuständigkeit der
													Gerichte von Österreich oder ihrer Vollstreckbarkeit.
													<br />
													<br />
													Stand: 01.06.2019
												</li>
											</ol>
										</li>
									</ol>
									<p></p>
								</>
							)}
						</div>
					</div>
					<footer className="my-5 pt-5 text-muted text-center text-small">
						<p className="mb-1">&copy; 2019 Primed Marketing Agency GmbH, Wipplingerstraße 12/2/4, 1010 Wien, Austria</p>
						<ul className="list-inline">
							<li className="list-inline-item">
								<Link to="/privacy">Privacy</Link>
							</li>
							<li className="list-inline-item">
								<Link to="/terms">Terms</Link>
							</li>
							<li className="list-inline-item">
								<Link to="/contact">Support</Link>
							</li>
						</ul>
					</footer>
				</div>
			</React.Fragment>
		)
	}
}

export default Terms
