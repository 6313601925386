// To translate in different languages

import LocalizedStrings from "react-localization";

const raw_strings={
  en: {
    title: "PaymentPunks",
    subtitle: "Please proceed with your payment",
    order: "Your Order",
    net: "Subtotal",
    sum: "Total",
    billingAdress: "Billing Adress",
    firstname: "Firstname",
    lastname: "Lastname",
    street: "Street",
    number: "Number",
    zip: "ZIP",
    city: "City",
    country: "Country",
    payment: "Payment",
    sepa: "SEPA Direct Debit",
    cc: "Credit Card",
    terms: "I accept the Terms and Conditions",
    please_accept_terms: "Please read and accept T&C",

    // these should not be needed
    /*routingNumber: 'routing number',
    accountNumber: 'account number',
    accountType: 'account type',
    nameOnAccount: 'name on account',*/

    creditcard: "Credit Card",
    nameOnCard: "Name on card",
    proceedCC: "Confirm Order",
    proceedSEPA: "To Payment",
    countries: [
      // ISO 2 letter codes!
      { id: "DE", value: "Germany" },
      { id: "AT", value: "Austria" },
      { id: "ES", value: "Spain" },
      { id: "FR", value: "France" },
      { id: "PT", value: "Portugal" },
      { id: "CH", value: "Switzerland" }
    ],
    choose: "Choose...",
    invalid: "Please enter valid input",
    coupon: "Coupon",
    discount: "Discount",
    vatId: "VAT ID",
    company: "Company Name",

    method: "Payment Method",

    password: 'Password',
    passwordRepeat: 'Password (Repeat)',
    passwordTooShort: "needs to be at least 8 characters long.",
    passwordTooLong: "must be less than 30 characters long.", 
    userData: 'Account',

    email: 'E-mail address',
    username: 'username',

    submitNext: 'Next',

    required: "is required",
    invalidFormat: "format is invalid",
    unknown_error: 'Unknown error has occured. Please contact support',
    already_subscribed: 'You have already active subscription for this plan.',
    successHeader: "Almost done, just one last step.",
    successBody: "Your subscription has been created. To pay please follow the link below or the instructions you have received by e-mail.",
    
    payInvoiceLink: 'Link to payment',
    errors:{
      'already-taken':'E-mail address is already in use. Please log-in or contact support.',
      'tax_id_invalid':'ID is invalid'
    },
    loading: "loading...",
    pleaseCheckInput: "Please check your input",
    plan: 'Package',
    subscription: 'subscription',
    payment_plans:{
      "299 monthly": "299 monthly",
      "249 monthly": "249 monthly",
      "199 monthly": "199 monthly",
      "149 monthly": "149 monthly",
      "99 monthly": "99 monthly",
      "weekly": "weekly",
      "monthly": "monthly"},
  },
  de: {
    title: "PaymentPunks",
    subtitle: "Bitte schließen Sie Ihre Bestellung ab.",
    order: "Ihre Bestellung",
    net: "Zwischensumme",
    sum: "Gesamt",
    billingAdress: "Rechnungsadresse",
    firstname: "Vorname",
    lastname: "Nachname",
    street: "Straße",
    number: "Nummer",
    zip: "PLZ",
    city: "Stadt",
    country: "Land",
    payment: "Zahlung",
    sepa: "SEPA Lastschrift",
    creditcard: "Kreditkarte",
    nameOnCard: "--Name auf Karte--",
    proceedCC: "Zahlung abschließen",
    proceedSEPA: "Weiter zur Zahlung",
    email: 'E-mail Addresse',
    username: 'Username',
    countries: [
      { id: "DE", value: "Deutschland" },
      { id: "AT", value: "Österreich" },
      { id: "ES", value: "Spanien" },
      { id: "FR", value: "Frankreich" },
      { id: "PT", value: "Portugal" },
      { id: "CH", value: "Schweiz" }
    ],
    choose: "Bitte auswählen...",
    invalid: "Bitte gültigen Wert eingeben.",
    coupon: "Rabattcode",
    discount: "Rabatt",
    vatId: "USt.-ID",
    company: "Firma",

    submitNext: 'Weiter',

    required: "erforderlich",
    cc: "Kreditkarte",
    method: "Zahlungsart",

    password: 'Passwort',
    passwordRepeat: 'Password (Wiederholung)',
    passwordTooShort: "muss mindestens 8 Zeichen lang sein.",
    passwordTooLong: "darf nicht länger als 30 Zeichen sein.", 
    userData: 'Account',

    invalidFormat: "Ungültiges Format",
    unknown_error: 'Es gab einen unbekannten Fehler. Bitte wende dich an den Support',
    already_subscribed: 'Du hast bereits eine aktive Subscription.',

    terms: "Ich habe die allgemeinen Geschäftsbedingungen gelesen und akzeptiere diese.",
    please_accept_terms: "Bitte lesen und akzeptieren Sie die allgemeinen Geschäftsbedingungen",
    successHeader: "Fast geschafft, es fehlt nur noch ein Schritt! ",
    successBody: "Ihr Abo wurde eingerichtet. Um zu bezahlen folgen Sie bitte dem Link unten oder den Anweisungen die Sie per E-Mail erhalten haben.",

    loading:"Lade...",
    
    payInvoiceLink: 'Link zur Zahlung',
    pleaseCheckInput: "Bitte überprüfen Sie Ihre Eingaben",
    plan: 'Paket',

    errors:{
      'already-taken':'Diese E-Mail ist bereits in Verwendung. Bitte loggen Sie sich ein oder kontaktieren Sie unseren Support.',
      'tax_id_invalid':'ID ist ungültig'
    },
    
    subscription: 'Abbonement',
    payment_plans:{
      "299 monthly": "299 monatliches",
      "249 monthly": "249 monatliches",
      "199 monthly": "199 monatliches",
      "149 monthly": "149 monatliches",
      "99 monthly": "99 monatliches",
      "weekly": "wöchentliches",
      "monthly": "monatliches"},
  }
}

// validate for missing translations
if(process.env.NODE_ENV=='development'){
const supported_langs=Object.keys(raw_strings)
const entry_count=Object.entries(raw_strings)
      .map(([k,v])=> v)
      .reduce((acc,lkeys)=>{
        Object.keys(lkeys).forEach(k => acc[k] = (acc[k]||0) + 1)
    return acc    
  },{})

const missing_translations=Object.entries(entry_count).filter(([k,v])=>{
  return v != supported_langs.length
})

console.error('missing translation strings:',missing_translations)
}

const strings = new LocalizedStrings(raw_strings);

export default strings;
