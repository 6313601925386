import React, { Component } from 'react'
import NavBar from './components/navbar'
import { Route, Switch } from 'react-router-dom'
//import './App.css';
import OrderForm from './components/orderForm'

class App extends Component {
	render() {
		return (
			//Todo: terms, privacy, contact, redirect: components are placeholders
			<React.Fragment>
				<NavBar />
				<main className="container">
					<div className="content">
						<Switch>
							<Route path="/" render={props => <OrderForm />} />
						</Switch>
					</div>
				</main>
			</React.Fragment>
		)
	}
}

export default App
