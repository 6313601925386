import React, { Component } from "react";
import strings from "./languageStrings";
import { Link } from "react-router-dom";
import PaymentForm from './PaymentForm'

const divStyle = {
  marginLeft: "10px"
};

class OrderForm extends Component {
  render(props) {
    return (
      <div className="container">
        <PaymentForm />
      
        <footer className="my-5 pt-5 text-muted text-center text-small">
          <p className="mb-1">&copy; 2019 Primed Marketing Agency GmbH, Wipplingerstraße 12/2/4, 1010 Wien, Austria</p>
          <ul className="list-inline">
            <li className="list-inline-item">
              <Link to="/privacy">Privacy</Link>
            </li>
            <li className="list-inline-item">
              <Link to="/terms">Terms</Link>
            </li>
            <li className="list-inline-item">
              <Link to="/contact">Support</Link>
            </li>
          </ul>
        </footer>
      </div>
    );
  }
}

export default OrderForm;
