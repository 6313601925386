import React, { Component } from 'react'
import Navbar from '../navbar'
import strings from '../languageStrings'
import { Link } from "react-router-dom";

class Privacy extends Component {
	state = {}
	render() {
		let language = strings.getLanguage()
		return (
			<React.Fragment>
				<Navbar />
				<div className="container">
					<div className="row">
						<div className="col">
							{language !== 'de' && (
								<>
									<h1>Privacy Policy</h1>

									<p>
										We are very delighted that you have shown interest in our enterprise. Data protection is of a
										particularly high priority for the management of the Primed Marketing Agency GmbH. The use of the
										Internet pages of the Primed Marketing Agency GmbH is possible without any indication of personal
										data; however, if a data subject wants to use special enterprise services via our website,
										processing of personal data could become necessary. If the processing of personal data is necessary
										and there is no statutory basis for such processing, we generally obtain consent from the data
										subject.
									</p>

									<p>
										The processing of personal data, such as the name, address, e-mail address, or telephone number of a
										data subject shall always be in line with the General Data Protection Regulation (GDPR), and in
										accordance with the country-specific data protection regulations applicable to the Primed Marketing
										Agency GmbH. By means of this data protection declaration, our enterprise would like to inform the
										general public of the nature, scope, and purpose of the personal data we collect, use and process.
										Furthermore, data subjects are informed, by means of this data protection declaration, of the rights
										to which they are entitled.
									</p>

									<p>
										As the controller, the Primed Marketing Agency GmbH has implemented numerous technical and
										organizational measures to ensure the most complete protection of personal data processed through
										this website. However, Internet-based data transmissions may in principle have security gaps, so
										absolute protection may not be guaranteed. For this reason, every data subject is free to transfer
										personal data to us via alternative means, e.g. by telephone.{' '}
									</p>

									<b>1. Definitions</b>
									<p>
										The data protection declaration of the Primed Marketing Agency GmbH is based on the terms used by
										the European legislator for the adoption of the General Data Protection Regulation (GDPR). Our data
										protection declaration should be legible and understandable for the general public, as well as our
										customers and business partners. To ensure this, we would like to first explain the terminology
										used.
									</p>

									<p>In this data protection declaration, we use, inter alia, the following terms:</p>

									<ul style={{ listStyle: 'none', paddingLeft: 0 }}>
										<li>
											<b>a) Personal data</b>
											<p>
												Personal data means any information relating to an identified or identifiable natural person
												(“data subject”). An identifiable natural person is one who can be identified, directly or
												indirectly, in particular by reference to an identifier such as a name, an identification
												number, location data, an online identifier or to one or more factors specific to the physical,
												physiological, genetic, mental, economic, cultural or social identity of that natural person.
											</p>
										</li>
										<li>
											<b>b) Data subject</b>
											<p>
												Data subject is any identified or identifiable natural person, whose personal data is processed
												by the controller responsible for the processing.
											</p>
										</li>
										<li>
											<b>c) Processing</b>
											<p>
												Processing is any operation or set of operations which is performed on personal data or on sets
												of personal data, whether or not by automated means, such as collection, recording,
												organisation, structuring, storage, adaptation or alteration, retrieval, consultation, use,
												disclosure by transmission, dissemination or otherwise making available, alignment or
												combination, restriction, erasure or destruction.{' '}
											</p>
										</li>
										<li>
											<b>d) Restriction of processing</b>
											<p>
												Restriction of processing is the marking of stored personal data with the aim of limiting their
												processing in the future.{' '}
											</p>
										</li>
										<li>
											<b>e) Profiling</b>
											<p>
												Profiling means any form of automated processing of personal data consisting of the use of
												personal data to evaluate certain personal aspects relating to a natural person, in particular
												to analyse or predict aspects concerning that natural person's performance at work, economic
												situation, health, personal preferences, interests, reliability, behaviour, location or
												movements.{' '}
											</p>
										</li>
										<li>
											<b>f) Pseudonymisation</b>
											<p>
												Pseudonymisation is the processing of personal data in such a manner that the personal data can
												no longer be attributed to a specific data subject without the use of additional information,
												provided that such additional information is kept separately and is subject to technical and
												organisational measures to ensure that the personal data are not attributed to an identified or
												identifiable natural person.{' '}
											</p>
										</li>
										<li>
											<b>g) Controller or controller responsible for the processing</b>
											<p>
												Controller or controller responsible for the processing is the natural or legal person, public
												authority, agency or other body which, alone or jointly with others, determines the purposes and
												means of the processing of personal data; where the purposes and means of such processing are
												determined by Union or Member State law, the controller or the specific criteria for its
												nomination may be provided for by Union or Member State law.{' '}
											</p>
										</li>
										<li>
											<b>h) Processor</b>
											<p>
												Processor is a natural or legal person, public authority, agency or other body which processes
												personal data on behalf of the controller.{' '}
											</p>
										</li>
										<li>
											<b>i) Recipient</b>
											<p>
												Recipient is a natural or legal person, public authority, agency or another body, to which the
												personal data are disclosed, whether a third party or not. However, public authorities which may
												receive personal data in the framework of a particular inquiry in accordance with Union or
												Member State law shall not be regarded as recipients; the processing of those data by those
												public authorities shall be in compliance with the applicable data protection rules according to
												the purposes of the processing.{' '}
											</p>
										</li>
										<li>
											<b>j) Third party</b>
											<p>
												Third party is a natural or legal person, public authority, agency or body other than the data
												subject, controller, processor and persons who, under the direct authority of the controller or
												processor, are authorised to process personal data.
											</p>
										</li>
										<li>
											<b>k) Consent</b>
											<p>
												Consent of the data subject is any freely given, specific, informed and unambiguous indication
												of the data subject's wishes by which he or she, by a statement or by a clear affirmative
												action, signifies agreement to the processing of personal data relating to him or her.{' '}
											</p>
										</li>
									</ul>

									<b>2. Name and Address of the controller</b>
									<p>
										Controller for the purposes of the General Data Protection Regulation (GDPR), other data protection
										laws applicable in Member states of the European Union and other provisions related to data
										protection is:
									</p>

									<b>Primed Marketing Agency GmbH</b>
									<br />
									<b>Wipplingerstraße 12/2/4</b>
									<br />
									<b>1010 Vienna, AT</b>
									<br />
									<b>Email: info@infludata.com</b>
									<br />
									<b>Website: https://www.infludata.com</b>
									<br />
									<br />

									<b>3. Cookies</b>
									<p>
										The Internet pages of the Primed Marketing Agency GmbH use cookies. Cookies are text files that are
										stored in a computer system via an Internet browser.
									</p>

									<p>
										Many Internet sites and servers use cookies. Many cookies contain a so-called cookie ID. A cookie ID
										is a unique identifier of the cookie. It consists of a character string through which Internet pages
										and servers can be assigned to the specific Internet browser in which the cookie was stored. This
										allows visited Internet sites and servers to differentiate the individual browser of the dats
										subject from other Internet browsers that contain other cookies. A specific Internet browser can be
										recognized and identified using the unique cookie ID.
									</p>

									<p>
										Through the use of cookies, the Primed Marketing Agency GmbH can provide the users of this website
										with more user-friendly services that would not be possible without the cookie setting.
									</p>

									<p>
										By means of a cookie, the information and offers on our website can be optimized with the user in
										mind. Cookies allow us, as previously mentioned, to recognize our website users. The purpose of this
										recognition is to make it easier for users to utilize our website. The website user that uses
										cookies, e.g. does not have to enter access data each time the website is accessed, because this is
										taken over by the website, and the cookie is thus stored on the user's computer system. Another
										example is the cookie of a shopping cart in an online shop. The online store remembers the articles
										that a customer has placed in the virtual shopping cart via a cookie.
									</p>

									<p>
										The data subject may, at any time, prevent the setting of cookies through our website by means of a
										corresponding setting of the Internet browser used, and may thus permanently deny the setting of
										cookies. Furthermore, already set cookies may be deleted at any time via an Internet browser or
										other software programs. This is possible in all popular Internet browsers. If the data subject
										deactivates the setting of cookies in the Internet browser used, not all functions of our website
										may be entirely usable.
									</p>

									<b>4. Collection of general data and information</b>
									<p>
										The website of the Primed Marketing Agency GmbH collects a series of general data and information
										when a data subject or automated system calls up the website. This general data and information are
										stored in the server log files. Collected may be (1) the browser types and versions used, (2) the
										operating system used by the accessing system, (3) the website from which an accessing system
										reaches our website (so-called referrers), (4) the sub-websites, (5) the date and time of access to
										the Internet site, (6) an Internet protocol address (IP address), (7) the Internet service provider
										of the accessing system, and (8) any other similar data and information that may be used in the
										event of attacks on our information technology systems.
									</p>

									<p>
										When using these general data and information, the Primed Marketing Agency GmbH does not draw any
										conclusions about the data subject. Rather, this information is needed to (1) deliver the content of
										our website correctly, (2) optimize the content of our website as well as its advertisement, (3)
										ensure the long-term viability of our information technology systems and website technology, and (4)
										provide law enforcement authorities with the information necessary for criminal prosecution in case
										of a cyber-attack. Therefore, the Primed Marketing Agency GmbH analyzes anonymously collected data
										and information statistically, with the aim of increasing the data protection and data security of
										our enterprise, and to ensure an optimal level of protection for the personal data we process. The
										anonymous data of the server log files are stored separately from all personal data provided by a
										data subject.
									</p>

									<b>5. Subscription to our newsletters</b>
									<p>
										On the website of the Primed Marketing Agency GmbH, users are given the opportunity to subscribe to
										our enterprise's newsletter. The input mask used for this purpose determines what personal data are
										transmitted, as well as when the newsletter is ordered from the controller.
									</p>

									<p>
										The Primed Marketing Agency GmbH informs its customers and business partners regularly by means of a
										newsletter about enterprise offers. The enterprise's newsletter may only be received by the data
										subject if (1) the data subject has a valid e-mail address and (2) the data subject registers for
										the newsletter shipping. A confirmation e-mail will be sent to the e-mail address registered by a
										data subject for the first time for newsletter shipping, for legal reasons, in the double opt-in
										procedure. This confirmation e-mail is used to prove whether the owner of the e-mail address as the
										data subject is authorized to receive the newsletter.
									</p>

									<p>
										During the registration for the newsletter, we also store the IP address of the computer system
										assigned by the Internet service provider (ISP) and used by the data subject at the time of the
										registration, as well as the date and time of the registration. The collection of this data is
										necessary in order to understand the (possible) misuse of the e-mail address of a data subject at a
										later date, and it therefore serves the aim of the legal protection of the controller.
									</p>

									<p>
										The personal data collected as part of a registration for the newsletter will only be used to send
										our newsletter. In addition, subscribers to the newsletter may be informed by e-mail, as long as
										this is necessary for the operation of the newsletter service or a registration in question, as this
										could be the case in the event of modifications to the newsletter offer, or in the event of a change
										in technical circumstances. There will be no transfer of personal data collected by the newsletter
										service to third parties. The subscription to our newsletter may be terminated by the data subject
										at any time. The consent to the storage of personal data, which the data subject has given for
										shipping the newsletter, may be revoked at any time. For the purpose of revocation of consent, a
										corresponding link is found in each newsletter. It is also possible to unsubscribe from the
										newsletter at any time directly on the website of the controller, or to communicate this to the
										controller in a different way.
									</p>

									<b>6. Newsletter-Tracking</b>
									<p>
										The newsletter of the Primed Marketing Agency GmbH contains so-called tracking pixels. A tracking
										pixel is a miniature graphic embedded in such e-mails, which are sent in HTML format to enable log
										file recording and analysis. This allows a statistical analysis of the success or failure of online
										marketing campaigns. Based on the embedded tracking pixel, the Primed Marketing Agency GmbH may see
										if and when an e-mail was opened by a data subject, and which links in the e-mail were called up by
										data subjects.
									</p>

									<p>
										Such personal data collected in the tracking pixels contained in the newsletters are stored and
										analyzed by the controller in order to optimize the shipping of the newsletter, as well as to adapt
										the content of future newsletters even better to the interests of the data subject. These personal
										data will not be passed on to third parties. Data subjects are at any time entitled to revoke the
										respective separate declaration of consent issued by means of the double-opt-in procedure. After a
										revocation, these personal data will be deleted by the controller. The Primed Marketing Agency GmbH
										automatically regards a withdrawal from the receipt of the newsletter as a revocation.
									</p>

									<b>7. Contact possibility via the website </b>
									<p>
										The website of the Primed Marketing Agency GmbH contains information that enables a quick electronic
										contact to our enterprise, as well as direct communication with us, which also includes a general
										address of the so-called electronic mail (e-mail address). If a data subject contacts the controller
										by e-mail or via a contact form, the personal data transmitted by the data subject are automatically
										stored. Such personal data transmitted on a voluntary basis by a data subject to the data controller
										are stored for the purpose of processing or contacting the data subject. There is no transfer of
										this personal data to third parties.
									</p>

									<b>8. Routine erasure and blocking of personal data</b>
									<p>
										The data controller shall process and store the personal data of the data subject only for the
										period necessary to achieve the purpose of storage, or as far as this is granted by the European
										legislator or other legislators in laws or regulations to which the controller is subject to.
									</p>

									<p>
										If the storage purpose is not applicable, or if a storage period prescribed by the European
										legislator or another competent legislator expires, the personal data are routinely blocked or
										erased in accordance with legal requirements.
									</p>

									<b>9. Rights of the data subject</b>
									<ul style={{ listStyle: 'none', paddingLeft: 0 }}>
										<li>
											<b>a) Right of confirmation</b>
											<p>
												Each data subject shall have the right granted by the European legislator to obtain from the
												controller the confirmation as to whether or not personal data concerning him or her are being
												processed. If a data subject wishes to avail himself of this right of confirmation, he or she
												may, at any time, contact any employee of the controller.
											</p>
										</li>
										<li>
											<b>b) Right of access</b>
											<p>
												Each data subject shall have the right granted by the European legislator to obtain from the
												controller free information about his or her personal data stored at any time and a copy of this
												information. Furthermore, the European directives and regulations grant the data subject access
												to the following information:
											</p>

											<ul style={{ listStyle: 'none', paddingLeft: 0 }}>
												<li>the purposes of the processing;</li>
												<li>the categories of personal data concerned;</li>
												<li>
													the recipients or categories of recipients to whom the personal data have been or will be
													disclosed, in particular recipients in third countries or international organisations;
												</li>
												<li>
													where possible, the envisaged period for which the personal data will be stored, or, if not
													possible, the criteria used to determine that period;
												</li>
												<li>
													the existence of the right to request from the controller rectification or erasure of personal
													data, or restriction of processing of personal data concerning the data subject, or to object
													to such processing;
												</li>
												<li>the existence of the right to lodge a complaint with a supervisory authority;</li>
												<li>
													where the personal data are not collected from the data subject, any available information as
													to their source;
												</li>
												<li>
													the existence of automated decision-making, including profiling, referred to in Article 22(1)
													and (4) of the GDPR and, at least in those cases, meaningful information about the logic
													involved, as well as the significance and envisaged consequences of such processing for the
													data subject.
												</li>
											</ul>
											<p>
												Furthermore, the data subject shall have a right to obtain information as to whether personal
												data are transferred to a third country or to an international organisation. Where this is the
												case, the data subject shall have the right to be informed of the appropriate safeguards
												relating to the transfer.
											</p>

											<p>
												If a data subject wishes to avail himself of this right of access, he or she may, at any time,
												contact any employee of the controller.
											</p>
										</li>
										<li>
											<b>c) Right to rectification </b>
											<p>
												Each data subject shall have the right granted by the European legislator to obtain from the
												controller without undue delay the rectification of inaccurate personal data concerning him or
												her. Taking into account the purposes of the processing, the data subject shall have the right
												to have incomplete personal data completed, including by means of providing a supplementary
												statement.
											</p>

											<p>
												If a data subject wishes to exercise this right to rectification, he or she may, at any time,
												contact any employee of the controller.
											</p>
										</li>
										<li>
											<b>d) Right to erasure (Right to be forgotten) </b>
											<p>
												Each data subject shall have the right granted by the European legislator to obtain from the
												controller the erasure of personal data concerning him or her without undue delay, and the
												controller shall have the obligation to erase personal data without undue delay where one of the
												following grounds applies, as long as the processing is not necessary:{' '}
											</p>

											<ul style={{ listStyle: 'none', paddingLeft: 0 }}>
												<li>
													The personal data are no longer necessary in relation to the purposes for which they were
													collected or otherwise processed.
												</li>
												<li>
													The data subject withdraws consent to which the processing is based according to point (a) of
													Article 6(1) of the GDPR, or point (a) of Article 9(2) of the GDPR, and where there is no
													other legal ground for the processing.
												</li>
												<li>
													The data subject objects to the processing pursuant to Article 21(1) of the GDPR and there are
													no overriding legitimate grounds for the processing, or the data subject objects to the
													processing pursuant to Article 21(2) of the GDPR.{' '}
												</li>
												<li>The personal data have been unlawfully processed.</li>
												<li>
													The personal data must be erased for compliance with a legal obligation in Union or Member
													State law to which the controller is subject.
												</li>
												<li>
													The personal data have been collected in relation to the offer of information society services
													referred to in Article 8(1) of the GDPR.
												</li>
											</ul>
											<p>
												If one of the aforementioned reasons applies, and a data subject wishes to request the erasure
												of personal data stored by the Primed Marketing Agency GmbH, he or she may, at any time, contact
												any employee of the controller. An employee of Primed Marketing Agency GmbH shall promptly
												ensure that the erasure request is complied with immediately.
											</p>

											<p>
												Where the controller has made personal data public and is obliged pursuant to Article 17(1) to
												erase the personal data, the controller, taking account of available technology and the cost of
												implementation, shall take reasonable steps, including technical measures, to inform other
												controllers processing the personal data that the data subject has requested erasure by such
												controllers of any links to, or copy or replication of, those personal data, as far as
												processing is not required. An employees of the Primed Marketing Agency GmbH will arrange the
												necessary measures in individual cases.
											</p>
										</li>
										<li>
											<b>e) Right of restriction of processing</b>
											<p>
												Each data subject shall have the right granted by the European legislator to obtain from the
												controller restriction of processing where one of the following applies:
											</p>

											<ul style={{ listStyle: 'none', paddingLeft: 0 }}>
												<li>
													The accuracy of the personal data is contested by the data subject, for a period enabling the
													controller to verify the accuracy of the personal data.{' '}
												</li>
												<li>
													The processing is unlawful and the data subject opposes the erasure of the personal data and
													requests instead the restriction of their use instead.
												</li>
												<li>
													The controller no longer needs the personal data for the purposes of the processing, but they
													are required by the data subject for the establishment, exercise or defence of legal claims.
												</li>
												<li>
													The data subject has objected to processing pursuant to Article 21(1) of the GDPR pending the
													verification whether the legitimate grounds of the controller override those of the data
													subject.
												</li>
											</ul>
											<p>
												If one of the aforementioned conditions is met, and a data subject wishes to request the
												restriction of the processing of personal data stored by the Primed Marketing Agency GmbH, he or
												she may at any time contact any employee of the controller. The employee of the Primed Marketing
												Agency GmbH will arrange the restriction of the processing.{' '}
											</p>
										</li>
										<li>
											<b>f) Right to data portability</b>
											<p>
												Each data subject shall have the right granted by the European legislator, to receive the
												personal data concerning him or her, which was provided to a controller, in a structured,
												commonly used and machine-readable format. He or she shall have the right to transmit those data
												to another controller without hindrance from the controller to which the personal data have been
												provided, as long as the processing is based on consent pursuant to point (a) of Article 6(1) of
												the GDPR or point (a) of Article 9(2) of the GDPR, or on a contract pursuant to point (b) of
												Article 6(1) of the GDPR, and the processing is carried out by automated means, as long as the
												processing is not necessary for the performance of a task carried out in the public interest or
												in the exercise of official authority vested in the controller.
											</p>

											<p>
												Furthermore, in exercising his or her right to data portability pursuant to Article 20(1) of the
												GDPR, the data subject shall have the right to have personal data transmitted directly from one
												controller to another, where technically feasible and when doing so does not adversely affect
												the rights and freedoms of others.
											</p>

											<p>
												In order to assert the right to data portability, the data subject may at any time contact any
												employee of the Primed Marketing Agency GmbH.
											</p>
										</li>
										<li>
											<b>g) Right to object</b>
											<p>
												Each data subject shall have the right granted by the European legislator to object, on grounds
												relating to his or her particular situation, at any time, to processing of personal data
												concerning him or her, which is based on point (e) or (f) of Article 6(1) of the GDPR. This also
												applies to profiling based on these provisions.
											</p>

											<p>
												The Primed Marketing Agency GmbH shall no longer process the personal data in the event of the
												objection, unless we can demonstrate compelling legitimate grounds for the processing which
												override the interests, rights and freedoms of the data subject, or for the establishment,
												exercise or defence of legal claims.
											</p>

											<p>
												If the Primed Marketing Agency GmbH processes personal data for direct marketing purposes, the
												data subject shall have the right to object at any time to processing of personal data
												concerning him or her for such marketing. This applies to profiling to the extent that it is
												related to such direct marketing. If the data subject objects to the Primed Marketing Agency
												GmbH to the processing for direct marketing purposes, the Primed Marketing Agency GmbH will no
												longer process the personal data for these purposes.
											</p>

											<p>
												In addition, the data subject has the right, on grounds relating to his or her particular
												situation, to object to processing of personal data concerning him or her by the Primed
												Marketing Agency GmbH for scientific or historical research purposes, or for statistical
												purposes pursuant to Article 89(1) of the GDPR, unless the processing is necessary for the
												performance of a task carried out for reasons of public interest.
											</p>

											<p>
												In order to exercise the right to object, the data subject may contact any employee of the
												Primed Marketing Agency GmbH. In addition, the data subject is free in the context of the use of
												information society services, and notwithstanding Directive 2002/58/EC, to use his or her right
												to object by automated means using technical specifications.
											</p>
										</li>
										<li>
											<b>h) Automated individual decision-making, including profiling</b>
											<p>
												Each data subject shall have the right granted by the European legislator not to be subject to a
												decision based solely on automated processing, including profiling, which produces legal effects
												concerning him or her, or similarly significantly affects him or her, as long as the decision
												(1) is not is necessary for entering into, or the performance of, a contract between the data
												subject and a data controller, or (2) is not authorised by Union or Member State law to which
												the controller is subject and which also lays down suitable measures to safeguard the data
												subject's rights and freedoms and legitimate interests, or (3) is not based on the data
												subject's explicit consent.
											</p>

											<p>
												If the decision (1) is necessary for entering into, or the performance of, a contract between
												the data subject and a data controller, or (2) it is based on the data subject's explicit
												consent, the Primed Marketing Agency GmbH shall implement suitable measures to safeguard the
												data subject's rights and freedoms and legitimate interests, at least the right to obtain human
												intervention on the part of the controller, to express his or her point of view and contest the
												decision.
											</p>

											<p>
												If the data subject wishes to exercise the rights concerning automated individual
												decision-making, he or she may, at any time, contact any employee of the Primed Marketing Agency
												GmbH.
											</p>
										</li>
										<li>
											<b>i) Right to withdraw data protection consent </b>
											<p>
												Each data subject shall have the right granted by the European legislator to withdraw his or her
												consent to processing of his or her personal data at any time.{' '}
											</p>

											<p>
												If the data subject wishes to exercise the right to withdraw the consent, he or she may, at any
												time, contact any employee of the Primed Marketing Agency GmbH.
											</p>
										</li>
									</ul>
									<b>
										10. Data protection provisions about the application and use of Google Analytics (with anonymization
										function)
									</b>
									<p>
										On this website, the controller has integrated the component of Google Analytics (with the
										anonymizer function). Google Analytics is a web analytics service. Web analytics is the collection,
										gathering, and analysis of data about the behavior of visitors to websites. A web analysis service
										collects, inter alia, data about the website from which a person has come (the so-called referrer),
										which sub-pages were visited, or how often and for what duration a sub-page was viewed. Web
										analytics are mainly used for the optimization of a website and in order to carry out a cost-benefit
										analysis of Internet advertising.
									</p>

									<p>
										The operator of the Google Analytics component is Google Ireland Limited, Gordon House, Barrow
										Street, Dublin, D04 E5W5, Ireland.
									</p>

									<p>
										For the web analytics through Google Analytics the controller uses the application "_gat.
										_anonymizeIp". By means of this application the IP address of the Internet connection of the data
										subject is abridged by Google and anonymised when accessing our websites from a Member State of the
										European Union or another Contracting State to the Agreement on the European Economic Area.
									</p>

									<p>
										The purpose of the Google Analytics component is to analyze the traffic on our website. Google uses
										the collected data and information, inter alia, to evaluate the use of our website and to provide
										online reports, which show the activities on our websites, and to provide other services concerning
										the use of our Internet site for us.
									</p>

									<p>
										Google Analytics places a cookie on the information technology system of the data subject. The
										definition of cookies is explained above. With the setting of the cookie, Google is enabled to
										analyze the use of our website. With each call-up to one of the individual pages of this Internet
										site, which is operated by the controller and into which a Google Analytics component was
										integrated, the Internet browser on the information technology system of the data subject will
										automatically submit data through the Google Analytics component for the purpose of online
										advertising and the settlement of commissions to Google. During the course of this technical
										procedure, the enterprise Google gains knowledge of personal information, such as the IP address of
										the data subject, which serves Google, inter alia, to understand the origin of visitors and clicks,
										and subsequently create commission settlements.
									</p>

									<p>
										The cookie is used to store personal information, such as the access time, the location from which
										the access was made, and the frequency of visits of our website by the data subject. With each visit
										to our Internet site, such personal data, including the IP address of the Internet access used by
										the data subject, will be transmitted to Google in the United States of America. These personal data
										are stored by Google in the United States of America. Google may pass these personal data collected
										through the technical procedure to third parties.
									</p>

									<p>
										The data subject may, as stated above, prevent the setting of cookies through our website at any
										time by means of a corresponding adjustment of the web browser used and thus permanently deny the
										setting of cookies. Such an adjustment to the Internet browser used would also prevent Google
										Analytics from setting a cookie on the information technology system of the data subject. In
										addition, cookies already in use by Google Analytics may be deleted at any time via a web browser or
										other software programs.
									</p>

									<p>
										In addition, the data subject has the possibility of objecting to a collection of data that are
										generated by Google Analytics, which is related to the use of this website, as well as the
										processing of this data by Google and the chance to preclude any such. For this purpose, the data
										subject must download a browser add-on under the link https://tools.google.com/dlpage/gaoptout and
										install it. This browser add-on tells Google Analytics through a JavaScript, that any data and
										information about the visits of Internet pages may not be transmitted to Google Analytics. The
										installation of the browser add-ons is considered an objection by Google. If the information
										technology system of the data subject is later deleted, formatted, or newly installed, then the data
										subject must reinstall the browser add-ons to disable Google Analytics. If the browser add-on was
										uninstalled by the data subject or any other person who is attributable to their sphere of
										competence, or is disabled, it is possible to execute the reinstallation or reactivation of the
										browser add-ons.
									</p>

									<p>
										Further information and the applicable data protection provisions of Google may be retrieved under
										https://www.google.com/intl/en/policies/privacy/ and under
										http://www.google.com/analytics/terms/us.html. Google Analytics is further explained under the
										following Link https://www.google.com/analytics/.
									</p>

									<b>11. Data protection provisions about the application and use of Instagram</b>
									<p>
										On this website, the controller has integrated components of the service Instagram. Instagram is a
										service that may be qualified as an audiovisual platform, which allows users to share photos and
										videos, as well as disseminate such data in other social networks.
									</p>

									<p>
										The operating company of the services offered by Instagram is Facebook Ireland Ltd., 4 Grand Canal
										Square, Grand Canal Harbour, Dublin 2 Ireland.
									</p>

									<p>
										With each call-up to one of the individual pages of this Internet site, which is operated by the
										controller and on which an Instagram component (Insta button) was integrated, the Internet browser
										on the information technology system of the data subject is automatically prompted to the download
										of a display of the corresponding Instagram component of Instagram. During the course of this
										technical procedure, Instagram becomes aware of what specific sub-page of our website was visited by
										the data subject.
									</p>

									<p>
										If the data subject is logged in at the same time on Instagram, Instagram detects with every call-up
										to our website by the data subject—and for the entire duration of their stay on our Internet
										site—which specific sub-page of our Internet page was visited by the data subject. This information
										is collected through the Instagram component and is associated with the respective Instagram account
										of the data subject. If the data subject clicks on one of the Instagram buttons integrated on our
										website, then Instagram matches this information with the personal Instagram user account of the
										data subject and stores the personal data.
									</p>

									<p>
										Instagram receives information via the Instagram component that the data subject has visited our
										website provided that the data subject is logged in at Instagram at the time of the call to our
										website. This occurs regardless of whether the person clicks on the Instagram button or not. If such
										a transmission of information to Instagram is not desirable for the data subject, then he or she can
										prevent this by logging off from their Instagram account before a call-up to our website is made.
									</p>

									<p>
										Further information and the applicable data protection provisions of Instagram may be retrieved
										under https://help.instagram.com/155833707900388 and https://www.instagram.com/about/legal/privacy/.
									</p>

									<b>12. Legal basis for the processing </b>
									<p>
										Art. 6(1) lit. a GDPR serves as the legal basis for processing operations for which we obtain
										consent for a specific processing purpose. If the processing of personal data is necessary for the
										performance of a contract to which the data subject is party, as is the case, for example, when
										processing operations are necessary for the supply of goods or to provide any other service, the
										processing is based on Article 6(1) lit. b GDPR. The same applies to such processing operations
										which are necessary for carrying out pre-contractual measures, for example in the case of inquiries
										concerning our products or services. Is our company subject to a legal obligation by which
										processing of personal data is required, such as for the fulfillment of tax obligations, the
										processing is based on Art. 6(1) lit. c GDPR. In rare cases, the processing of personal data may be
										necessary to protect the vital interests of the data subject or of another natural person. This
										would be the case, for example, if a visitor were injured in our company and his name, age, health
										insurance data or other vital information would have to be passed on to a doctor, hospital or other
										third party. Then the processing would be based on Art. 6(1) lit. d GDPR. Finally, processing
										operations could be based on Article 6(1) lit. f GDPR. This legal basis is used for processing
										operations which are not covered by any of the abovementioned legal grounds, if processing is
										necessary for the purposes of the legitimate interests pursued by our company or by a third party,
										except where such interests are overridden by the interests or fundamental rights and freedoms of
										the data subject which require protection of personal data. Such processing operations are
										particularly permissible because they have been specifically mentioned by the European legislator.
										He considered that a legitimate interest could be assumed if the data subject is a client of the
										controller (Recital 47 Sentence 2 GDPR).
									</p>

									<b>13. The legitimate interests pursued by the controller or by a third party</b>
									<p>
										Where the processing of personal data is based on Article 6(1) lit. f GDPR our legitimate interest
										is to carry out our business in favor of the well-being of all our employees and the shareholders.
									</p>

									<b>14. Period for which the personal data will be stored</b>
									<p>
										The criteria used to determine the period of storage of personal data is the respective statutory
										retention period. After expiration of that period, the corresponding data is routinely deleted, as
										long as it is no longer necessary for the fulfillment of the contract or the initiation of a
										contract.
									</p>

									<b>
										15. Provision of personal data as statutory or contractual requirement; Requirement necessary to
										enter into a contract; Obligation of the data subject to provide the personal data; possible
										consequences of failure to provide such data{' '}
									</b>
									<p>
										We clarify that the provision of personal data is partly required by law (e.g. tax regulations) or
										can also result from contractual provisions (e.g. information on the contractual partner). Sometimes
										it may be necessary to conclude a contract that the data subject provides us with personal data,
										which must subsequently be processed by us. The data subject is, for example, obliged to provide us
										with personal data when our company signs a contract with him or her. The non-provision of the
										personal data would have the consequence that the contract with the data subject could not be
										concluded. Before personal data is provided by the data subject, the data subject must contact any
										employee. The employee clarifies to the data subject whether the provision of the personal data is
										required by law or contract or is necessary for the conclusion of the contract, whether there is an
										obligation to provide the personal data and the consequences of non-provision of the personal data.
									</p>

									<b>16. Existence of automated decision-making</b>
									<p>As a responsible company, we do not use automatic decision-making or profiling.</p>
								</>
							)}
							{language == 'de' && (
								<>
									<h1>Datenschutzerklärung</h1>

									<p>
										Wir freuen uns sehr über Ihr Interesse an unserem Unternehmen. Datenschutz hat einen besonders hohen
										Stellenwert für die Geschäftsleitung der Primed Marketing Agency GmbH. Eine Nutzung der
										Internetseiten der Primed Marketing Agency GmbH ist grundsätzlich ohne jede Angabe personenbezogener
										Daten möglich. Sofern eine betroffene Person besondere Services unseres Unternehmens über unsere
										Internetseite in Anspruch nehmen möchte, könnte jedoch eine Verarbeitung personenbezogener Daten
										erforderlich werden. Ist die Verarbeitung personenbezogener Daten erforderlich und besteht für eine
										solche Verarbeitung keine gesetzliche Grundlage, holen wir generell eine Einwilligung der
										betroffenen Person ein.
									</p>

									<p>
										Die Verarbeitung personenbezogener Daten, beispielsweise des Namens, der Anschrift, E-Mail-Adresse
										oder Telefonnummer einer betroffenen Person, erfolgt stets im Einklang mit der
										Datenschutz-Grundverordnung und in Übereinstimmung mit den für die Primed Marketing Agency GmbH
										geltenden landesspezifischen Datenschutzbestimmungen. Mittels dieser Datenschutzerklärung möchte
										unser Unternehmen die Öffentlichkeit über Art, Umfang und Zweck der von uns erhobenen, genutzten und
										verarbeiteten personenbezogenen Daten informieren. Ferner werden betroffene Personen mittels dieser
										Datenschutzerklärung über die ihnen zustehenden Rechte aufgeklärt.
									</p>

									<p>
										Die Primed Marketing Agency GmbH hat als für die Verarbeitung Verantwortlicher zahlreiche technische
										und organisatorische Maßnahmen umgesetzt, um einen möglichst lückenlosen Schutz der über diese
										Internetseite verarbeiteten personenbezogenen Daten sicherzustellen. Dennoch können Internetbasierte
										Datenübertragungen grundsätzlich Sicherheitslücken aufweisen, sodass ein absoluter Schutz nicht
										gewährleistet werden kann. Aus diesem Grund steht es jeder betroffenen Person frei, personenbezogene
										Daten auch auf alternativen Wegen, beispielsweise telefonisch, an uns zu übermitteln.
									</p>

									<b>1. Begriffsbestimmungen</b>
									<p>
										Die Datenschutzerklärung der Primed Marketing Agency GmbH beruht auf den Begrifflichkeiten, die
										durch den Europäischen Richtlinien- und Verordnungsgeber beim Erlass der Datenschutz-Grundverordnung
										(DS-GVO) verwendet wurden. Unsere Datenschutzerklärung soll sowohl für die Öffentlichkeit als auch
										für unsere Kunden und Geschäftspartner einfach lesbar und verständlich sein. Um dies zu
										gewährleisten, möchten wir vorab die verwendeten Begrifflichkeiten erläutern.
									</p>

									<p>Wir verwenden in dieser Datenschutzerklärung unter anderem die folgenden Begriffe:</p>

									<ul style={{ listStyle: 'none', paddingLeft: 0 }}>
										<li>
											<b>a) personenbezogene Daten</b>
											<p>
												Personenbezogene Daten sind alle Informationen, die sich auf eine identifizierte oder
												identifizierbare natürliche Person (im Folgenden „betroffene Person“) beziehen. Als
												identifizierbar wird eine natürliche Person angesehen, die direkt oder indirekt, insbesondere
												mittels Zuordnung zu einer Kennung wie einem Namen, zu einer Kennnummer, zu Standortdaten, zu
												einer Online-Kennung oder zu einem oder mehreren besonderen Merkmalen, die Ausdruck der
												physischen, physiologischen, genetischen, psychischen, wirtschaftlichen, kulturellen oder
												sozialen Identität dieser natürlichen Person sind, identifiziert werden kann.
											</p>
										</li>
										<li>
											<b>b) betroffene Person</b>
											<p>
												Betroffene Person ist jede identifizierte oder identifizierbare natürliche Person, deren
												personenbezogene Daten von dem für die Verarbeitung Verantwortlichen verarbeitet werden.
											</p>
										</li>
										<li>
											<b>c) Verarbeitung</b>
											<p>
												Verarbeitung ist jeder mit oder ohne Hilfe automatisierter Verfahren ausgeführte Vorgang oder
												jede solche Vorgangsreihe im Zusammenhang mit personenbezogenen Daten wie das Erheben, das
												Erfassen, die Organisation, das Ordnen, die Speicherung, die Anpassung oder Veränderung, das
												Auslesen, das Abfragen, die Verwendung, die Offenlegung durch Übermittlung, Verbreitung oder
												eine andere Form der Bereitstellung, den Abgleich oder die Verknüpfung, die Einschränkung, das
												Löschen oder die Vernichtung.
											</p>
										</li>
										<li>
											<b>d) Einschränkung der Verarbeitung</b>
											<p>
												Einschränkung der Verarbeitung ist die Markierung gespeicherter personenbezogener Daten mit dem
												Ziel, ihre künftige Verarbeitung einzuschränken.
											</p>
										</li>
										<li>
											<b>e) Profiling</b>
											<p>
												Profiling ist jede Art der automatisierten Verarbeitung personenbezogener Daten, die darin
												besteht, dass diese personenbezogenen Daten verwendet werden, um bestimmte persönliche Aspekte,
												die sich auf eine natürliche Person beziehen, zu bewerten, insbesondere, um Aspekte bezüglich
												Arbeitsleistung, wirtschaftlicher Lage, Gesundheit, persönlicher Vorlieben, Interessen,
												Zuverlässigkeit, Verhalten, Aufenthaltsort oder Ortswechsel dieser natürlichen Person zu
												analysieren oder vorherzusagen.
											</p>
										</li>
										<li>
											<b>f) Pseudonymisierung</b>
											<p>
												Pseudonymisierung ist die Verarbeitung personenbezogener Daten in einer Weise, auf welche die
												personenbezogenen Daten ohne Hinzuziehung zusätzlicher Informationen nicht mehr einer
												spezifischen betroffenen Person zugeordnet werden können, sofern diese zusätzlichen
												Informationen gesondert aufbewahrt werden und technischen und organisatorischen Maßnahmen
												unterliegen, die gewährleisten, dass die personenbezogenen Daten nicht einer identifizierten
												oder identifizierbaren natürlichen Person zugewiesen werden.
											</p>
										</li>
										<li>
											<b>g) Verantwortlicher oder für die Verarbeitung Verantwortlicher</b>
											<p>
												Verantwortlicher oder für die Verarbeitung Verantwortlicher ist die natürliche oder juristische
												Person, Behörde, Einrichtung oder andere Stelle, die allein oder gemeinsam mit anderen über die
												Zwecke und Mittel der Verarbeitung von personenbezogenen Daten entscheidet. Sind die Zwecke und
												Mittel dieser Verarbeitung durch das Unionsrecht oder das Recht der Mitgliedstaaten vorgegeben,
												so kann der Verantwortliche beziehungsweise können die bestimmten Kriterien seiner Benennung
												nach dem Unionsrecht oder dem Recht der Mitgliedstaaten vorgesehen werden.
											</p>
										</li>
										<li>
											<b>h) Auftragsverarbeiter</b>
											<p>
												Auftragsverarbeiter ist eine natürliche oder juristische Person, Behörde, Einrichtung oder
												andere Stelle, die personenbezogene Daten im Auftrag des Verantwortlichen verarbeitet.
											</p>
										</li>
										<li>
											<b>i) Empfänger</b>
											<p>
												Empfänger ist eine natürliche oder juristische Person, Behörde, Einrichtung oder andere Stelle,
												der personenbezogene Daten offengelegt werden, unabhängig davon, ob es sich bei ihr um einen
												Dritten handelt oder nicht. Behörden, die im Rahmen eines bestimmten Untersuchungsauftrags nach
												dem Unionsrecht oder dem Recht der Mitgliedstaaten möglicherweise personenbezogene Daten
												erhalten, gelten jedoch nicht als Empfänger.
											</p>
										</li>
										<li>
											<b>j) Dritter</b>
											<p>
												Dritter ist eine natürliche oder juristische Person, Behörde, Einrichtung oder andere Stelle
												außer der betroffenen Person, dem Verantwortlichen, dem Auftragsverarbeiter und den Personen,
												die unter der unmittelbaren Verantwortung des Verantwortlichen oder des Auftragsverarbeiters
												befugt sind, die personenbezogenen Daten zu verarbeiten.
											</p>
										</li>
										<li>
											<b>k) Einwilligung</b>
											<p>
												Einwilligung ist jede von der betroffenen Person freiwillig für den bestimmten Fall in
												informierter Weise und unmissverständlich abgegebene Willensbekundung in Form einer Erklärung
												oder einer sonstigen eindeutigen bestätigenden Handlung, mit der die betroffene Person zu
												verstehen gibt, dass sie mit der Verarbeitung der sie betreffenden personenbezogenen Daten
												einverstanden ist.
											</p>
										</li>
									</ul>

									<b>2. Name und Anschrift des für die Verarbeitung Verantwortlichen</b>
									<p>
										Verantwortlicher im Sinne der Datenschutz-Grundverordnung, sonstiger in den Mitgliedstaaten der
										Europäischen Union geltenden Datenschutzgesetze und anderer Bestimmungen mit datenschutzrechtlichem
										Charakter ist die:
									</p>

									<b>Primed Marketing Agency GmbH</b>
									<br />
									<b>Wipplingerstraße 12/2/4</b>
									<br />
									<b>1010 Vienna, AT</b>
									<br />
									<b>Email: info@infludata.com</b>
									<br />
									<b>Website: https://www.infludata.com</b>
									<br />
									<br />

									<b>3. Cookies</b>
									<p>
										Die Internetseiten der Primed Marketing Agency GmbH verwenden Cookies. Cookies sind Textdateien,
										welche über einen Internetbrowser auf einem Computersystem abgelegt und gespeichert werden.
									</p>

									<p>
										Zahlreiche Internetseiten und Server verwenden Cookies. Viele Cookies enthalten eine sogenannte
										Cookie-ID. Eine Cookie-ID ist eine eindeutige Kennung des Cookies. Sie besteht aus einer
										Zeichenfolge, durch welche Internetseiten und Server dem konkreten Internetbrowser zugeordnet werden
										können, in dem das Cookie gespeichert wurde. Dies ermöglicht es den besuchten Internetseiten und
										Servern, den individuellen Browser der betroffenen Person von anderen Internetbrowsern, die andere
										Cookies enthalten, zu unterscheiden. Ein bestimmter Internetbrowser kann über die eindeutige
										Cookie-ID wiedererkannt und identifiziert werden.
									</p>

									<p>
										Durch den Einsatz von Cookies kann die Primed Marketing Agency GmbH den Nutzern dieser Internetseite
										nutzerfreundlichere Services bereitstellen, die ohne die Cookie-Setzung nicht möglich wären.
									</p>

									<p>
										Mittels eines Cookies können die Informationen und Angebote auf unserer Internetseite im Sinne des
										Benutzers optimiert werden. Cookies ermöglichen uns, wie bereits erwähnt, die Benutzer unserer
										Internetseite wiederzuerkennen. Zweck dieser Wiedererkennung ist es, den Nutzern die Verwendung
										unserer Internetseite zu erleichtern. Der Benutzer einer Internetseite, die Cookies verwendet, muss
										beispielsweise nicht bei jedem Besuch der Internetseite erneut seine Zugangsdaten eingeben, weil
										dies von der Internetseite und dem auf dem Computersystem des Benutzers abgelegten Cookie übernommen
										wird. Ein weiteres Beispiel ist das Cookie eines Warenkorbes im Online-Shop. Der Online-Shop merkt
										sich die Artikel, die ein Kunde in den virtuellen Warenkorb gelegt hat, über ein Cookie.
									</p>

									<p>
										Die betroffene Person kann die Setzung von Cookies durch unsere Internetseite jederzeit mittels
										einer entsprechenden Einstellung des genutzten Internetbrowsers verhindern und damit der Setzung von
										Cookies dauerhaft widersprechen. Ferner können bereits gesetzte Cookies jederzeit über einen
										Internetbrowser oder andere Softwareprogramme gelöscht werden. Dies ist in allen gängigen
										Internetbrowsern möglich. Deaktiviert die betroffene Person die Setzung von Cookies in dem genutzten
										Internetbrowser, sind unter Umständen nicht alle Funktionen unserer Internetseite vollumfänglich
										nutzbar.
									</p>

									<b>4. Erfassung von allgemeinen Daten und Informationen</b>
									<p>
										Die Internetseite der Primed Marketing Agency GmbH erfasst mit jedem Aufruf der Internetseite durch
										eine betroffene Person oder ein automatisiertes System eine Reihe von allgemeinen Daten und
										Informationen. Diese allgemeinen Daten und Informationen werden in den Logfiles des Servers
										gespeichert. Erfasst werden können die (1) verwendeten Browsertypen und Versionen, (2) das vom
										zugreifenden System verwendete Betriebssystem, (3) die Internetseite, von welcher ein zugreifendes
										System auf unsere Internetseite gelangt (sogenannte Referrer), (4) die Unterwebseiten, welche über
										ein zugreifendes System auf unserer Internetseite angesteuert werden, (5) das Datum und die Uhrzeit
										eines Zugriffs auf die Internetseite, (6) eine Internet-Protokoll-Adresse (IP-Adresse), (7) der
										Internet-Service-Provider des zugreifenden Systems und (8) sonstige ähnliche Daten und
										Informationen, die der Gefahrenabwehr im Falle von Angriffen auf unsere informationstechnologischen
										Systeme dienen.
									</p>

									<p>
										Bei der Nutzung dieser allgemeinen Daten und Informationen zieht die Primed Marketing Agency GmbH
										keine Rückschlüsse auf die betroffene Person. Diese Informationen werden vielmehr benötigt, um (1)
										die Inhalte unserer Internetseite korrekt auszuliefern, (2) die Inhalte unserer Internetseite sowie
										die Werbung für diese zu optimieren, (3) die dauerhafte Funktionsfähigkeit unserer
										informationstechnologischen Systeme und der Technik unserer Internetseite zu gewährleisten sowie (4)
										um Strafverfolgungsbehörden im Falle eines Cyberangriffes die zur Strafverfolgung notwendigen
										Informationen bereitzustellen. Diese anonym erhobenen Daten und Informationen werden durch die
										Primed Marketing Agency GmbH daher einerseits statistisch und ferner mit dem Ziel ausgewertet, den
										Datenschutz und die Datensicherheit in unserem Unternehmen zu erhöhen, um letztlich ein optimales
										Schutzniveau für die von uns verarbeiteten personenbezogenen Daten sicherzustellen. Die anonymen
										Daten der Server-Logfiles werden getrennt von allen durch eine betroffene Person angegebenen
										personenbezogenen Daten gespeichert.
									</p>

									<b>5. Abonnement unseres Newsletters</b>
									<p>
										Auf der Internetseite der Primed Marketing Agency GmbH wird den Benutzern die Möglichkeit
										eingeräumt, den Newsletter unseres Unternehmens zu abonnieren. Welche personenbezogenen Daten bei
										der Bestellung des Newsletters an den für die Verarbeitung Verantwortlichen übermittelt werden,
										ergibt sich aus der hierzu verwendeten Eingabemaske.
									</p>

									<p>
										Die Primed Marketing Agency GmbH informiert ihre Kunden und Geschäftspartner in regelmäßigen
										Abständen im Wege eines Newsletters über Angebote des Unternehmens. Der Newsletter unseres
										Unternehmens kann von der betroffenen Person grundsätzlich nur dann empfangen werden, wenn (1) die
										betroffene Person über eine gültige E-Mail-Adresse verfügt und (2) die betroffene Person sich für
										den Newsletterversand registriert. An die von einer betroffenen Person erstmalig für den
										Newsletterversand eingetragene E-Mail-Adresse wird aus rechtlichen Gründen eine Bestätigungsmail im
										Double-Opt-In-Verfahren versendet. Diese Bestätigungsmail dient der Überprüfung, ob der Inhaber der
										E-Mail-Adresse als betroffene Person den Empfang des Newsletters autorisiert hat.
									</p>

									<p>
										Bei der Anmeldung zum Newsletter speichern wir ferner die vom Internet-Service-Provider (ISP)
										vergebene IP-Adresse des von der betroffenen Person zum Zeitpunkt der Anmeldung verwendeten
										Computersystems sowie das Datum und die Uhrzeit der Anmeldung. Die Erhebung dieser Daten ist
										erforderlich, um den(möglichen) Missbrauch der E-Mail-Adresse einer betroffenen Person zu einem
										späteren Zeitpunkt nachvollziehen zu können und dient deshalb der rechtlichen Absicherung des für
										die Verarbeitung Verantwortlichen.
									</p>

									<p>
										Die im Rahmen einer Anmeldung zum Newsletter erhobenen personenbezogenen Daten werden ausschließlich
										zum Versand unseres Newsletters verwendet. Ferner könnten Abonnenten des Newsletters per E-Mail
										informiert werden, sofern dies für den Betrieb des Newsletter-Dienstes oder eine diesbezügliche
										Registrierung erforderlich ist, wie dies im Falle von Änderungen am Newsletterangebot oder bei der
										Veränderung der technischen Gegebenheiten der Fall sein könnte. Es erfolgt keine Weitergabe der im
										Rahmen des Newsletter-Dienstes erhobenen personenbezogenen Daten an Dritte. Das Abonnement unseres
										Newsletters kann durch die betroffene Person jederzeit gekündigt werden. Die Einwilligung in die
										Speicherung personenbezogener Daten, die die betroffene Person uns für den Newsletterversand erteilt
										hat, kann jederzeit widerrufen werden. Zum Zwecke des Widerrufs der Einwilligung findet sich in
										jedem Newsletter ein entsprechender Link. Ferner besteht die Möglichkeit, sich jederzeit auch direkt
										auf der Internetseite des für die Verarbeitung Verantwortlichen vom Newsletterversand abzumelden
										oder dies dem für die Verarbeitung Verantwortlichen auf andere Weise mitzuteilen.
									</p>

									<b>6. Newsletter-Tracking</b>
									<p>
										Die Newsletter der Primed Marketing Agency GmbH enthalten sogenannte Zählpixel. Ein Zählpixel ist
										eine Miniaturgrafik, die in solche E-Mails eingebettet wird, welche im HTML-Format versendet werden,
										um eine Logdatei-Aufzeichnung und eine Logdatei-Analyse zu ermöglichen. Dadurch kann eine
										statistische Auswertung des Erfolges oder Misserfolges von Online-Marketing-Kampagnen durchgeführt
										werden. Anhand des eingebetteten Zählpixels kann die Primed Marketing Agency GmbH erkennen, ob und
										wann eine E-Mail von einer betroffenen Person geöffnet wurde und welche in der E-Mail befindlichen
										Links von der betroffenen Person aufgerufen wurden.
									</p>

									<p>
										Solche über die in den Newslettern enthaltenen Zählpixel erhobenen personenbezogenen Daten, werden
										von dem für die Verarbeitung Verantwortlichen gespeichert und ausgewertet, um den Newsletterversand
										zu optimieren und den Inhalt zukünftiger Newsletter noch besser den Interessen der betroffenen
										Person anzupassen. Diese personenbezogenen Daten werden nicht an Dritte weitergegeben. Betroffene
										Personen sind jederzeit berechtigt, die diesbezügliche gesonderte, über das Double-Opt-In-Verfahren
										abgegebene Einwilligungserklärung zu widerrufen. Nach einem Widerruf werden diese personenbezogenen
										Daten von dem für die Verarbeitung Verantwortlichen gelöscht. Eine Abmeldung vom Erhalt des
										Newsletters deutet die Primed Marketing Agency GmbH automatisch als Widerruf.
									</p>

									<b>7. Kontaktmöglichkeit über die Internetseite</b>
									<p>
										Die Internetseite der Primed Marketing Agency GmbH enthält aufgrund von gesetzlichen Vorschriften
										Angaben, die eine schnelle elektronische Kontaktaufnahme zu unserem Unternehmen sowie eine
										unmittelbare Kommunikation mit uns ermöglichen, was ebenfalls eine allgemeine Adresse der
										sogenannten elektronischen Post (E-Mail-Adresse) umfasst. Sofern eine betroffene Person per E-Mail
										oder über ein Kontaktformular den Kontakt mit dem für die Verarbeitung Verantwortlichen aufnimmt,
										werden die von der betroffenen Person übermittelten personenbezogenen Daten automatisch gespeichert.
										Solche auf freiwilliger Basis von einer betroffenen Person an den für die Verarbeitung
										Verantwortlichen übermittelten personenbezogenen Daten werden für Zwecke der Bearbeitung oder der
										Kontaktaufnahme zur betroffenen Person gespeichert. Es erfolgt keine Weitergabe dieser
										personenbezogenen Daten an Dritte.
									</p>

									<b>8. Routinemäßige Löschung und Sperrung von personenbezogenen Daten</b>
									<p>
										Der für die Verarbeitung Verantwortliche verarbeitet und speichert personenbezogene Daten der
										betroffenen Person nur für den Zeitraum, der zur Erreichung des Speicherungszwecks erforderlich ist
										oder sofern dies durch den Europäischen Richtlinien- und Verordnungsgeber oder einen anderen
										Gesetzgeber in Gesetzen oder Vorschriften, welchen der für die Verarbeitung Verantwortliche
										unterliegt, vorgesehen wurde.
									</p>

									<p>
										Entfällt der Speicherungszweck oder läuft eine vom Europäischen Richtlinien- und Verordnungsgeber
										oder einem anderen zuständigen Gesetzgeber vorgeschriebene Speicherfrist ab, werden die
										personenbezogenen Daten routinemäßig und entsprechend den gesetzlichen Vorschriften gesperrt oder
										gelöscht.
									</p>

									<b>9. Rechte der betroffenen Person</b>
									<ul style={{ listStyle: 'none', paddingLeft: 0 }}>
										<li>
											<b>a) Recht auf Bestätigung</b>
											<p>
												Jede betroffene Person hat das vom Europäischen Richtlinien- und Verordnungsgeber eingeräumte
												Recht, von dem für die Verarbeitung Verantwortlichen eine Bestätigung darüber zu verlangen, ob
												sie betreffende personenbezogene Daten verarbeitet werden. Möchte eine betroffene Person dieses
												Bestätigungsrecht in Anspruch nehmen, kann sie sich hierzu jederzeit an einen Mitarbeiter des
												für die Verarbeitung Verantwortlichen wenden.
											</p>
										</li>
										<li>
											<b>b) Recht auf Auskunft</b>
											<p>
												Jede von der Verarbeitung personenbezogener Daten betroffene Person hat das vom Europäischen
												Richtlinien- und Verordnungsgeber gewährte Recht, jederzeit von dem für die Verarbeitung
												Verantwortlichen unentgeltliche Auskunft über die zu seiner Person gespeicherten
												personenbezogenen Daten und eine Kopie dieser Auskunft zu erhalten. Ferner hat der Europäische
												Richtlinien- und Verordnungsgeber der betroffenen Person Auskunft über folgende Informationen
												zugestanden:
											</p>

											<ul style={{ listStyle: 'none', paddingLeft: 0 }}>
												<li>die Verarbeitungszwecke</li>
												<li>die Kategorien personenbezogener Daten, die verarbeitet werden</li>
												<li>
													die Empfänger oder Kategorien von Empfängern, gegenüber denen die personenbezogenen Daten
													offengelegt worden sind oder noch offengelegt werden, insbesondere bei Empfängern in
													Drittländern oder bei internationalen Organisationen
												</li>
												<li>
													falls möglich die geplante Dauer, für die die personenbezogenen Daten gespeichert werden,
													oder, falls dies nicht möglich ist, die Kriterien für die Festlegung dieser Dauer
												</li>
												<li>
													das Bestehen eines Rechts auf Berichtigung oder Löschung der sie betreffenden
													personenbezogenen Daten oder auf Einschränkung der Verarbeitung durch den Verantwortlichen
													oder eines Widerspruchsrechts gegen diese Verarbeitung
												</li>
												<li>das Bestehen eines Beschwerderechts bei einer Aufsichtsbehörde</li>
												<li>
													wenn die personenbezogenen Daten nicht bei der betroffenen Person erhoben werden: Alle
													verfügbaren Informationen über die Herkunft der Daten
												</li>
												<li>
													das Bestehen einer automatisierten Entscheidungsfindung einschließlich Profiling gemäß Artikel
													22 Abs.1 und 4 DS-GVO und — zumindest in diesen Fällen — aussagekräftige Informationen über
													die involvierte Logik sowie die Tragweite und die angestrebten Auswirkungen einer derartigen
													Verarbeitung für die betroffene Person
												</li>
											</ul>
											<p>
												Ferner steht der betroffenen Person ein Auskunftsrecht darüber zu, ob personenbezogene Daten an
												ein Drittland oder an eine internationale Organisation übermittelt wurden. Sofern dies der Fall
												ist, so steht der betroffenen Person im Übrigen das Recht zu, Auskunft über die geeigneten
												Garantien im Zusammenhang mit der Übermittlung zu erhalten.
											</p>

											<p>
												Möchte eine betroffene Person dieses Auskunftsrecht in Anspruch nehmen, kann sie sich hierzu
												jederzeit an einen Mitarbeiter des für die Verarbeitung Verantwortlichen wenden.
											</p>
										</li>
										<li>
											<b>c) Recht auf Berichtigung</b>
											<p>
												Jede von der Verarbeitung personenbezogener Daten betroffene Person hat das vom Europäischen
												Richtlinien- und Verordnungsgeber gewährte Recht, die unverzügliche Berichtigung sie
												betreffender unrichtiger personenbezogener Daten zu verlangen. Ferner steht der betroffenen
												Person das Recht zu, unter Berücksichtigung der Zwecke der Verarbeitung, die Vervollständigung
												unvollständiger personenbezogener Daten — auch mittels einer ergänzenden Erklärung — zu
												verlangen.
											</p>

											<p>
												Möchte eine betroffene Person dieses Berichtigungsrecht in Anspruch nehmen, kann sie sich hierzu
												jederzeit an einen Mitarbeiter des für die Verarbeitung Verantwortlichen wenden.
											</p>
										</li>
										<li>
											<b>d) Recht auf Löschung (Recht auf Vergessen werden)</b>
											<p>
												Jede von der Verarbeitung personenbezogener Daten betroffene Person hat das vom Europäischen
												Richtlinien- und Verordnungsgeber gewährte Recht, von dem Verantwortlichen zu verlangen, dass
												die sie betreffenden personenbezogenen Daten unverzüglich gelöscht werden, sofern einer der
												folgenden Gründe zutrifft und soweit die Verarbeitung nicht erforderlich ist:
											</p>

											<ul style={{ listStyle: 'none', paddingLeft: 0 }}>
												<li>
													Die personenbezogenen Daten wurden für solche Zwecke erhoben oder auf sonstige Weise
													verarbeitet, für welche sie nicht mehr notwendig sind.
												</li>
												<li>
													Die betroffene Person widerruft ihre Einwilligung, auf die sich die Verarbeitung gemäß Art. 6
													Abs. 1 Buchstabe a DS-GVO oder Art. 9 Abs. 2 Buchstabe a DS-GVO stützte, und es fehlt an einer
													anderweitigen Rechtsgrundlage für die Verarbeitung.
												</li>
												<li>
													Die betroffene Person legt gemäß Art. 21 Abs. 1 DS-GVO Widerspruch gegen die Verarbeitung ein,
													und es liegen keine vorrangigen berechtigten Gründe für die Verarbeitung vor, oder die
													betroffene Person legt gemäß Art. 21 Abs. 2 DS-GVO Widerspruch gegen die Verarbeitung ein.
												</li>
												<li>Die personenbezogenen Daten wurden unrechtmäßig verarbeitet.</li>
												<li>
													Die Löschung der personenbezogenen Daten ist zur Erfüllung einer rechtlichen Verpflichtung
													nach dem Unionsrecht oder dem Recht der Mitgliedstaaten erforderlich, dem der Verantwortliche
													unterliegt.
												</li>
												<li>
													Die personenbezogenen Daten wurden in Bezug auf angebotene Dienste der
													Informationsgesellschaft gemäß Art. 8 Abs. 1 DS-GVO erhoben.
												</li>
											</ul>
											<p>
												Sofern einer der oben genannten Gründe zutrifft und eine betroffene Person die Löschung von
												personenbezogenen Daten, die bei der Primed Marketing Agency GmbH gespeichert sind, veranlassen
												möchte, kann sie sich hierzu jederzeit an einen Mitarbeiter des für die Verarbeitung
												Verantwortlichen wenden. Der Mitarbeiter der Primed Marketing Agency GmbH wird veranlassen, dass
												dem Löschverlangen unverzüglich nachgekommen wird.
											</p>

											<p>
												Wurden die personenbezogenen Daten von der Primed Marketing Agency GmbH öffentlich gemacht und
												ist unser Unternehmen als Verantwortlicher gemäß Art. 17 Abs. 1 DS-GVO zur Löschung der
												personenbezogenen Daten verpflichtet, so trifft die Primed Marketing Agency GmbH unter
												Berücksichtigung der verfügbaren Technologie und der Implementierungskosten angemessene
												Maßnahmen, auch technischer Art, um andere für die Datenverarbeitung Verantwortliche, welche die
												veröffentlichten personenbezogenen Daten verarbeiten, darüber in Kenntnis zu setzen, dass die
												betroffene Person von diesen anderen für die Datenverarbeitung Verantwortlichen die Löschung
												sämtlicher Links zu diesen personenbezogenen Daten oder von Kopien oder Replikationen dieser
												personenbezogenen Daten verlangt hat, soweit die Verarbeitung nicht erforderlich ist. Der
												Mitarbeiter der Primed Marketing Agency GmbH wird im Einzelfall das Notwendige veranlassen.
											</p>
										</li>
										<li>
											<b>e) Recht auf Einschränkung der Verarbeitung</b>
											<p>
												Jede von der Verarbeitung personenbezogener Daten betroffene Person hat das vom Europäischen
												Richtlinien- und Verordnungsgeber gewährte Recht, von dem Verantwortlichen die Einschränkung der
												Verarbeitung zu verlangen, wenn eine der folgenden Voraussetzungen gegeben ist:
											</p>

											<ul style={{ listStyle: 'none', paddingLeft: 0 }}>
												<li>
													Die Richtigkeit der personenbezogenen Daten wird von der betroffenen Person bestritten, und
													zwar für eine Dauer, die es dem Verantwortlichen ermöglicht, die Richtigkeit der
													personenbezogenen Daten zu überprüfen.
												</li>
												<li>
													Die Verarbeitung ist unrechtmäßig, die betroffene Person lehnt die Löschung der
													personenbezogenen Daten ab und verlangt stattdessen die Einschränkung der Nutzung der
													personenbezogenen Daten.
												</li>
												<li>
													Der Verantwortliche benötigt die personenbezogenen Daten für die Zwecke der Verarbeitung nicht
													länger, die betroffene Person benötigt sie jedoch zur Geltendmachung, Ausübung oder
													Verteidigung von Rechtsansprüchen.
												</li>
												<li>
													Die betroffene Person hat Widerspruch gegen die Verarbeitung gem. Art. 21 Abs. 1 DS-GVO
													eingelegt und es steht noch nicht fest, ob die berechtigten Gründe des Verantwortlichen
													gegenüber denen der betroffenen Person überwiegen.
												</li>
											</ul>
											<p>
												Sofern eine der oben genannten Voraussetzungen gegeben ist und eine betroffene Person die
												Einschränkung von personenbezogenen Daten, die bei der Primed Marketing Agency GmbH gespeichert
												sind, verlangen möchte, kann sie sich hierzu jederzeit an einen Mitarbeiter des für die
												Verarbeitung Verantwortlichen wenden. Der Mitarbeiter der Primed Marketing Agency GmbH wird die
												Einschränkung der Verarbeitung veranlassen.
											</p>
										</li>
										<li>
											<b>f) Recht auf Datenübertragbarkeit</b>
											<p>
												Jede von der Verarbeitung personenbezogener Daten betroffene Person hat das vom Europäischen
												Richtlinien- und Verordnungsgeber gewährte Recht, die sie betreffenden personenbezogenen Daten,
												welche durch die betroffene Person einem Verantwortlichen bereitgestellt wurden, in einem
												strukturierten, gängigen und maschinenlesbaren Format zu erhalten. Sie hat außerdem das Recht,
												diese Daten einem anderen Verantwortlichen ohne Behinderung durch den Verantwortlichen, dem die
												personenbezogenen Daten bereitgestellt wurden, zu übermitteln, sofern die Verarbeitung auf der
												Einwilligung gemäß Art. 6 Abs. 1 Buchstabe a DS-GVO oder Art. 9 Abs. 2 Buchstabe a DS-GVO oder
												auf einem Vertrag gemäß Art. 6 Abs. 1 Buchstabe b DS-GVO beruht und die Verarbeitung mithilfe
												automatisierter Verfahren erfolgt, sofern die Verarbeitung nicht für die Wahrnehmung einer
												Aufgabe erforderlich ist, die im öffentlichen Interesse liegt oder in Ausübung öffentlicher
												Gewalt erfolgt, welche dem Verantwortlichen übertragen wurde.
											</p>

											<p>
												Ferner hat die betroffene Person bei der Ausübung ihres Rechts auf Datenübertragbarkeit gemäß
												Art. 20 Abs. 1 DS-GVO das Recht, zu erwirken, dass die personenbezogenen Daten direkt von einem
												Verantwortlichen an einen anderen Verantwortlichen übermittelt werden, soweit dies technisch
												machbar ist und sofern hiervon nicht die Rechte und Freiheiten anderer Personen beeinträchtigt
												werden.
											</p>

											<p>
												Zur Geltendmachung des Rechts auf Datenübertragbarkeit kann sich die betroffene Person jederzeit
												an einen Mitarbeiter der Primed Marketing Agency GmbH wenden.
											</p>
										</li>
										<li>
											<b>g) Recht auf Widerspruch</b>
											<p>
												Jede von der Verarbeitung personenbezogener Daten betroffene Person hat das vom Europäischen
												Richtlinien- und Verordnungsgeber gewährte Recht, aus Gründen, die sich aus ihrer besonderen
												Situation ergeben, jederzeit gegen die Verarbeitung sie betreffender personenbezogener Daten,
												die aufgrund von Art. 6 Abs. 1 Buchstaben e oder f DS-GVO erfolgt, Widerspruch einzulegen. Dies
												gilt auch für ein auf diese Bestimmungen gestütztes Profiling.
											</p>

											<p>
												Die Primed Marketing Agency GmbH verarbeitet die personenbezogenen Daten im Falle des
												Widerspruchs nicht mehr, es sei denn, wir können zwingende schutzwürdige Gründe für die
												Verarbeitung nachweisen, die den Interessen, Rechten und Freiheiten der betroffenen Person
												überwiegen, oder die Verarbeitung dient der Geltendmachung, Ausübung oder Verteidigung von
												Rechtsansprüchen.
											</p>

											<p>
												Verarbeitet die Primed Marketing Agency GmbH personenbezogene Daten, um Direktwerbung zu
												betreiben, so hat die betroffene Person das Recht, jederzeit Widerspruch gegen die Verarbeitung
												der personenbezogenen Daten zum Zwecke derartiger Werbung einzulegen. Dies gilt auch für das
												Profiling, soweit es mit solcher Direktwerbung in Verbindung steht. Widerspricht die betroffene
												Person gegenüber der Primed Marketing Agency GmbH der Verarbeitung für Zwecke der Direktwerbung,
												so wird die Primed Marketing Agency GmbH die personenbezogenen Daten nicht mehr für diese Zwecke
												verarbeiten.
											</p>

											<p>
												Zudem hat die betroffene Person das Recht, aus Gründen, die sich aus ihrer besonderen Situation
												ergeben, gegen die sie betreffende Verarbeitung personenbezogener Daten, die bei der Primed
												Marketing Agency GmbH zu wissenschaftlichen oder historischen Forschungszwecken oder zu
												statistischen Zwecken gemäß Art. 89 Abs. 1 DS-GVO erfolgen, Widerspruch einzulegen, es sei denn,
												eine solche Verarbeitung ist zur Erfüllung einer im öffentlichen Interesse liegenden Aufgabe
												erforderlich.
											</p>

											<p>
												Zur Ausübung des Rechts auf Widerspruch kann sich die betroffene Person direkt an jeden
												Mitarbeiter der Primed Marketing Agency GmbH oder einen anderen Mitarbeiter wenden. Der
												betroffenen Person steht es ferner frei, im Zusammenhang mit der Nutzung von Diensten der
												Informationsgesellschaft, ungeachtet der Richtlinie 2002/58/EG, ihr Widerspruchsrecht mittels
												automatisierter Verfahren auszuüben, bei denen technische Spezifikationen verwendet werden.
											</p>
										</li>
										<li>
											<b>h) Automatisierte Entscheidungen im Einzelfall einschließlich Profiling</b>
											<p>
												Jede von der Verarbeitung personenbezogener Daten betroffene Person hat das vom Europäischen
												Richtlinien- und Verordnungsgeber gewährte Recht, nicht einer ausschließlich auf einer
												automatisierten Verarbeitung — einschließlich Profiling — beruhenden Entscheidung unterworfen zu
												werden, die ihr gegenüber rechtliche Wirkung entfaltet oder sie in ähnlicher Weise erheblich
												beeinträchtigt, sofern die Entscheidung (1) nicht für den Abschluss oder die Erfüllung eines
												Vertrags zwischen der betroffenen Person und dem Verantwortlichen erforderlich ist, oder (2)
												aufgrund von Rechtsvorschriften der Union oder der Mitgliedstaaten, denen der Verantwortliche
												unterliegt, zulässig ist und diese Rechtsvorschriften angemessene Maßnahmen zur Wahrung der
												Rechte und Freiheiten sowie der berechtigten Interessen der betroffenen Person enthalten oder
												(3) mit ausdrücklicher Einwilligung der betroffenen Person erfolgt.
											</p>

											<p>
												Ist die Entscheidung (1) für den Abschluss oder die Erfüllung eines Vertrags zwischen der
												betroffenen Person und dem Verantwortlichen erforderlich oder (2) erfolgt sie mit ausdrücklicher
												Einwilligung der betroffenen Person, trifft die Primed Marketing Agency GmbH angemessene
												Maßnahmen, um die Rechte und Freiheiten sowie die berechtigten Interessen der betroffenen Person
												zu wahren, wozu mindestens das Recht auf Erwirkung des Eingreifens einer Person seitens des
												Verantwortlichen, auf Darlegung des eigenen Standpunkts und auf Anfechtung der Entscheidung
												gehört.
											</p>

											<p>
												Möchte die betroffene Person Rechte mit Bezug auf automatisierte Entscheidungen geltend machen,
												kann sie sich hierzu jederzeit an einen Mitarbeiter des für die Verarbeitung Verantwortlichen
												wenden.
											</p>
										</li>
										<li>
											<b>i) Recht auf Widerruf einer datenschutzrechtlichen Einwilligung</b>
											<p>
												Jede von der Verarbeitung personenbezogener Daten betroffene Person hat das vom Europäischen
												Richtlinien- und Verordnungsgeber gewährte Recht, eine Einwilligung zur Verarbeitung
												personenbezogener Daten jederzeit zu widerrufen.
											</p>

											<p>
												Möchte die betroffene Person ihr Recht auf Widerruf einer Einwilligung geltend machen, kann sie
												sich hierzu jederzeit an einen Mitarbeiter des für die Verarbeitung Verantwortlichen wenden.
											</p>
										</li>
									</ul>
									<b>
										10. Datenschutzbestimmungen zu Einsatz und Verwendung von Google Analytics (mit
										Anonymisierungsfunktion)
									</b>
									<p>
										Der für die Verarbeitung Verantwortliche hat auf dieser Internetseite die Komponente Google
										Analytics (mit Anonymisierungsfunktion) integriert. Google Analytics ist ein Web-Analyse-Dienst.
										Web-Analyse ist die Erhebung, Sammlung und Auswertung von Daten über das Verhalten von Besuchern von
										Internetseiten. Ein Web-Analyse-Dienst erfasst unter anderem Daten darüber, von welcher
										Internetseite eine betroffene Person auf eine Internetseite gekommen ist (sogenannte Referrer), auf
										welche Unterseiten der Internetseite zugegriffen oder wie oft und für welche Verweildauer eine
										Unterseite betrachtet wurde. Eine Web-Analyse wird überwiegend zur Optimierung einer Internetseite
										und zur Kosten-Nutzen-Analyse von Internetwerbung eingesetzt.
									</p>

									<p>
										Betreibergesellschaft der Google-Analytics-Komponente ist die Google Ireland Limited, Gordon House,
										Barrow Street, Dublin, D04 E5W5, Ireland.
									</p>

									<p>
										Der für die Verarbeitung Verantwortliche verwendet für die Web-Analyse über Google Analytics den
										Zusatz "_gat._anonymizeIp". Mittels dieses Zusatzes wird die IP-Adresse des Internetanschlusses der
										betroffenen Person von Google gekürzt und anonymisiert, wenn der Zugriff auf unsere Internetseiten
										aus einem Mitgliedstaat der Europäischen Union oder aus einem anderen Vertragsstaat des Abkommens
										über den Europäischen Wirtschaftsraum erfolgt.
									</p>

									<p>
										Der Zweck der Google-Analytics-Komponente ist die Analyse der Besucherströme auf unserer
										Internetseite. Google nutzt die gewonnenen Daten und Informationen unter anderem dazu, die Nutzung
										unserer Internetseite auszuwerten, um für uns Online-Reports, welche die Aktivitäten auf unseren
										Internetseiten aufzeigen, zusammenzustellen, und um weitere mit der Nutzung unserer Internetseite in
										Verbindung stehende Dienstleistungen zu erbringen.
									</p>

									<p>
										Google Analytics setzt ein Cookie auf dem informationstechnologischen System der betroffenen Person.
										Was Cookies sind, wurde oben bereits erläutert. Mit Setzung des Cookies wird Google eine Analyse der
										Benutzung unserer Internetseite ermöglicht. Durch jeden Aufruf einer der Einzelseiten dieser
										Internetseite, die durch den für die Verarbeitung Verantwortlichen betrieben wird und auf welcher
										eine Google-Analytics-Komponente integriert wurde, wird der Internetbrowser auf dem
										informationstechnologischen System der betroffenen Person automatisch durch die jeweilige
										Google-Analytics-Komponente veranlasst, Daten zum Zwecke der Online-Analyse an Google zu
										übermitteln. Im Rahmen dieses technischen Verfahrens erhält Google Kenntnis über personenbezogene
										Daten, wie der IP-Adresse der betroffenen Person, die Google unter anderem dazu dienen, die Herkunft
										der Besucher und Klicks nachzuvollziehen und in der Folge Provisionsabrechnungen zu ermöglichen.
									</p>

									<p>
										Mittels des Cookies werden personenbezogene Informationen, beispielsweise die Zugriffszeit, der Ort,
										von welchem ein Zugriff ausging und die Häufigkeit der Besuche unserer Internetseite durch die
										betroffene Person, gespeichert. Bei jedem Besuch unserer Internetseiten werden diese
										personenbezogenen Daten, einschließlich der IP-Adresse des von der betroffenen Person genutzten
										Internetanschlusses, an Google in den Vereinigten Staaten von Amerika übertragen. Diese
										personenbezogenen Daten werden durch Google in den Vereinigten Staaten von Amerika gespeichert.
										Google gibt diese über das technische Verfahren erhobenen personenbezogenen Daten unter Umständen an
										Dritte weiter.
									</p>

									<p>
										Die betroffene Person kann die Setzung von Cookies durch unsere Internetseite, wie oben bereits
										dargestellt, jederzeit mittels einer entsprechenden Einstellung des genutzten Internetbrowsers
										verhindern und damit der Setzung von Cookies dauerhaft widersprechen. Eine solche Einstellung des
										genutzten Internetbrowsers würde auch verhindern, dass Google ein Cookie auf dem
										informationstechnologischen System der betroffenen Person setzt. Zudem kann ein von Google Analytics
										bereits gesetzter Cookie jederzeit über den Internetbrowser oder andere Softwareprogramme gelöscht
										werden.
									</p>

									<p>
										Ferner besteht für die betroffene Person die Möglichkeit, einer Erfassung der durch Google Analytics
										erzeugten, auf eine Nutzung dieser Internetseite bezogenen Daten sowie der Verarbeitung dieser Daten
										durch Google zu widersprechen und eine solche zu verhindern. Hierzu muss die betroffene Person ein
										Browser-Add-On unter dem Link https://tools.google.com/dlpage/gaoptout herunterladen und
										installieren. Dieses Browser-Add-On teilt Google Analytics über JavaScript mit, dass keine Daten und
										Informationen zu den Besuchen von Internetseiten an Google Analytics übermittelt werden dürfen. Die
										Installation des Browser-Add-Ons wird von Google als Widerspruch gewertet. Wird das
										informationstechnologische System der betroffenen Person zu einem späteren Zeitpunkt gelöscht,
										formatiert oder neu installiert, muss durch die betroffene Person eine erneute Installation des
										Browser-Add-Ons erfolgen, um Google Analytics zu deaktivieren. Sofern das Browser-Add-On durch die
										betroffene Person oder einer anderen Person, die ihrem Machtbereich zuzurechnen ist, deinstalliert
										oder deaktiviert wird, besteht die Möglichkeit der Neuinstallation oder der erneuten Aktivierung des
										Browser-Add-Ons.
									</p>

									<p>
										Weitere Informationen und die geltenden Datenschutzbestimmungen von Google können unter
										https://www.google.de/intl/de/policies/privacy/ und unter
										http://www.google.com/analytics/terms/de.html abgerufen werden. Google Analytics wird unter diesem
										Link https://www.google.com/intl/de_de/analytics/ genauer erläutert.
									</p>

									<b>11. Datenschutzbestimmungen zu Einsatz und Verwendung von Instagram</b>
									<p>
										Der für die Verarbeitung Verantwortliche hat auf dieser Internetseite Komponenten des Dienstes
										Instagram integriert. Instagram ist ein Dienst, der als audiovisuelle Plattform zu qualifizieren ist
										und den Nutzern das Teilen von Fotos und Videos und zudem eine Weiterverbreitung solcher Daten in
										anderen sozialen Netzwerken ermöglicht.
									</p>

									<p>
										Betreibergesellschaft der Dienste von Instagram ist die Facebook Ireland Ltd., 4 Grand Canal Square,
										Grand Canal Harbour, Dublin 2 Ireland.
									</p>

									<p>
										Durch jeden Aufruf einer der Einzelseiten dieser Internetseite, die durch den für die Verarbeitung
										Verantwortlichen betrieben wird und auf welcher eine Instagram-Komponente integriert wurde, wird der
										Internetbrowser auf dem informationstechnologischen System der betroffenen Person automatisch durch
										die jeweilige Instagram-Komponente veranlasst, eine Darstellung der entsprechenden Komponente von
										Instagram herunterzuladen. Im Rahmen dieses technischen Verfahrens erhält Instagram Kenntnis
										darüber, welche konkrete Unterseite unserer Internetseite durch die betroffene Person besucht wird.
									</p>

									<p>
										Sofern die betroffene Person gleichzeitig bei Instagram eingeloggt ist, erkennt Instagram mit jedem
										Aufruf unserer Internetseite durch die betroffene Person und während der gesamten Dauer des
										jeweiligen Aufenthaltes auf unserer Internetseite, welche konkrete Unterseite die betroffene Person
										besucht. Diese Informationen werden durch die Instagram-Komponente gesammelt und durch Instagram dem
										jeweiligen Instagram-Account der betroffenen Person zugeordnet. Betätigt die betroffene Person einen
										der auf unserer Internetseite integrierten Instagram-Link, werden die damit übertragenen Daten und
										Informationen dem persönlichen Instagram-Benutzerkonto der betroffenen Person zugeordnet und von
										Instagram gespeichert und verarbeitet.
									</p>

									<p>
										Instagram erhält über die Instagram-Komponente immer dann eine Information darüber, dass die
										betroffene Person unsere Internetseite besucht hat, wenn die betroffene Person zum Zeitpunkt des
										Aufrufs unserer Internetseite gleichzeitig bei Instagram eingeloggt ist; dies findet unabhängig
										davon statt, ob die betroffene Person die Instagram-Komponente anklickt oder nicht. Ist eine
										derartige Übermittlung dieser Informationen an Instagram von der betroffenen Person nicht gewollt,
										kann diese die Übermittlung dadurch verhindern, dass sie sich vor einem Aufruf unserer Internetseite
										aus ihrem Instagram-Account ausloggt.
									</p>

									<p>
										Weitere Informationen und die geltenden Datenschutzbestimmungen von Instagram können unter
										https://help.instagram.com/155833707900388 und https://www.instagram.com/about/legal/privacy/
										abgerufen werden.
									</p>

									<b>12. Rechtsgrundlage der Verarbeitung</b>
									<p>
										Art. 6 I lit. a DS-GVO dient unserem Unternehmen als Rechtsgrundlage für Verarbeitungsvorgänge, bei
										denen wir eine Einwilligung für einen bestimmten Verarbeitungszweck einholen. Ist die Verarbeitung
										personenbezogener Daten zur Erfüllung eines Vertrags, dessen Vertragspartei die betroffene Person
										ist, erforderlich, wie dies beispielsweise bei Verarbeitungsvorgängen der Fall ist, die für eine
										Lieferung von Waren oder die Erbringung einer sonstigen Leistung oder Gegenleistung notwendig sind,
										so beruht die Verarbeitung auf Art. 6 I lit. b DS-GVO. Gleiches gilt für solche
										Verarbeitungsvorgänge die zur Durchführung vorvertraglicher Maßnahmen erforderlich sind, etwa in
										Fällen von Anfragen zur unseren Produkten oder Leistungen. Unterliegt unser Unternehmen einer
										rechtlichen Verpflichtung durch welche eine Verarbeitung von personenbezogenen Daten erforderlich
										wird, wie beispielsweise zur Erfüllung steuerlicher Pflichten, so basiert die Verarbeitung auf Art.
										6 I lit. c DS-GVO. In seltenen Fällen könnte die Verarbeitung von personenbezogenen Daten
										erforderlich werden, um lebenswichtige Interessen der betroffenen Person oder einer anderen
										natürlichen Person zu schützen. Dies wäre beispielsweise der Fall, wenn ein Besucher in unserem
										Betrieb verletzt werden würde und daraufhin sein Name, sein Alter, seine Krankenkassendaten oder
										sonstige lebenswichtige Informationen an einen Arzt, ein Krankenhaus oder sonstige Dritte
										weitergegeben werden müssten. Dann würde die Verarbeitung auf Art. 6 I lit. d DS-GVO beruhen.
										Letztlich könnten Verarbeitungsvorgänge auf Art. 6 I lit. f DS-GVO beruhen. Auf dieser
										Rechtsgrundlage basieren Verarbeitungsvorgänge, die von keiner der vorgenannten Rechtsgrundlagen
										erfasst werden, wenn die Verarbeitung zur Wahrung eines berechtigten Interesses unseres Unternehmens
										oder eines Dritten erforderlich ist, sofern die Interessen, Grundrechte und Grundfreiheiten des
										Betroffenen nicht überwiegen. Solche Verarbeitungsvorgänge sind uns insbesondere deshalb gestattet,
										weil sie durch den Europäischen Gesetzgeber besonders erwähnt wurden. Er vertrat insoweit die
										Auffassung, dass ein berechtigtes Interesse anzunehmen sein könnte, wenn die betroffene Person ein
										Kunde des Verantwortlichen ist (Erwägungsgrund 47 Satz 2 DS-GVO).
									</p>

									<b>
										13. Berechtigte Interessen an der Verarbeitung, die von dem Verantwortlichen oder einem Dritten
										verfolgt werden
									</b>
									<p>
										Basiert die Verarbeitung personenbezogener Daten auf Artikel 6 I lit. f DS-GVO ist unser
										berechtigtes Interesse die Durchführung unserer Geschäftstätigkeit zugunsten des Wohlergehens all
										unserer Mitarbeiter und unserer Anteilseigner.
									</p>

									<b>14. Dauer, für die die personenbezogenen Daten gespeichert werden</b>
									<p>
										Das Kriterium für die Dauer der Speicherung von personenbezogenen Daten ist die jeweilige
										gesetzliche Aufbewahrungsfrist. Nach Ablauf der Frist werden die entsprechenden Daten routinemäßig
										gelöscht, sofern sie nicht mehr zur Vertragserfüllung oder Vertragsanbahnung erforderlich sind.
									</p>

									<b>
										15. Gesetzliche oder vertragliche Vorschriften zur Bereitstellung der personenbezogenen Daten;
										Erforderlichkeit für den Vertragsabschluss; Verpflichtung der betroffenen Person, die
										personenbezogenen Daten bereitzustellen; mögliche Folgen der Nichtbereitstellung
									</b>
									<p>
										Wir klären Sie darüber auf, dass die Bereitstellung personenbezogener Daten zum Teil gesetzlich
										vorgeschrieben ist (z.B. Steuervorschriften) oder sich auch aus vertraglichen Regelungen (z.B.
										Angaben zum Vertragspartner) ergeben kann. Mitunter kann es zu einem Vertragsschluss erforderlich
										sein, dass eine betroffene Person uns personenbezogene Daten zur Verfügung stellt, die in der Folge
										durch uns verarbeitet werden müssen. Die betroffene Person ist beispielsweise verpflichtet uns
										personenbezogene Daten bereitzustellen, wenn unser Unternehmen mit ihr einen Vertrag abschließt.
										Eine Nichtbereitstellung der personenbezogenen Daten hätte zur Folge, dass der Vertrag mit dem
										Betroffenen nicht geschlossen werden könnte. Vor einer Bereitstellung personenbezogener Daten durch
										den Betroffenen muss sich der Betroffene an einen unserer Mitarbeiter wenden. Unser Mitarbeiter
										klärt den Betroffenen einzelfallbezogen darüber auf, ob die Bereitstellung der personenbezogenen
										Daten gesetzlich oder vertraglich vorgeschrieben oder für den Vertragsabschluss erforderlich ist, ob
										eine Verpflichtung besteht, die personenbezogenen Daten bereitzustellen, und welche Folgen die
										Nichtbereitstellung der personenbezogenen Daten hätte.
									</p>

									<b>16. Bestehen einer automatisierten Entscheidungsfindung</b>
									<p>
										Als verantwortungsbewusstes Unternehmen verzichten wir auf eine automatische Entscheidungsfindung
										oder ein Profiling.
									</p>
								</>
							)}
						</div>
					</div>
					<footer className="my-5 pt-5 text-muted text-center text-small">
						<p className="mb-1">&copy; 2019 Primed Marketing Agency GmbH, Wipplingerstraße 12/2/4, 1010 Wien, Austria</p>
						<ul className="list-inline">
							<li className="list-inline-item">
								<Link to="/privacy">Privacy</Link>
							</li>
							<li className="list-inline-item">
								<Link to="/terms">Terms</Link>
							</li>
							<li className="list-inline-item">
								<Link to="/contact">Support</Link>
							</li>
						</ul>
					</footer>
				</div>
			</React.Fragment>
		)
	}
}

export default Privacy
