import React from 'react'
import { withRouter } from 'react-router-dom'
import Cookies from 'js-cookie'
import { GrantsContext } from '@primedma/paymentpunks-react'

function AccountPage(props) {

    const whoAmI = React.useContext(GrantsContext)

  const [account, setAccount] = React.useState({
    metadata: {}
  })

  React.useEffect(() => {
    (async function() {
      let acc = await fetch(`/api/profile/account/${whoAmI.id}`)
      setAccount(await acc.json())
    })()
  })

  const ext_uid=account.metadata['external-uid']
  
  return <div className='container'>

    <div className='row'>
      Hello {account.name} ({account.email}) / {account.id}
      &nbsp; [{ (ext_uid && ext_uid.match(/(.+)\:/)[1]) || 'stripe'}]
    </div>
    <div className='row'>
      other details like address go here with update func 
    </div>
    <div className='row'>
      <div>
        <h4>Roles and Grants</h4>
        <p>{whoAmI.roles}</p>
        <p>{whoAmI.grants.join(', ')}</p>
      </div>
    </div>
  </div>

}

export default withRouter(AccountPage)
