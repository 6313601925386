import React from 'react'
import * as F from 'react-form-elements'
import { GrantsContext } from '@primedma/paymentpunks-react'
import { useLocation, Redirect } from 'react-router-dom'
import { Helmet } from "react-helmet"
import qs from 'query-string'

export function PasswordCB(props) {
  let ctx = React.useContext(GrantsContext)
  let parms = qs.parse(useLocation().search)

  let username = parms.username || ctx.username
  let userid = parms.userid || ctx.id

  let [state, setState] = React.useState("initial")
  let [nextStep, setNextStep] = React.useState(null)

  React.useEffect(() => {
    if (parms.source == 'hp') {
      window.fetch("/api/profile/cb/setpassword_from_hp?id=" + parms.id).then((res) => {
        res.json().then(data => {
          // coming from the OnboardCB component
          if (data.redirect_url) {
            setNextStep(data.redirect_url)
          }
          setState('success')
        })
      }).catch(e => {
        console.log(e)
        setState('error')
      })
    }
  })

  if (parms.source == 'hp') {
    return state == 'initial'
      ? <div>finishing subscription initialization, please wait...</div>
      : state == 'success'
        ? <div>
          <div>success</div>
          {!!nextStep ? <Redirect to={nextStep} /> : <span />}
        </div>
        : <div>unknown error</div>
  } else if (username && userid) {
    return <div className='container'>
      <span><b>for internal use only</b></span>
      <F.Form
        name="reset-password-form"
        onSubmit={async (values) => {
          let res = await fetch("/api/profile/cb/setpassword", {
            method: 'PUT',
            headers: { 'content-type': 'application/json' },
            body: JSON.stringify({
              custid: userid,
              username: username,
              password: values.password
            })
          })

          if (res.ok) {
            alert("Done")
          } else {
            alert('Error')
            console.error(res)
          }
        }} >
        <F.PasswordInput className="form-group" inputClassName="form-control" name="password" />
        <F.Button>Submit</F.Button>
      </F.Form>
    </div>
  } else {
    return <div>
      no user data provided
          </div>
  }
}

export function UpgradeSubscription(props) {
  // basic to silver, silver to pro, etc.

  const [refreshedGrants, setGrantsRefreshed] = React.useState(false)
  const whoAmI = React.useContext(GrantsContext)
  if (!refreshedGrants && whoAmI.refresh) {
    console.log('refreshing grants')
    setGrantsRefreshed(true)
    whoAmI.refresh()
  }

  const [subscriptions, setSubscriptions] = React.useState([])
  const [plans, setPlans] = React.useState([])

  React.useEffect(() => {
    (async function() {
      let plans = await (await fetch('/api/profile/cb/plans')).json()
      setPlans(plans)
    })()

    if (subscriptions.length == 0)
      (async () => {
        let subscriptionsReq = await fetch(`/api/profile/cb/subscriptions/${encodeURIComponent(whoAmI.username)}`)
        if (subscriptionsReq.ok) {
          let subs = await subscriptionsReq.json()
          console.log('UPDATING SUBSCRIPTIONS STATE', subscriptions, subs)
          setSubscriptions(subs)
        } else {
          console.log(subscriptionsReq)
        }
      })()
  }, [subscriptions.length])

  return <React.Fragment>
    <Helmet>
      <script src="https://js.chargebee.com/v2/chargebee.js" data-cb-site={process.env.REACT_APP_CHARGEBEE_SITE} ></script>
    </Helmet>

    <div className='container'>
      <h5>Change subscription plan. WARNING no business rule checks are done; downgrades are possible</h5>
      <div className='row'>
        <F.Form
          onSubmit={async (data) => {
            let checkoutHPResp = await fetch(`/api/profile/cb/upgrade_subscription/${data.sub_id}/infludata-pro`, { method: 'POST' })
            if (checkoutHPResp.ok) {
              let cb = window.Chargebee.init({ site: process.env.REACT_APP_CHARGEBEE_SITE })
              let hpo = await checkoutHPResp.json()
              cb.openCheckout({
                hostedPage: async () => { return hpo.hosted_page },
                success: (hpid) => {
                  console.log("upgrade finished, redirecting")
                  window.location.pathname = '/account-portal'
                }
              })
            }
          }}>
          <F.DropDown
            className="form-group"
            inputClassName="form-control"
            name="sub_id"
            id="sub_id"
            label='Subscription to upgarde' >
            {subscriptions
              .map((sub, idx) => {
                return <F.Option initialValue={sub.id} key={idx}>{sub.plan_id} / {sub.id}</F.Option>
              })}
          </F.DropDown>

          <F.DropDown
            className="form-group"
            inputClassName="form-control"
            name="plan_id"
            id="plan_id"
            label='New Plan' >
            {plans
              .map((plan, idx) => {
                return <F.Option initialValue={plan.id} key={idx}>{plan.name}</F.Option>
              })}
          </F.DropDown>

          <F.Button>Upgrade</F.Button>
        </F.Form>
      </div>
    </div>
  </React.Fragment >
}

export function CheckoutTrial(props) {
  // checks out trial plan for existing subscription (e.g. end the trial with payment)
  // see https://www.chargebee.com/checkout-portal-docs/checkout-existing-tutorial.html
  // and this which must be the same  https://apidocs.chargebee.com/docs/api/hosted_pages#checkout_existing_subscription 

  // the grants refresh is just example how to use this feature. not needed
  const [refreshedGrants, setGrantsRefreshed] = React.useState(false)
  const whoAmI = React.useContext(GrantsContext)
  if (!refreshedGrants && whoAmI.refresh) {
    console.log('refreshing grants')
    setGrantsRefreshed(true)
    whoAmI.refresh()
  }

  const [subscriptions, setSubscriptions] = React.useState([])

  React.useEffect(() => {
    if (subscriptions.length == 0)
      (async () => {
        let subscriptionsReq = await fetch(`/api/profile/cb/subscriptions/${encodeURIComponent(whoAmI.username)}`)
        if (subscriptionsReq.ok) {
          let subs = await subscriptionsReq.json()
          console.log('UPDATING SUBSCRIPTIONS STATE', subscriptions, subs)
          setSubscriptions(subs)
        } else {
          console.log(subscriptionsReq)
        }
      })()
  }, [subscriptions.length])

  return <React.Fragment>
    <Helmet>
      <script src="https://js.chargebee.com/v2/chargebee.js" data-cb-site={process.env.REACT_APP_CHARGEBEE_SITE} ></script>
    </Helmet>

    <div className='container'>
      <h5>Convert trial subscription to paying</h5>
      <div className='row'>
        <F.Form
          onSubmit={async (data) => {
            let checkoutHPResp = await fetch(`/api/profile/cb/checkout_trial/${data.sub_id}`, { method: 'POST' })
            if (checkoutHPResp.ok) {
              let cb = window.Chargebee.init({ site: process.env.REACT_APP_CHARGEBEE_SITE })
              let hpo = await checkoutHPResp.json()
              cb.openCheckout({
                hostedPage: async () => { return hpo.hosted_page },
                success: (hpid) => {
                  console.log("upgrade finished, redirecting")
                  window.location.pathname = '/account-portal'
                }
              })
            }
          }}>
          <F.DropDown
            className="form-group"
            inputClassName="form-control"
            name="sub_id"
            id="sub_id"
            label='Subscription to upgarde' >
            {subscriptions
              .filter(sub => sub.status == 'in_trial')
              .map((sub, idx) => {
                return <F.Option initialValue={sub.id} key={idx}>{sub.plan_id}</F.Option>
              })}
          </F.DropDown>

          <F.Button>Upgrade</F.Button>
        </F.Form>
      </div>
    </div>
  </React.Fragment >
}

export function OnboardCB(props) {

  return <React.Fragment>
    <Helmet>
      <script src="https://js.chargebee.com/v2/chargebee.js" data-cb-site={process.env.REACT_APP_CHARGEBEE_SITE} ></script>
    </Helmet>

    <div className='container'>
      <F.Form
        name="onboardForm"
        onSubmit={async (data) => {
          // this code is just for demonstration, do it any way you want
          // see https://www.chargebee.com/checkout-portal-docs/checkout-new-tutorial.html
          console.log(data)

          let emailTakenReq = await fetch(`/api/profile/cb/emailtaken/${encodeURIComponent(data.email)}`)
          if (emailTakenReq.ok) {
            let etresp = await emailTakenReq.json()
            if (etresp.subscribed) alert("INFO: customer exists already")
          }

          let cb = window.Chargebee.init({ site: process.env.REACT_APP_CHARGEBEE_SITE })

          let hpr = await fetch("/api/profile/cb/subscribe", {
            method: 'POST',
            headers: { 'content-type': 'application/json' },
            body: JSON.stringify({
              plan_id: data.plan_id,//"infludata-base",//"infludata-pro",
              first_name: data.name,
              email: data.email,
              company: data.business_name,
              // etc...
              // pass-through the password!
              success_url: `/password?source=hp`,
              pass_through: JSON.stringify({
                password: data.password,
                redirect_url: '/login?method=chargebee'
              })
            })
          })
          if (hpr.ok) {
            let hpo = await hpr.json()
            console.log('HPO', hpo)

            if (hpo.hosted_page) {
              cb.openCheckout({
                hostedPage: () => { return Promise.resolve(hpo.hosted_page) }
              })
            } else if (hpo.customer) {
              // this was free plan, no hosted page, we're done
              alert('done, redirect to success page')
            }
          } else {
            //error handler
            alert("Error")
          }
        }}>

        {/** TODO add here onChange check that hides password and other fields in case the e-mail is taken!  **/}

        <F.DropDown
          className="form-group"
          inputClassName="form-control "// is-invalid
          name="plan_id"
          id="plan_id"
          label='Plan'>
          <F.Option initialValue="infludata-free">Free</F.Option>
          <F.Option initialValue="infludata-advanced">Advanced</F.Option>
          <F.Option initialValue="infludata-pro">Pro</F.Option>
        </F.DropDown>

        <F.EmailInput
          className="form-group"
          inputClassName="form-control "// is-invalid
          name="email"
          id="email"
          aria-describedby="email-error"
          label='e-mail' >
          { /* <small id="email-error" className="form-text text-muted">example error</small> */}
        </F.EmailInput>

        <F.TextBox
          className="form-group"
          inputClassName="form-control"
          name="name"
          label="name" />

        <F.TextBox
          className="form-group"
          inputClassName="form-control"
          name="business_name"
          label="Business Name" />

        <F.Password
          className="form-group"
          inputClassName="form-control"
          name="password"
          label="Password (if new)" />

        <F.Button>Submit</F.Button>
      </F.Form>
    </div>

  </React.Fragment>
}
