import React, { Component } from 'react';
import Navbar from '../navbar';
import strings from '../languageStrings'
import { Link } from "react-router-dom";


class Contact extends Component {
    state = {  }
    render() { 
        let language = strings.getLanguage()
        return ( 
            <React.Fragment>
                <Navbar/>
                <div className="container">
					<div className="row">
						<div className="col">
							{language !== 'de' && (
								<>
                                    <h3 className="text-center">To contact us, please send a mail to <a href="mailto: support@onlinepunks.com">support@onlinepunks.com</a><br/><br/> Thank you!</h3>
                                </>
							)}
							{language == 'de' && (
								<>
									<h3 className="text-center">Sollten Sie Fragen zur Zahlung haben, können Sie sich gerne unter <a href="mailto: support@onlinepunks.com">support@onlinepunks.com</a> mit uns in Verbindung setzen.<br/><br/> Danke!</h3>
								</>
							)}
						</div>
					</div>
					<footer className="my-5 pt-5 text-muted text-center text-small">
						<p className="mb-1">&copy; 2019 Primed Marketing Agency GmbH, Wipplingerstraße 12/2/4, 1010 Wien, Austria</p>
						<ul className="list-inline">
							<li className="list-inline-item">
								<Link to="/privacy">Privacy</Link>
							</li>
							<li className="list-inline-item">
								<Link to="/terms">Terms</Link>
							</li>
							<li className="list-inline-item">
								<Link to="/contact">Support</Link>
							</li>
						</ul>
					</footer>
				</div>
            </React.Fragment>
         );
    }
}
 
export default Contact;