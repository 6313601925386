import React from 'react'
import { withRouter } from 'react-router'
import strings from './languageStrings'
import queryString from 'query-string'

function FormControl(props) {
  return (
    <div {...props}>
      <label htmlFor={props.id}>{props.label}</label>
      {props.children}
      <div id={`${props.id}-feedback`} className="invalid-feedback">
        {strings.invalid}
      </div>
    </div>
  )
}

class CreateUserForm extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      username: '',
      password: '',
      passwordRep: '',
    }

    this.username = queryString.parse(props.location.search).username || props.username || ''
  }

  render() {
    return <React.Fragment>
      <div className="row" >
        <div className="col-md-5">
          <h4 className="mb-3">{strings.userData}</h4>

          <FormControl id="username" label={'Username*'} className="mb-3">
            <input
              className="form-control"
              data-recurly="username"
              onChange={e => this.props.onChange(e, 'username')}
            />
          </FormControl>
        </div>
      </div >
      <div className="row">
        <div className="col-md-5">
          <FormControl id="password" label={strings.password + '*'} className="mb-3">
            <input
              className="form-control"
              type="password"
              data-recurly="password"
              onChange={e => this.props.onChange(e, 'password')}
            />
          </FormControl>
        </div>
      </div>
      <div className="row">
        <div className="col-md-5">
          <FormControl id="passwordRep" label={strings.passwordRepeat + '*'} className="mb-3">
            <input
              className="form-control"
              type="password"
              data-recurly="passwordRep"
              onChange={e => this.props.onChange(e, 'passwordRep')}
            />
          </FormControl>
        </div>
      </div>
      <div className="row">
        <div className="col">
          <hr className="mb-4" />
        </div>
      </div>
    </React.Fragment>

  }
}

export default withRouter(CreateUserForm)
