import React from 'react'
import qs from 'query-string'
import { Helmet } from 'react-helmet'
import { withRouter, Redirect } from 'react-router-dom'
import assert from 'assert'

// config for the reports.onlinepunks iframe tracking
assert(process.env.REACT_APP_FB_BIB_TY, `FB_BIB_TY env var not defined`)

// I could probably eliminate this. but it doesn't hurt
function FBPixel(props) {
	React.useEffect(() => {
		;(function(f, b, e, v, n, t, s) {
			if (f.fbq) return

			n = f.fbq = function() {
				n.callMethod ? n.callMethod.apply(n, arguments) : n.queue.push(arguments)
			}
			if (!f._fbq) f._fbq = n
			n.push = n
			n.loaded = !0
			n.version = '2.0'
			n.queue = []
			t = b.createElement(e)
			t.async = !0
			t.src = v
			s = b.getElementsByTagName(e)[0]
			s.parentNode.insertBefore(t, s)
		})(window, document, 'script', 'https://connect.facebook.net/en_US/fbevents.js')
		window.fbq('init', '962515540757933')
		window.fbq('track', 'PageView')
	})

	return (
		<React.Fragment>
			<noscript>
				<img
					height="1"
					width="1"
					style="display:none"
					src="https://www.facebook.com/tr?id=962515540757933&ev=PageView&noscript=1"
				/>
			</noscript>
		</React.Fragment>
	)
}

function MyHead(props) {
	return (
		<Helmet>
			{props.children}
			<meta charSet="utf-8" />
			<meta name="viewport" content="width=device-width, initial-scale=1, shrink-to-fit=no" />
			<meta name="description" content />
			{/* Favicons */}
			<link rel="apple-touch-icon" href="/docs/4.4/assets/img/favicons/apple-touch-icon.png" sizes="180x180" />
			<link rel="icon" href="icons/icon-48x48.png" sizes="48x48" type="image/png" />
			<link rel="icon" href="icons/icon-192x192.png" sizes="192x192" type="image/png" />
			<link rel="icon" href="icons/icon-48x48.png" sizes="48x48" type="image/png" />
			<link rel="shortcut icon" href="icons/icon-48x48.png" />
			<link
				rel="stylesheet"
				href="https://stackpath.bootstrapcdn.com/bootstrap/4.3.1/css/bootstrap.min.css"
				integrity="sha384-ggOyR0iXCbMQv3Xipma34MD+dH/1fQ784/j6cY/iJTQUOhcWr7x9JvoRxT2MZw1T"
				crossorigin="anonymous"
			/>

			<style
				dangerouslySetInnerHTML={{
					__html:
						'\n      .bd-placeholder-img {\n        font-size: 1.125rem;\n        text-anchor: middle;\n        -webkit-user-select: none;\n        -moz-user-select: none;\n        -ms-user-select: none;\n        user-select: none;\n      }\n\n      @media (min-width: 768px) {\n        .bd-placeholder-img-lg {\n          font-size: 3.5rem;\n        }\n      }\n    ',
				}}
			/>
		</Helmet>
	)
}

function Top() {
	return (
		<div
			className="d-flex flex-wrap align-items-center p-3 my-3 text-white-50 bg-black rounded shadow-sm"
			style={
				{
					/* backgroundColor: '#000000' */
				}
			}
		>
			<img className="mr-3 img-fluid" src="OP_Logo_FB_electric.png" alt="" />
			<div className="lh-100">
				{/*       <h6 className="mb-0 text-white lh-100"></h6>
                    <small>Social Media Knowledge since 2019</small> */}
			</div>
		</div>
	)
}

function DownloadLineItem(props) {
	let { item, icon, sub_line, invoiceid, custid, file_name } = props

	return (
		<React.Fragment key={'download-' + item.id}>
			<div className="media text-muted pt-3">
				<div className="media-body pb-3 mb-0 small lh-125 border-bottom border-gray d-flex justify-content-between align-items-center w-100">
					<div className="column m-1 mx-2">
						<img src={icon || ''} />
					</div>
					<div className="column w-100 px-4 ">
						<strong className="text-gray-dark row">{item.name || item.invoice_name}</strong>
						<span className=" row"> {sub_line || ''} </span>
					</div>
					<a
						className="column"
						href={`/api/profile/chargebee-checkout/download?purchaseRef=${invoiceid}&customerId=${custid}&item=${item.id}`}
						download={`${file_name}.pdf`}
					>
						Download
					</a>
				</div>
			</div>
		</React.Fragment>
	)
}

function MessageLineItem(props) {
	let { icon, item, sub_line, message } = props
	return (
		<React.Fragment key={'download-' + item.id}>
			<div className="media text-muted pt-3">
				<div className="media-body pb-3 mb-0 small lh-125 border-bottom border-gray d-flex justify-content-between align-items-center w-100">
					<div className="column m-1 mx-2">
						<img src={icon || ''} />
					</div>
					<div className="column w-100 px-4 ">
						<strong className="text-gray-dark row">{item.name || item.invoice_name}</strong>
						<span className=" row"> {sub_line || ''} </span>
					</div>
					<span className="column text-nowrap">{message}</span>
				</div>
			</div>
		</React.Fragment>
	)
}

export const CbBuySuccess = withRouter(props => {
	const checkoutSessionId = qs.parse(window.location.search).session_id

	// use subscription id for download verification because invoice numbers are not random and can be guessed
	const { invoiceid, subid, custid } = qs.parse(window.location.search)
	const [authorizedDownloads, setDownloads] = React.useState(null)

	React.useEffect(() => {
		;(async () => {
			let authorization = await fetch(`/api/profile/chargebee-checkout/authorize-download?invoiceid=${invoiceid}`)
			if (authorization.ok) {
				setDownloads(await authorization.json())
			} else {
				console.error('could not authorize download', authorization)
			}
		})()
	}, [])

	let totalDiscount =
		(authorizedDownloads &&
			authorizedDownloads.line_item_discounts &&
			authorizedDownloads.line_item_discounts.reduce((total, lid) => total + lid.discount_amount, 0)) ||
		0
	let skus =
		authorizedDownloads &&
		authorizedDownloads.line_items
			//.filter(li => li.type == 'addon')
			.map(li => ({ id: encodeURIComponent(li.entity_id), price: li.amount, discount: li.discount_amount }))
	let skusParmStr = skus && skus.map(sk => `sku=${sk.id}&price=${sk.price}`).join('&')
	console.error(skus, skusParmStr)
	let baseCurrency = authorizedDownloads && authorizedDownloads.currency_code

	return (
		<React.Fragment>
			<MyHead>
				<title>OnlinePunks - Payment Success</title>
			</MyHead>
			<div className="container" id="success-root">
				<Top />
				<div className="m-3" id="success-downloads">
					{authorizedDownloads == null ? (
						<React.Fragment key="thank-you">
							<h3>Thank you for your purchase!</h3>
							<p>We are processing your payment and preparing the download... </p>
						</React.Fragment>
					) : null}

					{authorizedDownloads ? (
						<React.Fragment>
							<div className="my-3 p-3 bg-white rounded shadow-sm">
								<h6 className="border-bottom border-gray pb-2 mb-0">Downloads</h6>
								{authorizedDownloads.addOns.map(item => {
									let metadata = item.meta_data || {}
									let metaui = metadata.ui || {}
									// I could probably use discription for sub_line but better avoid someone messing it up by mistake
									let { sub_line, icon } = metaui
									let file_name = (item.meta_data && item.meta_data.file_name) || item.description

									if (metadata.presale) {
										return (
											<MessageLineItem item={item} icon={icon} sub_line={sub_line} message={metadata.presale.message} />
										)
									} else {
										return (
											<DownloadLineItem
												item={item}
												icon={icon}
												file_name={file_name}
												sub_line={sub_line}
												invoiceid={invoiceid}
												custid={custid}
											/>
										)
									}
								})}
								{/* load the iframe once for all items */}
								<iframe
									src={`${
										process.env.REACT_APP_FB_BIB_TY
									}?${skusParmStr}&orderId=${subid}&currency=${baseCurrency}&totalDiscount=${totalDiscount || 0}`}
									style={{ display: 'none', width: 0, height: 0 }}
								/>
							</div>
						</React.Fragment>
					) : null}
				</div>
			</div>
			<FBPixel />
		</React.Fragment>
	)
})
