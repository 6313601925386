import React from 'react'
import { withRouter } from 'react-router'
import strings from './languageStrings'
import OrderPreview from './OrderPreview'
import RecurlyContainer, { RecurlyCC, AdyenSEPA } from './RecurlyContainer'
import {
	ValidationError,
	ItemError,
	mark_form_errors,
	reset_form_errors,
	validate_form_data,
	FormValidationError,
} from '../validation'
import md5 from 'md5'
import queryString from 'query-string'
import { Link, BrowserRouter } from 'react-router-dom'
import CreateUserForm from './CreateUserForm'
import short_uuid from 'short-uuid'
import assert from 'assert'

/**
 * DO NOT do validation via bootstrap's 'required' and so on because it's better to configure that in recurly's backend.
 * It provides detailed feedback anyway so why duplicate the logic?
 */

function get_input_value(elem) {
	if (elem.getAttribute('type') == 'checkbox') {
		return elem.checked
	} else {
		return (elem.value != '' && elem.value) || null
	}
}

/**
 * @returns {object}
 */
function get_form_data(formRef) {
	let inputs = [...formRef.current.getElementsByTagName('input'), ...formRef.current.getElementsByTagName('select')]

	let data = inputs.reduce((res, i) => {
		let key = (i.attributes['data-recurly'] || {}).value
		if (key && get_input_value(i)) res[key] = get_input_value(i)
		return res
	}, {})

	return data
}

function FormControl(props) {
	return (
		<div {...props}>
			<label htmlFor={props.id}>{props.label}</label>
			{props.children}
			<div id={`${props.id}-feedback`} className="invalid-feedback">
				{strings.invalid}
			</div>
		</div>
	)
}

const CountryOptions = (
	<FormControl id="country" label={strings.country + '*'} className="mb-3">
		<select data-recurly="country" className="custom-select d-block w-100">
			<option value="">{strings.choose}</option>
			{strings.countries.map(country => (
				<option value={country.id} key={country.id}>
					{country.value}
				</option>
			))}
		</select>
	</FormControl>
)

const FirstName = (
	<FormControl id="first_name" label={strings.firstname + '*'} className="mb-3">
		<input className="form-control" data-recurly="first_name" />
	</FormControl>
)

const LastName = (
	<FormControl id="last_name" label={strings.lastname + '*'} className="mb-3">
		<input className="form-control" data-recurly="last_name" />
	</FormControl>
)

const Password = (
	<FormControl id="password" label={strings.password + '*'} className="mb-3">
		<input className="form-control" type="password" data-recurly="password" />
	</FormControl>
)

const PasswordRep = (
	<FormControl id="password" label={strings.password + '*'} className="mb-3">
		<input className="form-control" type="password" data-recurly="passwordRep" />
	</FormControl>
)

const Zip = (
	<FormControl id="postal_code" label={strings.zip + '*'} className="mb-3">
		<input className="form-control" data-recurly="postal_code" />
	</FormControl>
)

const InstagramUsername = (
	<FormControl id="username" label="Instagram Username*" className="mb-3">
		<div className="input-group">
			<div className="input-group-prepend">
				<span className="input-group-text">@</span>
			</div>
			<input type="text" className="form-control" id="username" placeholder="Username" data-recurly="username" />
			{/* must be explicit  here because of the nesting  */}
			<div id={`username-feedback`} className="invalid-feedback">
				{strings.invalid}
			</div>
		</div>
	</FormControl>
)

const Email = (
	<FormControl id="email" label="Email*" className="mb-3">
		<input type="email" className="form-control" id="email" placeholder="you@example.com" data-recurly="email" />
	</FormControl>
)

const City = (
	<FormControl id="city" label={strings.city + '*'} className="mb-3">
		<input className="form-control" data-recurly="city" />
	</FormControl>
)

const VATId = (
	<FormControl id="vat_number" label={strings.vatId} className="mb-3">
		<input className="form-control" id="VATId" data-recurly="vat_number" />
	</FormControl>
)

const company = (
	<FormControl id="company" label={strings.company} className="mb-3">
		<input className="form-control" id="company" data-recurly="company" />
	</FormControl>
)

const Street1 = (
	<FormControl id="address1" label={strings.street + '*'} className="mb-3">
		<input type="text" className="form-control" id="street" placeholder="" data-recurly="address1" />
	</FormControl>
)

const NameAddressForm = (
	<React.Fragment>
		<div className="row">
			<div className="col">{company}</div>
		</div>
		<div className="row">
			<div className="col">{FirstName}</div>
			<div className="col">{LastName}</div>
		</div>

		<div className="row">
			<div className="col">{Street1}</div>
		</div>
		<div className="row">
			<div className="col">{City}</div>
			<div className="col">{Zip}</div>
		</div>
		<div className="row">
			<div className="col">{CountryOptions}</div>
		</div>
		<div className="row">
			<div className="col">{VATId}</div>
		</div>
	</React.Fragment>
)

class PaymentForm extends React.Component {
	//
	state = {
		values: {},
		misc: {
			'payment-option': 'cc',
			//'terms': false
		},
	}

	formRef = React.createRef()
	userFormRef = React.createRef()

	constructor(props) {
		super(props)

		// that's practically static so it needs no go to the state
		this.plan_code = queryString.parse(props.location.search).plan_code || props.plan_code || 'test2'

		this.session_id = short_uuid.generate()
		this.cid = null
	}

	async componentDidMount() {
		let planCodes = await (await fetch('/api/profile/plans')).json()
		if (planCodes.ok == false) {
			throw planCodes
		} else {
			this.plans = planCodes

			let myPlan = planCodes.find(p => p.id == this.plan_code)
			if (myPlan != null) {
				this.plan_object = myPlan

				let sub = await this.upsertSubscription({
					__complete__: false,
					email: `preview.${short_uuid.generate()}@nil.paymentpunks.com`,
				})
				console.log('== SUB ==', sub.body.session_id, sub.body.customer.id)
				if (!sub.ok) {
					console.error(sub)
					this.setState({ error: { message: 'unexpected technical problem. please refresh or try later' } })
				}

				this.setState({ loaded: true })
			} else {
				this.setState({ error: { message: 'plan code not valid' } })
			}
		}
	}

	/**  @param {string} token */
	onToken = token => {
		console.log('-- token --', token)
		this.props.history.push('/success')
	}

	async upsertSubscription(formData) {
		let res = this.cid != null ? await this.updateSubscription(formData) : await this.createSubscription(formData)
		if (!res.ok && res.type == 'validation-error') {
			let langStr = strings.errors[res.details[0].code] || strings.invalidFormat
			throw new FormValidationError([new ItemError(res.details[0].subject, [langStr])])
		} else if (!res.ok) {
			throw new Error(JSON.stringify(res, null, 2))
		} else {
			return res
		}
	}

	async createSubscription(formData) {
		let input = formData
		input.plan_code = this.plan_code
		input.session_id = this.session_id
		try {
			let subs = await fetch(`/api/profile/subscription`, {
				method: 'POST',
				headers: { 'content-type': 'application/json' },
				body: JSON.stringify(input),
			})

			let body = await subs.json()
			if (body.ok && this.cid == null) {
				assert(body.body.customer.id)
				this.cid = body.body.customer.id
			} else if (body.cid && this.cid == null) {
				// when error but cid exists
				this.cid = body.cid
			}

			return body
		} catch (e) {
			console.error(e)
			return { ok: false, type: 'technical-error', message: e.message }
		}
	}

	// TODO delete preview subscriptions somewhere...

	async updateSubscription(formData) {
		let input = formData
		input.plan_code = this.plan_code
		input.session_id = this.session_id
		try {
			let subs = await fetch(`/api/profile/subscription/${this.cid}`, {
				method: 'PUT',
				headers: { 'content-type': 'application/json' },
				body: JSON.stringify(input),
			})

			let body = await subs.json()
			if (body.ok) {
				assert(
					body.body.customer.id == this.cid,
					`customer id is wrong. expected ${this.cid} but got ${body.body.customer.id}`
				)
			}

			return body
		} catch (e) {
			console.error(e)
			return { ok: false, type: 'technical-error', message: e.message }
		}
	}

	/** @param  {ValidationError} error */
	onError(error) {
		console.log('--error--', error)
		reset_form_errors()
		mark_form_errors(error)
	}

	onChange = (e, id) => {
		//console.log(e.target.value)
		this.setState({
			[id]: e.target.value,
		})
		// console.log(this.formRef, this.userFormRef)
	}

	render() {
		console.log('render', this.state.values, this.state.error)
		if (this.state.error) {
			return (
				<React.Fragment>
					<div id="order_error" className="container">
						<p className="text-center">The selected plan code is not valid. Please contact support.</p>
					</div>
				</React.Fragment>
			)
		} else if (!this.state.loaded) {
			return (
				<div className="container">
					<h3>loading ...</h3>
				</div>
			)
		} else {
			return (
				<React.Fragment>
					<form
						id="paymentForm"
						ref={this.formRef}
						onChange={e => {
							// note the input elements are not really managed.
							// I could skip this.setState and form would still work
							let data_name = e.target.attributes['data-recurly'],
								fname = data_name && data_name.value,
								fvalue = e.target.value

							if (data_name)
								this.setState({
									values: Object.assign(this.state.values, { [fname]: fvalue }),
								})
							else
								this.setState({
									misc: Object.assign(this.state.misc || {}, { [e.target.name]: e.target.value }),
								})
						}}
						onSubmit={e => {
							console.log('payment data submit form', this.formRef.current)
							e.preventDefault()
							console.error(
								'this form is not supposed to be submitted at the moment. the payment controls do soft submit and call onToken'
							)
							//this.onSubmit()
						}}
					>
						{/* 					<CreateUserForm onChange={(e, id) => this.onChange(e, id)} setRef={this.userFormRef} />*/}

						<div className="row">
							<div className="col-md-4 order-md-2 mb-4">
								<h4 className="d-flex justify-content-between align-items-center mb-3">
									<span className="text-muted">{strings.order}</span>
								</h4>

								<OrderPreview
									cid={this.cid}
									session={this.session_id}
									country={this.state.values.country}
									vat_number={this.state.values.vat_number}
									planCode={this.plan_code}
									coupon={this.state.values.coupon}
									plans={this.plans}
								/>
							</div>
							<div className="col-md-8 order-md-1">
								{/*
                 ----- account data ----- 
               */}
								<h4 className="mb-3">{strings.userData}</h4>

								<div className="row">
									<div className="col">{Email}</div>
								</div>

								<div className="row">
									<div className="col">{Password}</div>
									<div className="col">{PasswordRep}</div>
								</div>
								{/*
                 ----- billing data ----- 
               */}
								<h4 className="mb-3">{strings.billingAdress}</h4>

								{NameAddressForm}

								{/* <div className="row">
									<div className="col-md-6 mb-3">
										<FormControl label={strings.coupon} id="coupon">
											<input className="form-control" data-recurly="coupon" />
										</FormControl>
									</div>
								</div> */}
								<div
									className="mb-5"
									style={{
										display: 'flex',
										flexDirection: 'row',
										justifyContent: 'flex-start',
										flexWrap: 'wrap',
									}}
								>
									{/* the default FormControl layout looks ugly, do it manually */}
									<input
										style={{ width: '1em' }}
										type="checkbox"
										name="terms"
										className="form-control p-2"
										data-recurly="terms"
									/>
									<span style={{ display: 'inline-flex', alignItems: 'center', minWidth: '90%' }} className="p-1">
										{strings.terms}*
									</span>
									<div id="terms-feedback" style={{}} className="invalid-feedback">
										Please enter valid input
									</div>
								</div>

								{/* ----- here are shown all errors that are not field-specific -------- */}

								<input type="hidden" name="recurly-token" data-recurly="token" className="form-control" />
								{/* place  for global error feedback. coupled to the token 
							   if you want  to move, move TOGETHER WITH THE TOKEN INPUT ELEMENT */}
								<p id="token-feedback" className="invalid-feedback mt-3 h2">
									{strings.unknown_error}
								</p>

								{/* ----- submit -------- */}
								{!this.state.register_success == true ? (
									<button
                                        id="submit"
                                        className="btn btn-primary"
										onClick={async event => {
											event.preventDefault()
                                            document.getElementById('submit').disabled = true
											
                                            try {
												reset_form_errors()
												let formData = get_form_data(this.formRef)
												console.log('formData', formData)
                                                validate_form_data(formData)
                                                
                                                document.getElementById('submit').insertAdjacentHTML('afterend', '<div id="spinner" class="spinner-border" role="status" style="margin-left: 12px; margin-bottom: -9px; border-color: #c7c7c7; border-right-color: transparent;"><span class="sr-only">Loading...</span></div>')

												let res = await this.upsertSubscription(Object.assign({ __complete__: true }, formData))
												if (res.ok) {
                                                    document.getElementById('spinner').remove();
													this.setState({ register_success: true, invoice_url: res.body.invoice.hosted_invoice_url })
												} else {
													console.error(res)
                                                    document.getElementById('spinner').remove();
                                                    throw new Error('unhandled exception')
												}
											} catch (e) {
                                                document.getElementById('submit').disabled = false
                                                if(document.getElementById('spinner')) {
                                                    document.getElementById('spinner').remove();
                                                }
												if (e instanceof ValidationError) this.onError(e)
												else throw e
											}
										}}
									>
                                        {strings.submitNext}
									</button>
								) : (
									<React.Fragment>
										<h3 className="mb-3">{strings.successHeader}</h3>
										<span>{strings.successBody}</span>
										<br />
										<a href={this.state.invoice_url} id="invoice-link" style={{display: "block", margin: "12px", marginLeft: "0px"}}>
											{strings.payInvoiceLink}
										</a>
									</React.Fragment>
								)}

								<hr className="mb-4" />
							</div>
						</div>
					</form>

					<div>
						<span>*{strings.required}</span>
					</div>
				</React.Fragment>
			)
		}
	}
}

export default withRouter(PaymentForm)
