import React from 'react'
import { BrowserRouter, Route, Link, Switch, useRouteMatch, withRouter } from "react-router-dom";
import PAvatar from './components/ProfileAvatar'
import AccountPage from "./AccountPage"

import PurchaseHistory from './PurchaseHistory'

export default (props) => {

  //let match = useRouteMatch()
  let match = { path: "/account-portal" }
  console.log(match, props)

  return <div className='container'>
    <div className='d-flex flex-row justify-content-between'>
      <span className=''>
        <Link className='col-md-auto' to={`${match.path}/`}>Home</Link>
        <Link className='col-md-auto' to={`${match.path}/purchases`}>Purchase History</Link>
      </span>
      <span className=''>
        <Link className='col-md-auto' to={`/upgrade`}>upgrade subscription</Link>
        <Link className='col-md-auto' to={`/upgrade_trial`}>upgrade trial</Link>
        <Link className='col-md-auto' to={`${match.path}/account`}>my profile</Link>
        <Link className='col-md-auto' to={`/password`}>password</Link>
        <Link className='col-md-auto' to={`/login`}>logout</Link>
        <span className='col-md-auto'><PAvatar /></span>
      </span>
    </div>
    <div className='container md-4'>
      <Switch>
        <Route path={`${match.path}/account`} component={AccountPage} />
        <Route path={`${match.path}/purchases`} component={PurchaseHistory} />
      </Switch>
    </div>
  </div>
}
