// Todo redirect - thank you page or something like this
import React, { Component } from 'react';
import Navbar from './navbar';
import strings from './languageStrings';


class AfterOrderRedirect extends Component {
    render() { 
        return ( 
            <React.Fragment>
                <Navbar/>
                <div className= "container">
                    <div className="text-center mb-3">
                        <h1>{strings.successHeader}</h1>
                    </div>
                    <div className="ml-3 mr-3">
                        <div className="text-center">
                            <h3>{strings.successBody}</h3>
                        </div>
                    </div>
                </div>
            </React.Fragment> 
         );
    }
}
 
export default AfterOrderRedirect;