import validate from 'validate.js'
import strings from './components/languageStrings'
import assert from 'assert'

export class ItemError {
	/**
	 * @param {string} name
	 * @param  {string[]} messages
	 */
	constructor(name, messages) {
		this.name = name
		assert(Array.isArray(messages), 'ItemError messages must be array')
		this.messages = messages
	}

	/** @type {string} */
	name

	/**  @type {string[]} */
	messages
}

/**
 * base for all form/input errors
 */
export class ValidationError extends Error {
	constructor(err) {
		/* no need for elaborate message as this exception must never pop-up */
		super('error in form input data')
		this.message = 'error in form input data\n' + JSON.stringify(err)
	}

	/** @type {string} */
	source

	/** @type{ItemError[]} */
	errors
}
ValidationError.prototype.name = 'ValidationError'

/**
 * @callback onError
 * @param {ValidationError} error
 */

export class FormValidationError extends ValidationError {
	/**
	 * @param {ItemError[]} errors
	 */
	constructor(errors) {
		super(errors)
		this.source = 'form-validation'
		this.errors = errors
		console.log('---', errors)
		this.errors.push(new ItemError('token', [strings.pleaseCheckInput]))
	}
}
FormValidationError.prototype.name = 'FormValidationError'

/**
 * raised by recurly.js itself
 */
export class RecurlyJsError extends ValidationError {
	constructor(err) {
		super(err)
		let { name, details } = err

		try {
			assert(name == 'validation' || name == 'api-error')
			assert(details != null)
			assert(details.length > 0)
		} catch (e) {
			console.error(e)
			this.errors = [new ItemError('token', ['unknown error'])]
		}

		this.source = 'recurly.js'
		// serve the errors  one by  one
		let e = details[0]
		this.errors = [new ItemError('card', [`${e.field} ${e.messages[0]}`])]
	}
}
RecurlyJsError.prototype.name = 'RecurlyJsError'

/**
 * raised by recurly.adyen
 * haven't managed to trigger it
 */
export class AdyenError extends ValidationError {
	constructor(err) {
		super(err)

		this.source = 'adyen'
		this.cause = err
		this.errors = [new ItemError('token', [strings.unknown_error])]
	}
}
AdyenError.prototype.name = 'AdyenError'

/**
 * raised when subscirbing with adyen token this also should be considered "internal server error"  as under normal conditions will never show up
 * the spectrum of possible  errors here is  not well defined. Whatever I've seen from experiments I'll handle explicitly
 * I'll ignore explicit handling of 'missing field' errors because that's caught by form  validation  and would pop up here only in case of bug
 */
export class AdyenSubscriptionError extends ValidationError {
	static fields = ['coupon']

	constructor(err) {
		super(err)
		this.source = 'subscribe'
		this.cause = err

		//"details":[{"message":"You already have a subscription to this plan.","field":"purchase.subscriptions[0].base"}]
		if (err.details && err.details.find(e => e.message == 'You already have a subscription to this plan.')) {
			this.errors = [new ItemError('token', [strings.already_subscribed])]
		} else if (err.details && err.details.find(e => AdyenSubscriptionError.fields.includes(e.field))) {
			this.errors = err.details
				.filter(e => AdyenSubscriptionError.fields.includes(e.field))
				.map(e => {
					return new ItemError(e.field, [strings.invalid])
				})
		} else {
			console.error('unknown error', err)
			this.errors = [new ItemError('token', [strings.unknown_error])]
		}
	}
}
AdyenSubscriptionError.prototype.name = 'AdyenSubscriptionError'

/**
 *
 */
export class CCSubscriptionError extends AdyenSubscriptionError {
	constructor(err) {
		super(err)
	}
}
CCSubscriptionError.prototype.name = 'CCSubscriptionError'

const required_validator = field_name => {
	return {
		allowEmpty: false,
		message: `^${field_name} ${strings.required}`,
	}
}

validate.validators.equalsValue = function(value, options, key, attributes) {
	if (value != options.value) {
		return [`^${options.message}`]
	}
}

const form_validators = {
	company: {},
	first_name: {
		presence: required_validator(strings.firstname),
	},
	last_name: {
		presence: required_validator(strings.lastname),
	},
	password: {
        presence: required_validator(strings.password),
        length: {
            minimum: 8,
            maximum: 30,
            tooShort: strings.passwordTooShort,
            tooLong: strings.passwordTooLong,
        },
	},
	passwordRep: {
		equality: { message: strings.passwordRepeat, attribute: 'password' },
	},
	email: {
		presence: required_validator(strings.email),
		email: {
			message: `^${strings.email} ${strings.invalidFormat}`,
		},
	},
	address1: {
		presence: required_validator(strings.street),
	},
	city: {
		presence: required_validator(strings.city),
	},
	postal_code: {
		presence: required_validator(strings.zip),
	},
	vat_number: {},
	country: {
		presence: required_validator(strings.country),
	},
	terms: {
		equalsValue: { message: strings.please_accept_terms, value: true },
	},
}

/**
 * @throws {ValidationError}
 */
export function validate_form_data(data) {
	let errors = validate(data, form_validators, {})
	if (errors) {
		throw new FormValidationError(Object.entries(errors).map(([k, v]) => new ItemError(k, v)))
	} else return null
}

export function reset_form_errors() {
	document.querySelectorAll('[data-recurly]').forEach(elm => {
		let eclass = elm.getAttribute('class') || ''
		if (eclass.includes('is-invalid')) {
			elm.setAttribute('class', eclass.replace('is-invalid', '').trim())
		}
		// optionally append is-valid class to mark all fields green
	})
}

/** @param {ValidationError} e*/
export function mark_form_errors(e) {
	if (e.errors) {
		e.errors.forEach(e => {
			console.log('marking error', e.name, e)
			let elm = document.querySelector(`[data-recurly='${e.name}']`)
			//console.log(elm)
			if (elm == null) {
				console.error("can't find error input element", e.name)
			} else {
				// if message is missing, then probably there are duplicated tags
				let eclass = elm.getAttribute('class') || ''
				elm.setAttribute('class', (eclass + ' is-invalid').trim())
			}
			let msge = document.getElementById(`${e.name}-feedback`)
			if (msge == null) {
				console.error("can't find error feedback element", e.name)
			} else {
				//console.log(msge,e.messages[0],msge.textContent)
				// if >1 message makes no sense to show all. order validators properly
				msge.textContent = e.messages[0]
			}
		})
	}
}
