import React from 'react'
import PP, { GrantsContext } from '@primedma/paymentpunks-react'

console.log(PP,GrantsContext)

export default function(children, props) {

  let ctx = React.useContext(GrantsContext)

  return <span>
    <span>Hello {ctx.username}</span>
  </span>

}



