import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import strings from './languageStrings'
import md5 from 'md5'
import queryString from 'query-string'
import assert from 'assert'
import {
  validate_form_data,
  mark_form_errors,
  reset_form_errors,
  ValidationError,
  FormValidationError,
  RecurlyJsError,
  CCSubscriptionError,
  AdyenError,
  AdyenSubscriptionError,
} from '../validation'

//required for cyprress  to be able to itercept fetch
// see https://github.com/cypress-io/cypress/issues/95
import { fetch as polyFetch } from 'whatwg-fetch'
//const fetch = null
//delete window.fetch

/**
 * this is wrapper component for buildeng recurly payment UI. It does nothing more
 * than to initialize and destroy the recurly binding to the UI elements. You need
 * to provide the UI (single | multiple fields) yourself as children in the usual
 * way explained in the recurly documentation.
 *
 * WARNING: the children must be 100% static elements. Having React elements as children will probably not work
 *
 * WARNING: this component will call recurly.configure() so you can have only one inctance of this component mounted in the page at a time
 *
 * I'm handling all the events because if the developer misses to set unique key to each form variation React will instantiate only one copy of the component and change it's children for the respective variant being activated. This is regardless if the component is Pure
 *
 * */

function reset_recurly() {
  let recurly = window.recurly
  try {
    recurly.destroy()
    recurly.hostedFields.destroy()
    recurly.hostedFields = null
    recurly.readyState = 0
  } catch (e) {
    console.error(e)
  }
}

export default React.memo(function RecurlyContainer(props) {
  useEffect(() => {
    window.recurly.configure(process.env.REACT_APP_RECURLYJS_TOKEN)
    return () => {
      reset_recurly()
    }
  })

  return <React.Fragment>{props.children}</React.Fragment>
})

function get_input_value(elem) {
  if (elem.getAttribute('type') == 'checkbox') {
    return elem.checked
  } else {
    return elem.value
  }
}

/**
 * @returns {object}
 */
function get_form_data(formRef) {

  let inputs = [...formRef.current.getElementsByTagName('input'),
  ...formRef.current.getElementsByTagName('select')]

  let data = inputs.reduce((res, i) => {
    let key = (i.attributes['data-recurly'] || {}).value
    return key ? Object.assign(res, { [key]: get_input_value(i) }) : res
  }, {})

  return data
}


function create_basic_request(inputs) {
  let payload = {
    username: inputs.username,
    password: inputs.password,
    // TODO make dynamic maybe?
    currency: 'EUR',

    email: inputs.email,
    first_name: inputs.first_name,
    last_name: inputs.last_name,

    company: inputs.company,

    country: inputs.country,
    address1: inputs.address1,
    city: inputs.city,
    zip: inputs.postal_code,

    coupon: inputs.coupon,

    vat_number: inputs.vat_number,
  }

  return payload
}

const RecurlyCC = React.memo(({ formRef, onToken, onError, planCode, formUser }) => {

  let subscribe_with_cc = async (token) => {
    let inputs = get_form_data(formRef) // no need to validate. done already
    let uid = md5(inputs.email)
    let payload = Object.assign(create_basic_request(inputs),
      {
        plan_code: planCode,
        token: token.id,
      })

    console.log('CC subscribing...', inputs, payload)

    // TODO up to this point the code is the same for both providers

    let resp = polyFetch(`/api/profile/${uid}/subscription?provider=recurly`, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(payload),
    })
    return resp
  }

  return (
    <React.Fragment>
      <span>
        {/* the extra  span ^^ is needed because I seem to hit a bug in bootstrap where token's invalidity causes the error  bellow to be shown too */}

        <div
          className="form-control pr-0 border-0 card-wrapper"
          style={
            // this trickery is needed  because the injected recurly div has top padding
            {
              height: '2em',
              paddingRight: 'calc(1.5em + 0.75em',
              paddingLeft: '0px',
              paddingTop: '0px',
              paddingBottom: '0px',
            }
          }
          data-recurly="card"
        />
        <div id="card-feedback" className="invalid-feedback" />
      </span>
      <br />
      <button
        className="btn btn-primary btn-lg btn-block"
        type="submit"
        onClick={e => {
          e.preventDefault()

          try {
            reset_form_errors()
            let formData = get_form_data(formRef)
            console.log('formData', formData)
            validate_form_data(formData)

            window.recurly.token(formRef.current, async (err, token) => {
              // can't throw here
              if (err) {
                onError(new RecurlyJsError(err))
              } else {
                let sub_resp = await subscribe_with_cc(token)
                let sub_res = await sub_resp.json()
                if (sub_resp.ok) {
                  onToken(sub_res)
                } else {
                  onError(new CCSubscriptionError(sub_res))
                }
              }
            })
          } catch (e) {
            if (e instanceof ValidationError) onError(e)
            else throw e
          }
        }}
      >
        {strings.proceedCC}
      </button>
    </React.Fragment>
  )
})
export { RecurlyCC }

export class AdyenSEPA extends React.PureComponent {
  static propTypes = {
    planCode: PropTypes.string.isRequired,
    onError: PropTypes.func.isRequired,
    onToken: PropTypes.func.isRequired,
    formRef: PropTypes.any.isRequired,
  }

  ifRef = React.createRef()

  componentDidMount() {
    // test code, only to be used for integration tests. integrates the adyen pop-up in iframe
    let is_in_test_mode = queryString.parse(window.location.search)['test_mode']
    if (is_in_test_mode) {
      let wo = window.open;
      window.open = (...args) => {
        console.log('---------------', args)
        let newe = document.createElement('div')
        let but = document.getElementById('adyen-subscribe')
        but.parentNode.insertBefore(newe, but)
        newe.innerHTML = `<iframe id="captive-frame" src=${'about:blank'} name=${args[1]}
sandbox="allow-forms allow-same-origin allow-scripts"
height="600px" ></iframe>`

        newe.children[0].addEventListener('load', function() {
          console.log('iframe navigate', this)

          let theFirstForm = this.contentDocument.querySelector('form[name="adyenForm"]')
          if (theFirstForm) {
            // decouple just in case
            setTimeout(() => {
              console.log('YAY')
              theFirstForm.target = '_self'
              theFirstForm.submit()
            }, 100)
          }
        })
        wo.apply(window, args)
      }
    }
    // end of  test code
    // production code

    let errorHandler = err => {
      this.props.onError(new AdyenError(err))
    }

    this.adyen = window.recurly.Adyen({})

    // I'm not sure which are valid error events. Have read the code and put anything that made sense
    this.adyen.on('error', errorHandler)
    this.adyen.on('validation', errorHandler)
    this.adyen.on('adyen-error', errorHandler)

    this.adyen.on('token', this.props.onToken)

    // this seems to be called never
    this.adyen.on('done', function(response) {
      alert('adyen done ' + response)
    })
  }

  async create_subscription(inputs) {
    let uid = md5(inputs.email)
    let payload = Object.assign(create_basic_request(inputs),
      {
        plan_code: this.props.planCode,
      })

    console.log(inputs, payload)

    let resp = polyFetch(`/api/profile/${uid}/subscription`, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(payload),
    })

    return resp
  }

  submit = async e => {
    // TODO this disables the bootstrap form verification
    // not 100% confident I want to use it anyway
    e.preventDefault()

    try {
      reset_form_errors()
      let inputs = get_form_data(this.props.formRef)

      validate_form_data(inputs)

      let sub_resp = await this.create_subscription(inputs)
      let sub_res = await sub_resp.json()

      if (sub_resp.ok) {
        let oid = sub_res.charge_invoice.uuid
        this.adyen.start({
          invoiceUuid: oid,
          // TODO
          skinCode: process.env.REACT_APP_ADYEN_SKIN,
          countryCode: inputs.country,
          // TODO
          shopperLocale: 'en_EN',
        })
      } else {
        // TODO this is gonna be obsolete
        //        this.props.onError(new AdyenSubscriptionError(sub_res))
        throw new AdyenSubscriptionError(sub_res)
      }
    } catch (e) {
      if (e instanceof ValidationError) {
        this.props.onError(e)
      } else throw e
    }
  }

  render() {
    return (
      <div>
        <br />
        <button className="btn btn-primary btn-lg btn-block" id="adyen-subscribe" onClick={this.submit}>
          {strings.proceedSEPA}
        </button>
      </div>
    )
  }
}

