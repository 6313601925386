import React from 'react'
import qs from 'query-string'
import { Helmet } from "react-helmet"
import { withRouter, Redirect } from 'react-router-dom'
import assert from 'assert'

function FBPixel(props) {

  React.useEffect(() => {

    (function(f, b, e, v, n, t, s) {
      if (f.fbq) return;

      n = f.fbq = function() {
        n.callMethod ?
          n.callMethod.apply(n, arguments) : n.queue.push(arguments)
      };
      if (!f._fbq) f._fbq = n; n.push = n; n.loaded = !0; n.version = '2.0';
      n.queue = []; t = b.createElement(e); t.async = !0;
      t.src = v; s = b.getElementsByTagName(e)[0];
      s.parentNode.insertBefore(t, s)
    })(window, document, 'script',
      'https://connect.facebook.net/en_US/fbevents.js');
    window.fbq('init', '962515540757933');
    window.fbq('track', 'PageView');

  })

  return <React.Fragment>
    <noscript><img height="1" width="1" style="display:none"
      src="https://www.facebook.com/tr?id=962515540757933&ev=PageView&noscript=1"
    /></noscript>
  </React.Fragment>
}

function MyHead(props) {
  return <Helmet>
    {props.children}
    <meta charSet="utf-8" />
    <meta name="viewport" content="width=device-width, initial-scale=1, shrink-to-fit=no" />
    <meta name="description" content />
    {/* Favicons */}
    <link rel="apple-touch-icon" href="/docs/4.4/assets/img/favicons/apple-touch-icon.png" sizes="180x180" />
    <link rel="icon" href="icons/icon-48x48.png" sizes="48x48" type="image/png" />
    <link rel="icon" href="icons/icon-192x192.png" sizes="192x192" type="image/png" />
    <link rel="icon" href="icons/icon-48x48.png" sizes="48x48" type="image/png" />
    <link rel="shortcut icon" href="icons/icon-48x48.png" />
    <link rel="stylesheet" href="https://stackpath.bootstrapcdn.com/bootstrap/4.3.1/css/bootstrap.min.css" integrity="sha384-ggOyR0iXCbMQv3Xipma34MD+dH/1fQ784/j6cY/iJTQUOhcWr7x9JvoRxT2MZw1T" crossorigin="anonymous" />

    <style dangerouslySetInnerHTML={{ __html: "\n      .bd-placeholder-img {\n        font-size: 1.125rem;\n        text-anchor: middle;\n        -webkit-user-select: none;\n        -moz-user-select: none;\n        -ms-user-select: none;\n        user-select: none;\n      }\n\n      @media (min-width: 768px) {\n        .bd-placeholder-img-lg {\n          font-size: 3.5rem;\n        }\n      }\n    " }} />
  </Helmet>
}

function Top() {
  return < div className="d-flex flex-wrap align-items-center p-3 my-3 text-white-50 bg-black rounded shadow-sm"
    style={{ /* backgroundColor: '#000000' */ }}>
    <img className="mr-3 img-fluid" src="OP_Logo_FB_electric.png" alt="" />
               <div className="lh-100">
                 {/*       <h6 className="mb-0 text-white lh-100"></h6>
                    <small>Social Media Knowledge since 2019</small> */}
    </div>
  </div >

}

export const BuyBibleSuccess = withRouter(function(props) {

  const checkoutSessionId = qs.parse(window.location.search).session_id
  const [coSession, setCoSession] = React.useState({})

  React.useEffect(() => {
    (async () => {
      let cos = await fetch('/api/profile/stripe-checkout/session/' + checkoutSessionId)
      let res = await cos.json()
      setCoSession(res)
    })()
  }, [])

  if (coSession.id) {
    const downloadUrl = '/facebookbible/download?receipt_id=' + coSession.receipt_number
      + '&email=' + encodeURIComponent(coSession.email)
      + '&cOsessionId=' + coSession.id

    return <React.Fragment>
      <Redirect to={downloadUrl} />
    </React.Fragment>
  } else {
    return <React.Fragment>
      <MyHead >
        <title>OnlinePunks - Payment Success</title>
      </MyHead>
      <div className="container" >
        <Top />
        <div className="m-3">
          <h3>Thank you for your purchase!</h3>
          <p>We are processing your payment and preparing the download... </p>
        </div>
      </div>
      <FBPixel />
    </React.Fragment>
  }
})

export function DownloadFBBible(props) {

  assert(process.env.REACT_APP_FB_BIB_TY, `FB_BIB_TY env var not defined`)

  const receiptId = qs.parse(window.location.search).receipt_id
  const receiptEmail = qs.parse(window.location.search).email
  const cOsessionId = qs.parse(window.location.search).cOsessionId

  //console.log(qs.parse(window.location.search))

  console.log(receiptId, receiptEmail, cOsessionId)

  const [isVerified, setVerified] = React.useState(null)
  const [paymentRef, setPaymentRef] = React.useState(null)
  const [orderData, setOrderData] = React.useState(null)

  React.useEffect(() => {
    (async () => {
      if (receiptId && receiptEmail) {
        console.log('verifying receipt...')
        let r = await fetch('/api/profile/stripe-checkout/receipt?id=' + receiptId.trim()
          + '&email=' + encodeURIComponent(receiptEmail.trim())
          + (cOsessionId ? `&cOsessionId=${cOsessionId}` : ''))
        let res = await r.json()

        setVerified(res.valid)
        setPaymentRef(res.valid && res.paymentRef)
        if (res.valid) {
          setOrderData({ sku: res.sku, price: res.price, ref: res.paymentRef, currency: res.currency })
        }
      } else {
        setVerified(false)
      }
    })()
  }, [])

  return <React.Fragment>
    <MyHead>
      <title>OnlinePunks - Download Page</title>
    </MyHead>

    <main role="main" className="container">

      <Top />

      {/* download */}
      {isVerified == true ? <div className="my-3 p-3 bg-white rounded shadow-sm">
        <h6 className="border-bottom border-gray pb-2 mb-0">Downloads</h6>
        <div className="media text-muted pt-3">
          <div className="media-body pb-3 mb-0 small lh-125 border-bottom border-gray d-flex justify-content-between align-items-center w-100">
            <div className="column m-1 mx-2"><img src="fb_bible_cover.png" /></div>
            <div className="column w-100 px-4 ">
              <strong className="text-gray-dark row">Facebook Bible 2020</strong>
              <span className=" row">by Patrick Wind</span>
            </div>
            <a className="column" href={`/api/profile/stripe-checkout/download?purchaseRef=${paymentRef}`} download={"Facebook_Bible.pdf"}>Download</a>
          </div>
        </div>
        {orderData && orderData.sku
          ? <iframe
            src={`${process.env.REACT_APP_FB_BIB_TY}?sku=${orderData.sku}&price=${orderData.price}&orderId=${orderData.ref}&currency=${orderData.currency.toUpperCase()}`}
            style={{ display: 'none', width: 0, height: 0 }} />
          : null}
      </div>
        : <React.Fragment />}

      {isVerified === false && (receiptEmail || receiptId) ? <span>Wrong recepit id and/or e-mail, please enter bellow</span> : <React.Fragment />}
      {/* the form  */}
      {(isVerified === false) ? <div className='row m-4'>
        <form >
          <div className='form-group'>
            <label htmlFor='email'>Enter the email used to purchase here</label>
            <input className='form-control' type='text' name='email'></input>

            <label htmlFor='receipt_id'>Enter the purchase receipt id here<br /> (you can find it in the receipt e-mail we sent you)</label>
            <input className='form-control' type='text' name='receipt_id'></input>
            <br />
            <input className='btn btn-primary' type='submit' value='Confirm' />
          </div>
        </form>
      </div>
        : <React.Fragment />}
    </main>
  </React.Fragment>

}
