import React, { useState, useEffect } from 'react'
import strings from './languageStrings'
import { withRouter } from 'react-router'
//required for cyprress  to be able to itercept fetch
// see https://github.com/cypress-io/cypress/issues/95
import { fetch as polyFetch } from 'whatwg-fetch'
const fetch = null

async function previewInvoice(sid, session, plan, country, nocoupon = false, vat_number) {
	// we preview fake purchase that uses only the fields that change the price or it's components
	// cid is provided by server and cached by this component. it's unique for each session

	console.log('preview', sid, session, plan, country, vat_number)

	let _sid_ = `${sid}/` 
	let method = 'PUT' //sid != null ? 'PUT' : 'POST'
	let res = await polyFetch(
		`/api/profile/subscription/${_sid_}`,
		{
			method: method,
			headers: { 'content-type': 'application/json' },
			body: JSON.stringify({
				__complete__: false,
				session_id: session,
				plan_code: plan,
				country: country,
				vat_number: vat_number,
			}),
		}
	)

	if (res.ok) {
		let invoiceRes = await res.json()
		if (invoiceRes.ok) {
			return invoiceRes.body
		} else {
			throw invoiceRes
		}
	} else {
		let error = await res.json()
		console.error(error.httpStatus, error.details[0])
		if (error.httpStatus == '422' && error.details[0].field.includes('coupon_codes')) {
			console.log('coupon invalid, requesting preview without coupon')
			return previewInvoice(sid, plan, country, true, true)
		} else {
			return { error: { code: res.status, message: error } }
		}
	}
}

// get localized translation for the nick, extracted into function so the app doesn't crash
// when a new package name is introduced
function getLocalizedNickname(nick, strings) {
  return strings.payment_plans[nick] || nick;
}

function OrderPreview(props) {
	const plan_code = props.planCode
	const { session, cid, country, vat_number, plans, coupon } = props
	const thereIsVatNumber = vat_number != null && vat_number.length > 0

	// the subscription id used for the fake preview subscription. don't need it in the state to avoid excess refreshes
	let sid = null

	let [isLoading, setLoading] = useState(true)
	let [error, setError] = useState(null)
    let [invoice, setInvoice] = useState(null)
    
    //console.log("plans", plans);

	const plan_details = (plans || []).find(p => p.id == plan_code) || {}

	useEffect(() => {
		setLoading(true)

		previewInvoice(cid, session, plan_code, country, null, vat_number)
			.then(resp => {
				if (sid == null) {
					sid = resp.sid
				}
				try {
					let invoice = resp.invoice
					setInvoice({
						price: invoice.subtotal / 100,
						currency: invoice.currency,
						tax_amount: invoice.tax / 100,
						tax_rate: // it used to be easy --> invoice.tax_percent, <-- but now that I use the real invoice it's per item
            invoice.lines.data[0].tax_rates[0].percentage,
						subtotal: invoice.subtotal / 100,
						discount: invoice.discount / 100 || 0.0,
						total: invoice.total / 100,
						due_date: new Date(invoice.due_date),
					})
					setLoading(false)
          setError(null)
				} catch (e) {
					console.error(e)
					setError(e)
				}
			})
			.catch(e => {
				setError(e)
				console.error(e)
			})
	}, [country, plan_code, thereIsVatNumber])

	// render
	if (error) {
		return (
			<div id="preview_error" className="container bg-warning">
				{strings.unknown_error}
			</div>
		)
	} else if (!invoice || isLoading) {
		return <span id="preview_loading">{strings.loading}</span>
	} else {
		return (
			<div>
				<ul className="list-group mb-3">
					<li className="list-group-item ">
						<h6 className="">{strings.plan}</h6>
						<div className="text-muted">{plan_details.product.name}</div>
					</li>
					<li className="list-group-item d-flex justify-content-between">
						<small>{strings.net}</small>
						<small className="text-muted">{invoice.price.toFixed(2)}</small>
					</li>
					{invoice.discount > 0 ? (
						<div>
							<li className="list-group-item d-flex justify-content-between">
								<small>{strings.discount}</small>
								<small className="text-muted">{invoice.discount.toFixed(2)}</small>
							</li>
							<li className="list-group-item d-flex justify-content-between">
								<small>{strings.net}</small>
								<small className="text-muted">{invoice.subtotal.toFixed(2)}</small>
							</li>
						</div>
					) : (
						<div />
					)}
					<li className="list-group-item d-flex justify-content-between">
						<small>
							VAT <span data-preview="vat">{invoice.tax_rate}</span>%
						</small>
						<small className="text-muted">{invoice.tax_amount.toFixed(2)}</small>
					</li>
					<li className="list-group-item d-flex flex-column justify-content-between">
						<div>{getLocalizedNickname(plan_details.nickname, strings)} {strings.subscription}</div>
					</li>
					<li className="list-group-item d-flex justify-content-between">
						<span style={{ alignSelf: 'flex-start' }} data-preview="total">
							{strings.sum}
						</span>
						<strong id="preview-total" data-preview="total">
							€ {invoice.total.toFixed(2)}
						</strong>
					</li>
				</ul>
			</div>
		)
	}
}

export default withRouter(
	React.memo(OrderPreview, (prevProps, nextProps) => {
		// with the useEffect deps I can easily skip this function, but why not..
		let propsEqual =
			prevProps.cid == nextProps.cid &&
			prevProps.country == nextProps.country &&
			prevProps.vat_number == nextProps.vat_number &&
			prevProps.coupon == nextProps.coupon
		return propsEqual
	})
)
