import React, { Component } from "react";
import strings from "./languageStrings.js";

class navbar extends Component {
  render() {
    return (
      <div className="py-5 text-center">
        {/* <Logo style={{maxWidth: "500px", marginBottom: "24px" }}/>*/}
        <img src={process.env.PUBLIC_URL + '/PP_Logo.png'} style={{maxWidth: "801px", width: "100%", padding: "24px"}}/>
        <p className="lead">{strings.subtitle}</p>
      </div>
    );
  }
}

export default navbar;
