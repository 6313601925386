
import React from 'react'
import Cookies from 'js-cookie'
import QS from 'query-string' 

export default function(props, children) {

  let profileInfo = QS.parse(window.location.search)
  console.log(profileInfo)

  let email = profileInfo.email

  return (<div className='container'>
    We need to create profile for you. Please verify the information bellow:

            <form action='/api/profile/onboard-external' method='POST' className='column'>
      <input type='hidden' name='success_redirect' value='/api/profile/auth/google' />

      <div className='row m-4'>
        <div className='form-group col-md-4'>
          <label htmlFor='givenName'>Name</label>
          <input className='form-control' name='givenName' value={profileInfo.givenName} />

          <label htmlFor='familyName'>Family</label>
          <input className='form-control' name='familyName' value={profileInfo.familyName} />

          <label htmlFor='businessName'>Business Name</label>
          <input className='form-control' name='businessName' value="" />
          
          <label htmlFor='email'>E-mail</label>
          <input className='form-control' type='email' name='email' value={email} />

          <br />
          <input className='btn btn-primary' type='submit' value='Confirm' />
        </div>
      </div>
    </form>
  </div>)
}

