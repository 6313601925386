import React from 'react'

export default function Purchases(props) {

  let [invoices, setInvoices] = React.useState([])

  React.useEffect(() => {
    (async () => {
      let invoices = await fetch(`/api/profile/my-invoices`)
      setInvoices(await invoices.json())
    })()
  }, [])

  return <div className='card'>
    <div className='card-body'>
      <span className='card-title'>
        <h3>Your Purchases</h3>
      </span>
    </div>
    <ul className='list-group list-group-flush'>
      {invoices.map(i => {
        const isBible = i.lines.data.find(li => li.description.includes('bible'))
        return <li className='list-group-item'>
          <span className='row'>
            <span className='col'>{i.number}</span>
            <span className='col'>{i.lines.data.map(li => li.description).join(', ')}</span>
            <span className='col'>
              {isBible
                ? <button type="button" class="btn btn-primary">Download</button>
                : ''}
            </span>
          </span>
        </li>

      })}
    </ul>
  </div>

}
