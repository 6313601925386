import React from 'react'
import ReactDOM from 'react-dom'
import { BrowserRouter, Route, Redirect, Switch } from 'react-router-dom'
import 'bootstrap/dist/css/bootstrap.css'
import 'font-awesome/css/font-awesome.css'
import App from './App'
import AfterOrderRedirect from './components/afterOrderRedirect'
import Contact from './components/footer/contact'
import Privacy from './components/footer/privacy'
import Terms from './components/footer/terms'
import Login from './components/Login'
import PortalPage from './PortalPage'
import { SessionGuard } from '@primedma/paymentpunks-react'
import ExternalAccountOnboard from './ExternalAccountOnboard'
import { BuyBibleSuccess, DownloadFBBible } from './BuyBibleSuccess'
import { CbBuySuccess } from './ChargebeeCheckout'
import PlansList from './PlansList'
import { OnboardCB, PasswordCB, CheckoutTrial, UpgradeSubscription } from './OnboardCB'

ReactDOM.render(
  <BrowserRouter>
    <SessionGuard
      cookieName="whoami"
      loginPath="/login"
      ignorePaths={[
        '/subscribe',
        '/onboard',
        '/terms',
        '/privacy',
        '/contact',
        '/ext_onboard',
        '/password',
        // deprecated
        // TODO remove when not used anymore
        '/facebookbible',
        '/facebookbible/success',
        '/facebookbible/download',
        '/facebookbible/cancelled',
        // chargebee
        '/cbcheckout/success',
        '/cbcheckout/cancelled',
      ]}
    >
      <Switch>
        <Route path="/success" component={AfterOrderRedirect} />
        <Route path="/contact" component={Contact} />
        <Route path="/terms" component={Terms} />
        <Route path="/privacy" component={Privacy} />
        <Route path="/login" component={Login} />
        {/* deprecated */}
        <Route path="/subscribe" component={App} />
        {/* chargebee */}
        <Route path="/onboard" >
          <OnboardCB />
        </Route>
        <Route path='/upgrade_trial' component={CheckoutTrial} />
        <Route path='/upgrade' component={UpgradeSubscription} />
        <Route path="/password" component={PasswordCB} />
        <Route path="/ext_onboard" component={ExternalAccountOnboard} />
        // fb bible + stripe ;; obsolete
        <Route path="/facebookbible/success" component={BuyBibleSuccess} />
        <Route path="/facebookbible/download" component={DownloadFBBible} />
        <Route
          path="/facebookbible/cancelled"
          render={() => <Redirect to={{ pathname: '/facebookbible/cancelled.html' }} />}
        />
        {/* <Route path='/facebookbible' exact={true} render={() => <Redirect to={{ pathname: "/facebookbible/index.html" }} />} /> */}
        // chargebee generic checkout
        <Route path="/cbcheckout/success" component={CbBuySuccess} />
        <Route
          path="/titokbible/cancelled"
          render={() => <Redirect to={{ pathname: '/pdfpurchase/cancelled.html' }} />}
        />
        {/* <Route path='/facebookbible' exact={true} render={() => <Redirect to={{ pathname: "/facebookbible/index.html" }} />} /> */}

        <Route path="/account-portal" component={PortalPage} />
        <Route path="/__all_plans__" component={PlansList} />
      </Switch>
    </SessionGuard>
  </BrowserRouter>,
  document.getElementById('root')
)
