import React, { useContext } from 'react'
import { SessionGuard, GrantsContext } from '@primedma/paymentpunks-react'
import { useLocation } from 'react-router-dom'
import qs from 'query-string'

export default function(props) {

  // don't really see reason to complicate the component and wait for the result
  fetch('/api/profile/logout', { method: 'PUT' })

  let parms = qs.parse(useLocation().search)
  console.log(parms)
  
  let ctx = React.useContext(GrantsContext)
  console.log(ctx)

  let [method, setMethod] = React.useState(parms.method)

  return <div className='container'>

    <form action={'/api/profile/authenticate'
      + (method == 'chargebee' ? '?method=chargebee' : '')
      + "&onSuccess=/account-portal"}
      method='post'
      className='column'>
      <div className='row m-4'>
        <div className='form-group col-md-4'>
          <label htmlFor='email'>E-mail</label>
          <input className='form-control' type='email' name='email' />

          <label htmlFor='password'>Password</label>
          <input className='form-control' type='password' name='password' />
          <br />
          <label htmlFor="chargebee">via chargebee</label>
          <input className='m-2' type="checkbox" name="chargebee"
            checked={method == "chargebee"}
            onChange={(e) => {
              if (e.target.checked) setMethod('chargebee')
              else setMethod(null)
            }} />
          <br />
          <input className='btn btn-primary m-1' type='submit' value='Log In' />
          <a className='btn btn-info m-1' href='/api/profile/auth/facebook'>FB</a>
          <a className='btn btn-info m-1' href='/api/profile/auth/google'>goog</a>
        </div>
      </div>

      <div className='row m-4 col-md-4'>
        <a className="underlineHover " href="/resetpassword">Forgot Password?</a>
      </div>
    </form>
  </div >
}

