import React, { useState, useEffect } from 'react'

export default function PlansList() {
	const [plans, setPlans] = useState([])

	useEffect(() => {
		async function f() {
			let _plans = await (await fetch('/api/profile/plans')).json()
			setPlans(_plans)
		}
		f()
	}, [])

	return (
		<div className="container">
			Available plans:
			<div className=" row">
				{plans.map(plan => (
					<>
						<span className="col-2">
							<a href={`/subscribe?plan_code=${plan.id}`}>{plan.id}</a>
						</span>
						<span className="col-sm-1">{plan.active ? 'active' : 'inactive'}</span>
						<span className="col">{plan.nickname}</span>
						<span className="col-sm-1">({plan.interval})</span>
						<span className="col-xsm-1"> ==> </span>
						<span className="col-sm">{plan.product.id}</span>
						<span className="col">{plan.product.name}</span>
						<div className="w-100"></div>
					</>
				))}
			</div>
		</div>
	)
}
